<app-header></app-header>
<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="models">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'models'}" (click)="toggleBtnModels('models')"><mat-icon class="self-center mr-9">accessibility</mat-icon>
                <span>{{'UNSTRUCTURED_DATA.MODELS' | translate}}</span></button>
        </div>
        <div class="textures">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'textures'}" (click)="toggleBtnTextures('textures')"><mat-icon class="self-center mr-9">texture</mat-icon>
                <span class="ml-0">{{'UNSTRUCTURED_DATA.TEXTURES' | translate}}</span></button>
        </div>
        <div class="snapshots">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'snapshots'}" (click)="toggleBtnSnapshots('snapshots')"><mat-icon class="self-center mr-9">center_focus_strong</mat-icon>
                <span class="ml-0">{{'UNSTRUCTURED_DATA.SNAPSHOTS' | translate}}</span></button>
        </div>
        <div class="recordings">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'recordings'}" (click)="toggleBtnRecording('recordings')"><mat-icon class="self-center mr-9">mic</mat-icon>
                <span class="ml-0">{{'UNSTRUCTURED_DATA.RECORDINGS' | translate}}</span></button>
        </div>
        <div class="configurations">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'configurations'}" (click)="toggleBtnConfigurations('configurations')"><mat-icon class="self-center">settings</mat-icon>
                <span class="ml-3">{{'UNSTRUCTURED_DATA.CONFIGURATIONS' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>
</div>