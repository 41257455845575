
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { FileDataService } from '../file-data/file-data.service';

const lawgameCommunicationAPIURL = environment.lawgameCommunication;
const lawgameIdm = environment.lawgameIdm;

export interface USER {
  userName: string;
  languageCode: string;
  languageDescription: string;
  role: string;
  organizationCode: string;
  organizationDescription: string;
  blocked: boolean;
  permission: string | null;
}



@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }


  getLoggedInUserData(): Observable<any> {
    return this.http
    .post<any>(lawgameCommunicationAPIURL + 'com/userProfileDetails', null)
    .pipe(catchError(UserService.handleError));
  }
  checkToken(){
    return this.http.get<any>(lawgameCommunicationAPIURL + 'com/checkToken')
      .pipe(catchError(UserService.handleError));
  }

  resetPassword(email:string): Observable<any>{
    const body = {
      email: email
    }
  return this.http.put<any>(lawgameIdm + "idm/createRecoverPasswordRecord", body)
  .pipe(catchError(UserService.handleError));
  }

}
