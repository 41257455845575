import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenService } from 'src/app/services/token/token.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteElementComponent } from '../delete-element/delete-element.component';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  role: string ='';
  wrongCredentials: boolean = false;
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  loginFormGroup = this._formBuilder.group({
    username: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^\S*$/),
      ],
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          ///^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#\$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d!\"#\$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{9,}$/
        ),
      ],
    ],
  });
  loginFormErrors: { field: string; required: string; others: string }[] = [
    {
      field: 'username',
      required: 'LOGIN.ERRORS.USERNAME.REQUIRED',
      others: 'LOGIN.ERRORS.USERNAME.MINLENGTH',
    },
    {
      field: 'password',
      required: 'LOGIN.ERRORS.PASSWORD.REQUIRED',
      others: 'LOGIN.ERRORS.PASSWORD.PATTERN',
    },
  ];

  constructor(
    private interactionService: InteractionService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private tokenService: TokenService,
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    public dialog: MatDialog,
  ) {
    // Checks if the user is already logged in
    if (this.tokenService.getRefreshToken()) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() { }

  onLogin() {
    // Check if form is valid, otherwise display errors in UI form
    if (this.isFormValid()) {
      // Activate loading spinner for UI
      this.isLoading = true;
      // Clear any previous errors regarding wrong credentials from UI
      this.wrongCredentials = false;
      // Check if backend is available or the platform is used only for testing purposes
      if (!this.interactionService.getBackendStatus()) {
        localStorage.setItem('dataManagement_admin_access_token', 'test_refresh');
        localStorage.setItem('dataManagement_admin_refresh_token', 'test_access');
        this.isLoading = false;
        this.router.navigate(['home']);
        this.interactionService.setUser('EN');
      }

      // Otherwise POST username and password
      else {
        this.authService.login(this.loginFormGroup.value).subscribe(
          // If the response is good then deactivate loading spinner from UI and allow the user to go to chooseStudy
          (res) => {
            //check if logged in user has admin rights
            this.userService.getLoggedInUserData().subscribe(
              (res) => {
                this.interactionService.setUser(
                  res.languageCode
               );
                this.interactionService.setUserProfile(res);
                localStorage.setItem("user_language", res.languageCode);
                // localStorage.setItem('language', "EN")
                this.role = res.role;
                if(this.role != 'PILOT_TRAINEE' && this.role != 'PILOT_TRAINER'){
                  this.router.navigate(['home']);
                }else{
                  this.router.navigate(['homePilot']);
                }
                
              })
            this.isLoading = false;
          },
          (err: any) => {
            // username is invalid
            if(err.error.error === 'unauthorized'){
              this.openSnackBar(
                this.translate.instant("LOGIN.ERRORS.INVALID_USERNAME"),
                'notif-error',
                4000
              );
              // wrong password
            }else if(err.error.error === 'invalid_grant'){
              this.openSnackBar(
                this.translate.instant("LOGIN.ERRORS.INVALID_PASSWORD"),
                'notif-error',
                4000
              );
            }else if(err.error.error === "invalid_client" && err.error.error_description === "User is blocked."){
              this.openSnackBar(
                this.translate.instant("LOGIN.ERRORS.BLOCKED_ERROR"),
                'notif-error',
                4000
              );
            } 
             else if(err.error.error === "user_have_no_permission"){
              this.openSnackBar(
                this.translate.instant("LOGIN.UNAUTHORIZED"),
                'notif-error',
                4000
              );
            }
            // Otherwise display a general message for the moment
            else {
              this.openSnackBar(
                this.translate.instant("LOGIN.REQUEST.ERROR"),
                'notif-error',
                4000
              );
            }
            this.isLoading = false;
          }
        );
      }
    }
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  isFormValid() {
    return this.loginFormGroup.valid;
  }

  goTo(prefix: string) {
    this.router.navigate([prefix]);
  }

onForgotPassword(){
  this.isLoading = true;
  this.dialog
  .open(ForgotPasswordDialogComponent, {
    data: {},
  })
  .afterClosed()
  .subscribe(() => {
  
  });
  this.isLoading = false;
  this.isLoading = false;
}

}
