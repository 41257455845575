<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="wrapper">
    <div class="invoice">
      <button class="close-button" (click)="closeInvoice()">×</button>
      <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
        <mat-icon>download</mat-icon>
      </button>
      <h2>Report for {{ data.playerId }}</h2>
      <p><strong>Player ID:</strong> {{ data.playerId }}</p>
      <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
      
      <h3>Blood Stain Attributes</h3>
      
      <p><strong>Blood Stain Image:</strong></p>

      <ng-container *ngIf="isBinaryStream(data.bloodStainAttributes.blood_stain_image_name_response); else localImage">
        <img [src]="data.bloodStainAttributes.blood_stain_image_name_response" alt="Deformed Car" width="200">
      </ng-container>
      <ng-template #localImage>
        <p>Image is related to local storage.</p>
      </ng-template>
      <p><strong>Unity Blood Stain Origin Point:</strong></p>
      <table>
        <tbody>
          <tr *ngFor="let value of data.bloodStainAttributes.unity_blood_stain_origin_point; let i = index">
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  
  