import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { IMAGERY_ACQUISITION, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { AddGameScenarioComponent } from '../add-game-scenario/add-game-scenario.component';

@Component({
  selector: 'app-imagery-acquisition',
  templateUrl: './imagery-acquisition.component.html',
  styleUrls: ['./imagery-acquisition.component.css']
})
export class ImageryAcquisitionComponent implements OnInit {
  
  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: IMAGERY_ACQUISITION[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['traineeId', 'timestamp', 'details', 'actions'];
  dataSource: MatTableDataSource<IMAGERY_ACQUISITION> = new MatTableDataSource();
  traineeId: string ='';
  timestamp: string ='';
  properties: any;
  gameType:string ='';



  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    switch(this.gameAndDataPath){
      case 'csi/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CSI', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.IMAGERY_ACQUISITION', location: '/structuredData/imageryAcquisition' },
        ]);
        this.interactionService.setSelectedGame("csi");
        break;
      }case 'carAccident/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.IMAGERY_ACQUISITION', location: '/structuredData/imageryAcquisition' },
        ]);
        this.interactionService.setSelectedGame("car_accident");
        break;
      }
      //AFTER REFREH THIS WILL APEAR 
      default:{
        this.router.navigate(['/home']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
        ]);
        break;
      }
    }
    this.gameType = this.interactionService.getSelectedGame();
    this.getImageryAcquisition(this.gameType);
    this.isLoading = false;
  }
  
  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

openSnackBar(notificationMessage: string, type: string, duration: number) {
  this._snackBar.open(notificationMessage, 'X', {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: type,
    duration: duration,
  });
}

getImageryAcquisition(gameType: any){
  this.isLoading = true;
  let backend: boolean = true;
  //Fetch to backend
  if (backend) {
    this.sdmDataService.getImageryAcquisition(this.gameType).subscribe(
      (res) => {
        this.dataSource.data = res;
        console.log(res);
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = res.count;
        this.isLoading = false;
      },
      (error: any) => {
        if (error.error.statusDescription == "user_have_no_permission") {
          this.openSnackBar(
            this.translate.instant("LOGIN.UNAUTHORIZED"),
            'notif-error',
            4000
          );
        }
      }
    );
  }
  this.isLoading = false;
}


openDeleteDialog(traineeID:any, timestamp:any){
  this.isLoading = true;
  this.interactionService.setElementFromWhereToDelete('imageryAcquisition');
  this.interactionService.setUserId(traineeID);
  this.interactionService.setTimestamp(timestamp);
  this.dialog
  .open(DeleteScenarioComponent, {
    data: {},
  })
  .afterClosed()
  .subscribe((val: ImageryAcquisitionComponent) => {
    this.getImageryAcquisition(this.gameType);
  });
  this.isLoading = false;
}
}