import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-folder-explorer-navbar',
  templateUrl: './folder-explorer-navbar.component.html',
  styleUrls: ['./folder-explorer-navbar.component.css']
})
export class FolderExplorerNavbarComponent implements OnInit {

  folders: {
    name: string;
    location: string;
  }[] = [];
  fields: string[] = [];

  constructor(
    private router: Router,
    private interactionService: InteractionService
  ) {}
  

  ngOnInit(): void {
      this.folders = this.interactionService.getFolderExplorerNavBar();
    
  }
  goTo(prefix: string) {
    this.router.navigate([prefix]);
  }
}
