<div class="invoice-container">
  <div class="invoice" #invoice>
    <button class="close-button" (click)="closeInvoice()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2>Negotiation Report</h2>
    <p><strong>User ID:</strong> {{ data.userId }}</p>
    <p><strong>Player ID:</strong> {{ data.playerId }}</p>
    <p><strong>Participant Full Name:</strong> {{ data.participantFullName }}</p>
    <p><strong>Interrogation ID:</strong> {{ data.interrogationId }}</p>
    <p><strong>Game Start Timestamp:</strong> {{ data.gameStartTimestamp }}</p>
    <p><strong>Negotiation Start Timestamp:</strong> {{ data.negotiationStartTimestamp }}</p>
    <p><strong>Negotiation End Timestamp:</strong> {{ data.negotiationEndTimestamp }}</p>
    <p><strong>Negotiation Steps Timestamps:</strong></p>
    <ul>
      <li *ngFor="let timestamp of data.negotiationStepsTimestamps">{{ timestamp }}</li>
    </ul>
    <p><strong>Game End Timestamp:</strong> {{ data.gameEndTimestamp }}</p>
  </div>
</div>