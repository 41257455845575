<div class="sidebar">
    <div class="buttonsWrapper">
        <div>
            <button mat-raised-button [routerLink]="['/reportLocationPrediction']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.LOCATION_PREDICTION' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/reportAnomalyDetection']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.ANOMALY_DETECTION' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/reportAiResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.AI_RESULT_INSERTION' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/terrAttackScenarioGeneration']" routerLinkActive="active">
                <mat-icon class="material-icons">description</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportLmsQuizResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.LMS_QUIZ_RESULTS' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>