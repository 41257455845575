import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terr-attack-reports-sidenav',
  templateUrl: './terr-attack-reports-sidenav.component.html',
  styleUrls: ['./terr-attack-reports-sidenav.component.css']
})
export class TerrAttackReportsSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
