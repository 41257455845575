<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="wrapper">
    <div class="invoice">
      <button class="close-button" (click)="closeInvoice()">×</button>
      <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
        <mat-icon>download</mat-icon>
      </button>
      <h2>Report for {{data.username}}</h2>
      <p><strong>Username:</strong> {{ data.username }}</p>
      <p><strong>Date:</strong> {{ data.datetime }}</p>
      <p><strong>Module Name:</strong> {{ data.moduleName }}</p>
      <p><strong>Scenario URL:</strong> <a href="{{ data.scenarioUrl }}">{{ data.scenarioUrl }}</a></p>
      <p><strong>Scenario Max Points:</strong> {{ data.scenarioMaxPoints }}</p>
      <p><strong>Final Score:</strong> {{ data.finalScore }}</p>
      <p><strong>Trainer Name:</strong> {{ data.trainerName }}</p>
      <p><strong>Evaluator Name:</strong> {{ data.evaluatorName }}</p>
    
      <h3>Procedure</h3>
      <p><strong>Allocated Points:</strong> {{ data.procedure.AllocatedPoints }}</p>
      <p><strong>Score:</strong> {{ data.procedure.Score }}</p>
      <table>
        <thead>
          <tr>
            <th>Action</th>
            <th>Max Points</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let action of data.procedure.Actions">
            <td>{{ action.Action }}</td>
            <td>{{ action.MaxPoints }}</td>
            <td>{{ action.Points }}</td>
          </tr>
        </tbody>
      </table>
    
      <h3>Evidence Gathering</h3>
      <p><strong>Allocated Points:</strong> {{ data.evidenceGathering.AllocatedPoints }}</p>
      <p><strong>Score:</strong> {{ data.evidenceGathering.Score }}</p>
      <table>
        <thead>
          <tr>
            <th>Action</th>
            <th>Collected</th>
            <th>Photo</th>
            <th>Identified</th>
            <th>Marked</th>
            <th>Penalty</th>
            <th>Max Points</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let action of data.evidenceGathering.Actions">
            <td>{{ action.Action }}</td>
            <td>{{ action.Collected }}</td>
            <td>{{ action.Photo }}</td>
            <td>{{ action.Identified }}</td>
            <td>{{ action.Marked }}</td>
            <td>{{ action.Penalty }}</td>
            <td>{{ action.MaxPoints }}</td>
            <td>{{ action.Points }}</td>
          </tr>
        </tbody>
      </table>
    
      <h3>Photos</h3>
      <div *ngFor="let photo of data.photos">
        <!-- <p><strong>Description:</strong> {{ photo.Description }}</p> -->
        <img [src]="photo.URL" alt="{{ photo.Description }}" width="200">
      </div>
    </div>
    
  </div>