<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
  <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>

<div class="wrapper">
  <div class="invoice">
    <button class="close-button" (click)="closeReport()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2 class="invoice-title">Final Report for {{ data.username }}</h2>
    <!-- Summary Information -->
    <p><strong>Date and Time:</strong> {{ data.datetime }}</p>
    <p><strong>Module Name:</strong> {{ data.moduleName }}</p>
    <p><strong>Scenario URL:</strong> {{ data.scenarioUrl || 'N/A' }}</p>
    <p><strong>Scenario Max Points:</strong> {{ data.scenarioMaxPoints }}</p>
    <p><strong>Final Score:</strong> {{ data.finalScore }}</p>
    <p><strong>Trainer Name:</strong> {{ data.trainerName }}</p>
    <p><strong>Evaluator Name:</strong> {{ data.evaluatorName }}</p>

    <!-- Procedure Details -->
    <h3>Procedure Details</h3>
    <p><strong>Score:</strong> {{ data.procedure.Score }}</p>
    <table class="procedure-table">
      <thead>
        <tr>
          <th>Action</th>
          <th>Points</th>
          <th>Max Points</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let action of data.procedure.Actions">
          <td>{{ action.Action }}</td>
          <td>{{ action.Points }}</td>
          <td>{{ action.MaxPoints }}</td>
        </tr>
      </tbody>
    </table>
    <br>
    <p><strong>Allocated Points:</strong> {{ data.procedure.AllocatedPoints }}</p>

    <!-- Evidence Gathering Details -->
    <h3>Evidence Gathering</h3>
    <p><strong>Score:</strong> {{ data.evidenceGathering.Score }}</p>
    <p><strong>Allocated Points:</strong> {{ data.evidenceGathering.AllocatedPoints }}</p>

    <!-- Photos -->
    <h3>Photos</h3>
    <div *ngIf="data.photos.length > 0; else noPhotos">
      <div *ngFor="let photo of data.photos">
        <img [src]="photo.URL" alt="{{ photo.Description }}" width="200">
      </div>
    </div>
    <ng-template #noPhotos>
      <p>No photos available.</p>
    </ng-template>

    <!-- Quiz Attempts Details -->
    <h3>Quiz Attempts Details</h3>
    <table class="attempts-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Quiz</th>
          <th>User ID</th>
          <th>Attempt</th>
          <th>Unique ID</th>
          <th>Layout</th>
          <th>Current Page</th>
          <th>Preview</th>
          <th>State</th>
          <th>Time Start</th>
          <th>Time Finish</th>
          <th>Time Modified</th>
          <th>Time Modified Offline</th>
          <th>Time Check State</th>
          <th>Sum Grades</th>
          <th>Graded Notification Sent Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attempt of data.quizAttempts">
          <td>{{ attempt.id }}</td>
          <td>{{ attempt.quiz }}</td>
          <td>{{ attempt.userid }}</td>
          <td>{{ attempt.attempt }}</td>
          <td>{{ attempt.uniqueid }}</td>
          <td>{{ attempt.layout }}</td>
          <td>{{ attempt.currentpage }}</td>
          <td>{{ attempt.preview }}</td>
          <td>{{ attempt.state }}</td>
          <td>{{ formatTimestamp(attempt.timestart) }}</td>
          <td>{{ formatTimestamp(attempt.timefinish) }}</td>
          <td>{{ formatTimestamp(attempt.timemodified) }}</td>
          <td>{{ formatTimestamp(attempt.timemodifiedoffline) }}</td>
          <td>{{ attempt.timecheckstate || 'N/A' }}</td>
          <td>{{ attempt.sumgrades }}</td>
          <td>{{ formatTimestamp(attempt.gradednotificationsenttime) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
