import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { GameStatisticsComponent } from './components/game-statistics/game-statistics.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ModelsComponent } from './components/models/models.component';
import { ScenariosComponent } from './components/scenarios/scenarios.component';
import { StructuredDataComponent } from './components/structured-data/structured-data.component';
import { UnstructuredDataComponent } from './components/unstructured-data/unstructured-data.component';
import { AuthGuard } from './guard/auth.guard';
import { AddDirectoryComponent } from './components/add-directory/add-directory.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { RecordingsComponent } from './components/recordings/recordings.component';
import { SnapshotsComponent } from './components/snapshots/snapshots.component';
import { TexturesComponent } from './components/textures/textures.component';
import { ApartmentScenarioComponent } from './components/apartment-scenario/apartment-scenario.component';
import { QualityReportComponent } from './components/quality-report/quality-report.component';
import { ImageryAcquisitionComponent } from './components/imagery-acquisition/imagery-acquisition.component';
import { GameEventsComponent } from './components/game-events/game-events.component';
import { NpcResponseComponent } from './components/npc-response/npc-response.component';
import { AinResponseComponent } from './components/ain-response/ain-response.component';
import { CityAttackScenarioComponent } from './components/city-attack-scenario/city-attack-scenario.component';
import { EmotionalAssessmentComponent } from './components/emotional-assessment/emotional-assessment.component';
import { TwoCarsCrashScenarioComponent } from './components/two-cars-crash-scenario/two-cars-crash-scenario.component';
import { VictimCrossingScenarioComponent } from './components/victim-crossing-scenario/victim-crossing-scenario.component';
import { VictimOnCurbScenarioComponent } from './components/victim-on-curb-scenario/victim-on-curb-scenario.component';
import { AvatarMovesComponent } from './components/avatar-moves/avatar-moves.component';
import { CameraSurveillanceComponent } from './components/camera-surveillance/camera-surveillance.component';
import { GameActionsComponent } from './components/game-actions/game-actions.component';
import { NpcMovesComponent } from './components/npc-moves/npc-moves.component';
import { RealTimeRecordQuestsComponent } from './components/real-time-record-quests/real-time-record-quests.component';
import { HumanStanceComponent } from './components/human-stance/human-stance.component';
import { InterrogationSummaryComponent } from './components/interrogation-summary/interrogation-summary.component';
import { NegotiationSummaryComponent } from './components/negotiation-summary/negotiation-summary.component';
import { LocationPredictionComponent } from './components/location-prediction/location-prediction.component';
import { AnomalyDetectionComponent } from './components/anomaly-detection/anomaly-detection.component';
import { AiResultsInsertionComponent } from './components/ai-results-insertion/ai-results-insertion.component';
import { ReportGenerationInterrogationSummaryComponent } from './components/report-generation-interrogation-summary/report-generation-interrogation-summary.component';
import { ReportGenerationNegotiationSummaryComponent } from './components/report-generation-negotiation-summary/report-generation-negotiation-summary.component';
import { ReportGenerationLocationPredictionComponent } from './components/report-generation-location-prediction/report-generation-location-prediction.component';
import { ReportGenerationAnomalyDetectionComponent } from './components/report-generation-anomaly-detection/report-generation-anomaly-detection.component';
import { ReportGenerationAiResultsComponent } from './components/report-generation-ai-results/report-generation-ai-results.component';
import { BulletTrajectoryAnalysisComponent } from './components/bullet-trajectory-analysis/bullet-trajectory-analysis.component';
import { BloodStainOriginPointAnalysisComponent } from './components/blood-stain-origin-point-analysis/blood-stain-origin-point-analysis.component';
import { CarDeformationsAnalysisComponent } from './components/car-deformations-analysis/car-deformations-analysis.component';
import { AssessmentResultsComponent } from './components/assessment-results/assessment-results.component';
import { ObjectCentroidsComponent } from './components/object-centroids/object-centroids.component';
import { ReportsCarAccidentComponent } from './components/reports-car-accident/reports-car-accident.component';
import { ReportsInterrogationComponent } from './components/reports-interrogation/reports-interrogation.component';
import { ReportsCsiComponent } from './components/reports-csi/reports-csi.component';
import { ReportsTerroristAttackComponent } from './components/reports-terrorist-attack/reports-terrorist-attack.component';
import { HomePilotComponent } from './components/home-pilot/home-pilot.component';
import { ReportCarDeformationComponent } from './components/report-car-deformation/report-car-deformation.component';
import { ReportInterrogationSummaryComponent } from './components/report-interrogation-summary/report-interrogation-summary.component';
import { ReportNegotiationSummaryComponent } from './components/report-negotiation-summary/report-negotiation-summary.component';
import { ReportBloodstainAnalysisComponent } from './components/report-bloodstain-analysis/report-bloodstain-analysis.component';
import { ReportBulltetTrajectoryComponent } from './components/report-bulltet-trajectory/report-bulltet-trajectory.component';
import { ReportCsiFinalResultsComponent } from './components/report-csi-final-results/report-csi-final-results.component';
import { ReportLocationPredictionComponent } from './components/report-location-prediction/report-location-prediction.component';
import { ReportAnomalyDetectionComponent } from './components/report-anomaly-detection/report-anomaly-detection.component';
import { ReportAiResultsComponent } from './components/report-ai-results/report-ai-results.component';
import { ReportGenerationCsiFinalResultsComponent } from './components/report-generation-csi-final-results/report-generation-csi-final-results.component';
import { ReportGenerationCarDeformationComponent } from './components/report-generation-car-deformation/report-generation-car-deformation.component';
import { ReportGenerationBloodstainAnalysisComponent } from './components/report-generation-bloodstain-analysis/report-generation-bloodstain-analysis.component';
import { ReportGenerationBulletTrajectoryComponent } from './components/report-generation-bullet-trajectory/report-generation-bullet-trajectory.component';
import { ScenarioGenerationCarAccidentComponent } from './components/scenario-generation-car-accident/scenario-generation-car-accident.component';
import { ScenarioGenerationCsiComponent } from './components/scenario-generation-csi/scenario-generation-csi.component';
import { ScenarioGenerationTerroristAttackComponent } from './components/scenario-generation-terrorist-attack/scenario-generation-terrorist-attack.component';
import { ScenarioGenerationCarAccidentPilotComponent } from './components/scenario-generation-car-accident-pilot/scenario-generation-car-accident-pilot.component';
import { ScenarioGenerationCsiPilotComponent } from './components/scenario-generation-csi-pilot/scenario-generation-csi-pilot.component';
import { ScenarioGenerationTerrAttackPilotComponent } from './components/scenario-generation-terr-attack-pilot/scenario-generation-terr-attack-pilot.component';
import { LmsQuizResultsComponent } from './components/lms-quiz-results/lms-quiz-results.component';
import { ReportLmsQuizResultsComponent } from './components/report-lms-quiz-results/report-lms-quiz-results.component';
import { VrScenariosComponent } from './components/vr-scenarios/vr-scenarios.component';
import { ReportCsiAndLmsResultsComponent } from './components/report-csi-and-lms-results/report-csi-and-lms-results.component';
import { ReportCsiAndLmsResultsPilotComponent } from './components/report-csi-and-lms-results-pilot/report-csi-and-lms-results-pilot.component';


const routes: Routes = [
  { path: '', component: LoginComponent},

  { path: 'login', component: LoginComponent },
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent},
  { path: 'homePilot', canActivate: [AuthGuard], component: HomePilotComponent},
  { path: 'profile', canActivate: [AuthGuard], component: UserProfileComponent },
  { path: 'structuredData', canActivate: [AuthGuard], component: StructuredDataComponent},
  { path: 'unstructuredData', canActivate: [AuthGuard], component: UnstructuredDataComponent},
  { path: 'structuredData/gameStatistics', canActivate: [AuthGuard], component: GameStatisticsComponent},
  { path: 'structuredData/scenarios', canActivate: [AuthGuard], component: ScenariosComponent},
  { path: 'structuredData/apartmentScenario', canActivate: [AuthGuard], component: ApartmentScenarioComponent},
  { path: 'structuredData/ainResponse', canActivate: [AuthGuard], component: AinResponseComponent},
  { path: 'structuredData/npcResponse', canActivate: [AuthGuard], component: NpcResponseComponent},
  { path: 'structuredData/gameEvents', canActivate: [AuthGuard], component: GameEventsComponent},
  { path: 'structuredData/imageryAcquisition', canActivate: [AuthGuard], component: ImageryAcquisitionComponent},
  { path: 'structuredData/cityAttack', canActivate: [AuthGuard], component: CityAttackScenarioComponent},
  { path: 'structuredData/qualityReport', canActivate: [AuthGuard], component: QualityReportComponent},
  { path: 'unstructuredData/recordings', canActivate: [AuthGuard], component: RecordingsComponent},
  { path: 'unstructuredData/snapshots', canActivate: [AuthGuard], component: SnapshotsComponent},
  { path: 'unstructuredData/models', canActivate: [AuthGuard], component: ModelsComponent},
  { path: 'unstructuredData/textures', canActivate: [AuthGuard], component: TexturesComponent},
  { path: 'unstructuredData/configurations', canActivate: [AuthGuard], component: ConfigurationComponent},
  { path: 'structuredData/emotionalAssessment', canActivate: [AuthGuard], component: EmotionalAssessmentComponent},
  { path: 'structuredData/twoCarsCrash', canActivate: [AuthGuard], component: TwoCarsCrashScenarioComponent},
  { path: 'structuredData/victimCrossing', canActivate: [AuthGuard], component: VictimCrossingScenarioComponent},
  { path: 'structuredData/victimOnCurb', canActivate: [AuthGuard], component: VictimOnCurbScenarioComponent},
  { path: 'structuredData/avatarMoves', canActivate: [AuthGuard], component: AvatarMovesComponent},
  { path: 'structuredData/cameraSurveillance', canActivate: [AuthGuard], component: CameraSurveillanceComponent},
  { path: 'structuredData/gameActions', canActivate: [AuthGuard], component: GameActionsComponent},
  { path: 'structuredData/npcMoves', canActivate: [AuthGuard], component: NpcMovesComponent},
  { path: 'structuredData/realTimeRecordQuests', canActivate: [AuthGuard], component: RealTimeRecordQuestsComponent},
  { path: 'structuredData/humanStance', canActivate: [AuthGuard], component: HumanStanceComponent},
  { path: 'structuredData/interrogationSummary', canActivate: [AuthGuard], component: InterrogationSummaryComponent},
  { path: 'structuredData/negotiationSummary', canActivate: [AuthGuard], component: NegotiationSummaryComponent},
  { path: 'structuredData/locationPrediction', canActivate: [AuthGuard], component: LocationPredictionComponent},
  { path: 'structuredData/anomalyDetection', canActivate: [AuthGuard], component: AnomalyDetectionComponent},
  { path: 'structuredData/aiResultInsertion', canActivate: [AuthGuard], component: AiResultsInsertionComponent},
  { path: 'structuredData/reportGenerationInterrogationSummary', canActivate: [AuthGuard], component: ReportGenerationInterrogationSummaryComponent},
  { path: 'structuredData/reportGenerationNegotiationSummary', canActivate: [AuthGuard], component: ReportGenerationNegotiationSummaryComponent},
  { path: 'structuredData/reportGenerationLocationPrediction', canActivate: [AuthGuard], component: ReportGenerationLocationPredictionComponent},
  { path: 'structuredData/reportGenerationAnomalyDetection', canActivate: [AuthGuard], component: ReportGenerationAnomalyDetectionComponent},
  { path: 'structuredData/reportGenerationAiResults', canActivate: [AuthGuard], component: ReportGenerationAiResultsComponent},
  { path: 'structuredData/bulletTrajectoryAnalysis', canActivate: [AuthGuard], component: BulletTrajectoryAnalysisComponent},
  { path: 'structuredData/bloodstainOriginPointAnalysis', canActivate: [AuthGuard], component: BloodStainOriginPointAnalysisComponent},
  { path: 'structuredData/carDeformationsAnalysis', canActivate: [AuthGuard], component: CarDeformationsAnalysisComponent},
  { path: 'structuredData/assessmentResults', canActivate: [AuthGuard], component: AssessmentResultsComponent},
  { path: 'structuredData/objectCentroids', canActivate: [AuthGuard], component: ObjectCentroidsComponent},
  { path: 'reportsCarAccident', canActivate: [AuthGuard], component: ReportsCarAccidentComponent},
  { path: 'reportsInterrogation', canActivate: [AuthGuard], component: ReportsInterrogationComponent},
  { path: 'reportsCsi', canActivate: [AuthGuard], component: ReportsCsiComponent},
  { path: 'reportsTerroristAttack', canActivate: [AuthGuard], component: ReportsTerroristAttackComponent},
  { path: 'reportsTerroristAttack', canActivate: [AuthGuard], component: ReportsTerroristAttackComponent},
  { path: 'reportCarDeformation', canActivate: [AuthGuard], component: ReportCarDeformationComponent},
  { path: 'reportInterrogationSummary', canActivate: [AuthGuard], component: ReportInterrogationSummaryComponent},
  { path: 'reportNegotiationSummary', canActivate: [AuthGuard], component: ReportNegotiationSummaryComponent},
  { path: 'reportBloodstainAnalysis', canActivate: [AuthGuard], component: ReportBloodstainAnalysisComponent},
  { path: 'reportBulletTrajectory', canActivate: [AuthGuard], component: ReportBulltetTrajectoryComponent},
  { path: 'reportCsiFinalResults', canActivate: [AuthGuard], component: ReportCsiFinalResultsComponent},
  { path: 'reportLocationPrediction', canActivate: [AuthGuard], component: ReportLocationPredictionComponent},
  { path: 'reportAnomalyDetection', canActivate: [AuthGuard], component: ReportAnomalyDetectionComponent},
  { path: 'reportAiResults', canActivate: [AuthGuard], component: ReportAiResultsComponent},
  { path: 'reportLmsQuizResults', canActivate: [AuthGuard], component: ReportLmsQuizResultsComponent},
  { path: 'reportGenerationCsiFinalResults', canActivate: [AuthGuard], component: ReportGenerationCsiFinalResultsComponent},
  { path: 'reportGenerationCarDeformation', canActivate: [AuthGuard], component: ReportGenerationCarDeformationComponent},
  { path: 'reportGenerationBulletTrajectory', canActivate: [AuthGuard], component: ReportGenerationBulletTrajectoryComponent},
  { path: 'reportGenerationBloodstainAnalysis', canActivate: [AuthGuard], component: ReportGenerationBloodstainAnalysisComponent},
  { path: 'structuredData/scenarioGenerationCarAccident', canActivate: [AuthGuard], component: ScenarioGenerationCarAccidentComponent},
  { path: 'structuredData/scenarioGenerationCsi', canActivate: [AuthGuard], component: ScenarioGenerationCsiComponent},
  { path: 'structuredData/scenarioGenerationTerrAttack', canActivate: [AuthGuard], component: ScenarioGenerationTerroristAttackComponent},
  { path: 'carAccidentScenarioGeneration', canActivate: [AuthGuard], component: ScenarioGenerationCarAccidentPilotComponent},
  { path: 'csiScenarioGeneration', canActivate: [AuthGuard], component: ScenarioGenerationCsiPilotComponent},
  { path: 'terrAttackScenarioGeneration', canActivate: [AuthGuard], component: ScenarioGenerationTerrAttackPilotComponent},
  { path: 'structuredData/lmsQuizRsults', canActivate: [AuthGuard], component: LmsQuizResultsComponent},
  { path: 'structuredData/vrScenarios', canActivate: [AuthGuard], component: VrScenariosComponent},
  { path: 'structuredData/reportCsiAndLmsResults', canActivate: [AuthGuard], component: ReportCsiAndLmsResultsComponent},
  { path: 'reportCsiAndLmsResults', canActivate: [AuthGuard], component: ReportCsiAndLmsResultsPilotComponent},

  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
