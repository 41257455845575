import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-scenario',
  templateUrl: './edit-scenario.component.html',
  styleUrls: ['./edit-scenario.component.css']
})
export class EditScenarioComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private router: Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private sdmDataService: SdmDataService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService) { }

  public elementName: string = '';
  public currentDescription: string = '';
  public currentName: string = '';
  public currentCategory: any;
  public currentType: any;
  public newName: string = '';
  public newDescription: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  ngOnInit(): void {
    this.isLoading = true;
    this.elementName = this.interactionService.getElementName();
    this.currentName = this.interactionService.getElementName();
    this.currentDescription = this.interactionService.getCurrentDescription();
    this.currentCategory = this.interactionService.getCurrentCategory();
    this.currentType = this.interactionService.getCurrentType();
    this.isLoading = false;
  }

  

  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];

  language = this.interactionService.getLanguage();

  onClick() {
    this.dialogRef.close();

  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }



  onRename() {
    this.isLoading = true;
    if (!this.interactionService.getBackendStatus()) {
      let testingChooseSuccess = true;
      setTimeout(() => {
        if (testingChooseSuccess === true) {
          this.openSnackBar(
            this.translate.instant('UPDATE_SCENARIO.SUCCESS'),
            'notif-success',
            4000
          );
          this.router.navigate(['home']);
        }
        else {
          this.openSnackBar(
            this.translate.instant('UPDATE_SCENARIO.ERROR'),
            'notif-error',
            4000
          );
        }
        this.isLoading = false;
      }, 1000);
    }
    this.sdmDataService.renameElement(
      this.elementName,
      this.formGroup.get('newName')!.value!,
      this.formGroup.get('newDescription')!.value!,
      this.formGroup.get('type')!.value!,
      this.formGroup.get('category')!.value!,
    )
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'OK' || res.statusCode === '001') {
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS'),
              'notif-success',
              4000
            );
            this.isLoading = false;
            // location.reload();
            this.dialogRef.close();
          }else {
            this.openSnackBar(
              this.translate.instant('UPDATE_SCENARIO.ERROR'),
              'notif-error',
              4000
            );
            this.dialogRef.close();
          }
        },
        (err: any) => {
          if(err.error.statusDescription === 'scenario_with_given_name_already_exists'){
            this.openSnackBar(
              this.translate.instant('SCENARIO_LIST.CREATE_SCENARIO.DUPLICATE_ERROR'),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }else{
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
          this.isLoading = false;
        }
      );
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  formGroup = this._formBuilder.group({
    newName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^(?=.{1,255}$)[\w\-. ]+\w$/),
      ],
    ],
    newDescription: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
      ],
    ],
    type: ['', Validators.required],
    category: ['', Validators.required],
  });

  formErrors: { field: string; required: string; warningOne: string; warningTwo: string }[] = [
    {
      field: 'newName',
      required: 'UPDATE_SCENARIO.NEW_NAME.REQUIRED',
      warningOne: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.MINLENGTH',
      warningTwo: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.INVALID_FORMAT'
    },
    {
      field: 'description',
      required: 'UPDATE_SCENARIO.DESCRIPTION.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'type',
      required: 'UPDATE_SCENARIO.TYPE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'role',
      required: 'UPDATE_SCENARIO.CATEGORY.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
  ];

  isFormValid() {
    return this.formGroup.valid;
  }

}
