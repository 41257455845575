import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-report-vizualization-bloodstain-analysis',
  templateUrl: './report-vizualization-bloodstain-analysis.component.html',
  styleUrls: ['./report-vizualization-bloodstain-analysis.component.css']
})
export class ReportVizualizationBloodstainAnalysisComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    private interactionService: InteractionService,
    public dialogRef: MatDialogRef<ReportVizualizationBloodstainAnalysisComponent>, 
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
     this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  photoError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/default.jpg';
  }

  isBinaryStream(url: string): boolean {
    // Assuming a simple check to determine if the URL is a binary stream or an actual link
    // This can be customized based on the actual format of the binary stream or link
    return url.startsWith('data:') || url.startsWith('http');
  }


  closeInvoice() {
    this.dialogRef.close();
  }

  downloadPDF(): void { 
   // const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
   const doc = new jsPDF() as any;
    // Step 1: Add the background image using the base64 string
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); // Full page background

    // Title text
    const title = 'Blood Stain Report';

    // Calculate the width of the title
    const titleWidth = doc.getTextWidth(title);

    // Calculate x position for center alignment
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

    // Add logo (base64 image)
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed

    // Add report title with decreased font size and bold
    doc.setFontSize(14); // Smaller font size for title
    doc.setFont('bold'); // Set font to bold
    doc.setTextColor(255, 255, 255); // Set title color to white for better contrast
    doc.text(title, x, 40); // Adjust y position to accommodate logo

    // Reset to normal font and set text color
    doc.setFont('normal'); // Reset to normal font
    doc.setFontSize(10); // Adjust font size for user info
    doc.setTextColor(255, 255, 255); // Set text color to white for user info

    // Add Player ID and Timestamp
    const playerId = this.data?.playerId ?? 'null'; // Use nullish coalescing operator
    const timestamp = this.data?.timestamp ?? 'null'; // Use nullish coalescing operator
    doc.text('Player ID: ' + playerId, 10, 50);
    doc.text('Timestamp: ' + timestamp, 10, 60);
    let positionY = 70;
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255); 

    const bloodStainAttributesTitle = 'Blood Stain Attributes';
    const bloodStainAttributesTitleWidth = doc.getTextWidth(bloodStainAttributesTitle);
    const y = (doc.internal.pageSize.getWidth() - bloodStainAttributesTitleWidth) / 2;
    doc.text(bloodStainAttributesTitle, y, positionY);
    positionY += 10;

    const bloodStainOriginPoint: (number | string)[] = this.data?.bloodStainAttributes?.unity_blood_stain_origin_point || [];
    const tableData: string[][] = bloodStainOriginPoint.length > 0 
        ? bloodStainOriginPoint.map((value: number | string) => [value.toString()]) 
        : [['null']];
    autoTable(doc, {
        head: [['Unity Blood Stain Origin Point']],
        body: tableData,
        startY: positionY,
        styles: { fillColor: undefined, textColor: [255, 255, 255] },
        theme: 'grid',
    });
    positionY = doc.autoTable.previous.finalY + 10;

    // Blood Stain Image Section
    const bloodStainImgTitle = 'Blood Stain Image';
    const bloodStainImgWidth = doc.getTextWidth(bloodStainImgTitle);
    const bloodStainImgCenter = (doc.internal.pageSize.getWidth() - bloodStainImgWidth) / 2;
    doc.text(bloodStainImgTitle, bloodStainImgCenter, positionY);
    positionY += 10;

    const bloodStainImage = this.data?.bloodStainAttributes?.blood_stain_image_name_response;

    if (bloodStainImage) {
        const img = new Image();
        img.src = bloodStainImage.startsWith('data:image/png;base64,')
            ? bloodStainImage
            : `data:image/png;base64,${bloodStainImage}`;

        // Dynamically load the image and adjust size/aspect ratio
        img.onload = () => {
            const maxImageWidth = 70; // Max allowed width for the image
            const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width

            // Calculate the dynamic width and height, preserving aspect ratio
            const imageWidth = img.width > maxImageWidth ? maxImageWidth : img.width;
            const imageHeight = (img.height / img.width) * imageWidth;

            // Calculate X position to center the image
            const xPosition = (pageWidth - imageWidth) / 2;

            // Add the image to the PDF with dynamic width and height
            doc.addImage(img.src, 'PNG', xPosition, positionY, imageWidth, imageHeight);
            positionY += imageHeight + 10; // Move the Y position for the next section

            // Save the document after the image is added
            doc.save('blood_stain_summary_report.pdf');
        };

        img.onerror = (error) => {
            console.error('Error loading blood stain image:', error);
            doc.text('No image available.', 10, positionY);
            positionY += 10;

            // Save the document even if the image fails
            doc.save('blood_stain_summary_report.pdf');
        };
    } else {
        // If no image is available
        doc.text('No image available.', 10, positionY);
        positionY += 10;

        // Save the document without the image
        doc.save('blood_stain_summary_report.pdf');
    }
}


}