import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { INTERROGATION_SUMMARY, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { InterrogationSummaryComponent } from '../interrogation-summary/interrogation-summary.component';

import { ReportVizualizationInterrogationSummaryComponent } from '../report-vizualization-interrogation-summary/report-vizualization-interrogation-summary.component';

@Component({
  selector: 'app-report-interrogation-summary',
  templateUrl: './report-interrogation-summary.component.html',
  styleUrls: ['./report-interrogation-summary.component.css']
})
export class ReportInterrogationSummaryComponent implements OnInit {
  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  public gameType:string='';
  public role: string ='';
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: INTERROGATION_SUMMARY[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['userId', 'interrogationId', 'actions'];
  data: Map<string, object> = new Map;

  dataSource: MatTableDataSource<INTERROGATION_SUMMARY> = new MatTableDataSource();

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.gameType = this.interactionService.getSelectedGame();
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
   if(this.gameType === 'interrogation'){
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
        { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/homePilot'},
        { name: 'STRUCTURED_DATA.REPORTS_INTERRGATION', location: '/reportsInterrogation' },
        { name: 'STRUCTURED_DATA.REPORTS_INTERROGATION_SUMMARY', location: '/reportInterrogationSummary'},
      ]);
      this.interactionService.setSelectedGame("interrogation");
    }else{
      this.router.navigate(['/homePilot']);
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
      ]);
    }

    this.findAllInterrogationSummary();
    this.isLoading = false;
  }

  findAllInterrogationSummary(){
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.findAllInterrogationSummary().subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  openDeleteDialog(userId:any, playerId:any, participantFullName:any, 
    interrogationId:any, gameStartTimestamp:any, interrogationStartTimestamp:any, 
    interrogationEndTimestamp:any, confessionTimestamp:any, gameEndTimestamp:any, multipleQuestionRatio:any) {
    this.isLoading = true;
    this.interactionService.setElementFromWhereToDelete('interrogationSummary');
    this.interactionService.setUserId(userId);
    this.interactionService.setPlayerId(playerId);
    this.interactionService.setParticipantFullName(participantFullName);
    this.interactionService.setInterviewId(interrogationId);
    this.interactionService.setGameStartTimestamp(gameStartTimestamp);
    this.interactionService.setInterrogationStartTimestamp(interrogationStartTimestamp);
    this.interactionService.setInterrogationEndTimestamp(interrogationEndTimestamp);
    this.interactionService.setConfessionTimestamp(confessionTimestamp);
    this.interactionService.setGameEndTimestamp(gameEndTimestamp);
    this.interactionService.setMultipleQuestionRatio(multipleQuestionRatio);

    this.dialog
    .open(DeleteScenarioComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe((val: InterrogationSummaryComponent) => {
      this.findAllInterrogationSummary();
    });
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

openSnackBar(notificationMessage: string, type: string, duration: number) {
  this._snackBar.open(notificationMessage, 'X', {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: type,
    duration: duration,
  });
}

  generateReportDialog(json:any){
  this.isLoading = true;
  this.interactionService.setJson(json);
  this.dialog
  .open(ReportVizualizationInterrogationSummaryComponent, {
    data: {},
  })
  .afterClosed()
  .subscribe((val: ReportInterrogationSummaryComponent) => {
    // this.getAllRealTimeRecordQuest();
  });
  this.isLoading = false;
  }
}
