<div class="form">
    <div *ngIf="isLoading === true" class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
        <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
          <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
        </div>
      </div>
      <h2 mat-dialog-title>Add Emotional Assessment</h2>
      <form [formGroup]="formGroup" class=" text-[#ffc601] " (keyup.enter)="addEmotionalAssessment()">
        <div class="emotional-row">
          <div class="emotional-column" style="padding: 5px;">
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.USER_ID.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="userId" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"GAME_EVENTS.TIMESTAMP.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="timestamp" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.INTERVIEW_ID.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="interviewId" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.ORGANIZER_INSTITUTION.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="organizerInstitution" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.PARTICIPANT_FULL_NAME.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="participantFullName" />
            </mat-form-field>
          </div>
          <div class="emotional-column" style="padding: 5px;">
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.ANGER_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="angerGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.CALM_GRADE.TITLE" | translate}}</mat-label>
             <input type="text" matInput formControlName="calmGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.TRUST_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="trustGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
            <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.DISGUST_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="disgustGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.FEAR_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="fearGrade" />
            </mat-form-field>
          </div>
            <div class="emotional-column" style="padding: 5px;">
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.HAPPINESS_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="happinessGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.SADNESS_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="sadnessGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.SURPRISE_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="surpriseGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.INTEREST_GRADE.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="interestGrade" />
            </mat-form-field>
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.ACCURACY.TITLE" | translate}}</mat-label>
              <input type="text" matInput formControlName="accuracy" />
            </mat-form-field>
          </div>
      </div>
        
      </form>
    </div>
    <div class="text-[10px]">
      * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
    </div>
    <div *ngFor="let error of formErrors">
      <div class="text-[#ffc601]" *ngIf="
          (formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.touched) ||
          (formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.dirty)
        ">
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
            formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.errors?.['required']
          ">
          {{ error.required | translate }}
        </div>
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
            formGroup.get(error.field)?.invalid &&
            (formGroup.get(error.field)?.errors?.['pattern'] ||
              formGroup.get(error.field)?.errors?.['minlength'])
          ">
          {{ error.warningOne | translate }}
        </div>
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
        formGroup.get(error.field)?.invalid &&
        (formGroup.get(error.field)?.errors?.['pattern'] ||
          formGroup.get(error.field)?.errors?.['minlength'])
      ">
       {{ error.warningTwo | translate }}
    </div>
      </div>
    </div>
    <mat-dialog-actions class="cursor-pointer">
        <button mat-raised-button (click)="onClick()"
          class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">Cancel</button>
        <button mat-raised-button (click)="addEmotionalAssessment()"[disabled]="!isFormValid()"
          class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">OK
        </button>
      </mat-dialog-actions>