import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
// import { DeleteDialogComponent } from 'src/app/file-explorer/modals/delete-dialog/delete-dialog.component';
import { FileDataService } from 'src/app/services/file-data/file-data.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-delete-element',
  templateUrl: './delete-element.component.html',
  styleUrls: ['./delete-element.component.css']
})
export class DeleteElementComponent implements OnInit {

  public elementNameToDelete: string ='';
  public isDirectory: any;
  public elementPath: string = '';
  currentPath!: string;
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  
    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, 
      private fileDataService: FileDataService,
      private router:Router,
      private _snackBar: MatSnackBar,
      private translate: TranslateService,
      private interactionService: InteractionService ) {}
  
      languages = [
        { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
        { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
        { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
        { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
        { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
        { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
        { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
        { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
        { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
        { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
        { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
        { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
      ];
    language = this.interactionService.getLanguage();
  
    ngOnInit(): void {
      this.isLoading = true;
      this.elementNameToDelete=this.interactionService.getdeletedElementName()
      this.isDirectory = this.interactionService.getElementType();
      this.elementPath = this.interactionService.getElementPath();
      this.isLoading = false;
    }
  
    onClick(){
      this.dialogRef.close();
    
    }
    
    onDelete() {
    this.isLoading=true;
    let elementName = this.elementNameToDelete;
    this.currentPath = this.pushToPath(this.elementPath, elementName!);
    let gameSelected = this.interactionService.getSelectedGame();
    let element = this.interactionService.getElementFromMenu();
    switch(gameSelected){
      case "carAccident":{
         this.deleteForCarAccidentByElementAndPath(element, this.currentPath);
         break;
      }
      case "interrogation":{
        this.deleteForInterrogationElementAndPath(element, this.currentPath);
        break;
      }
      case "csi":{
        this.deleteForCsiElementAndPath(element, this.currentPath);
        break;
      }
      case "terroristAttack":{
        this.deleteForTerrAttackElementAndPath(element, this.currentPath);
        break;
      }
    }
    }

    deleteForCarAccidentByElementAndPath(element: string, path:string){
      this.isLoading = true;
      this.interactionService.setElementFromMenu(element);
      let elementGame = this.interactionService.getElementFromMenu();
      let elementName = this.elementNameToDelete;
      this.currentPath = this.pushToPath(this.elementPath, elementName!);
      path = this.currentPath;
      this.fileDataService.deleteForCarAccidentByElementAndPath(elementGame, this.currentPath, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully' || res.statusCode === '029') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
            this.dialogRef.close();
            // location.reload();
          }else if(res.statusDescription === 'the_directory_was_deleted_successfully' || res.statusCode === '062'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.isLoading = false;
          }else if(res.statusDescription === 'could_not_delete_the_element_Path_may_be_wrong' || res.statusCode === '063'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.ELEMENT_DOES_NOT_EXIST'),
              'notif-error',
              4000
            );
             // location.reload();
          }
          else{
            this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
          this.isLoading = false;
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
            this.isLoading = false;
        }
      );
    }
    deleteForInterrogationElementAndPath(element: string, path:string){
      this.isLoading = true;
      this.interactionService.setElementFromMenu(element);
      let elementGame = this.interactionService.getElementFromMenu();
      let elementName = this.elementNameToDelete;
      this.currentPath = this.pushToPath(this.elementPath, elementName!);
      path = this.currentPath;
      this.fileDataService.deleteForInterrogationElementAndPath(elementGame, this.currentPath, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully' || res.statusCode === '029') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
            this.dialogRef.close();
            // location.reload();
          }else if(res.statusDescription === 'the_directory_was_deleted_successfully' || res.statusCode === '062'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.isLoading = false;
          }else if(res.statusDescription === 'could_not_delete_the_element_Path_may_be_wrong' || res.statusCode === '063'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.ELEMENT_DOES_NOT_EXIST'),
              'notif-error',
              4000
            );
             // location.reload();
          }
          else{
            this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
          this.isLoading = false;
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
            this.isLoading = false;
        }
      );
    }
    deleteForCsiElementAndPath(element: string, path:string){
      this.isLoading = true;
      this.interactionService.setElementFromMenu(element);
      let elementGame = this.interactionService.getElementFromMenu();
      let elementName = this.elementNameToDelete;
      this.currentPath = this.pushToPath(this.elementPath, elementName!);
      path = this.currentPath;
      this.fileDataService.deleteForCsiElementAndPath(elementGame, this.currentPath, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully' || res.statusCode === '029') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
            this.dialogRef.close();
            // location.reload();
          }else if(res.statusDescription === 'the_directory_was_deleted_successfully' || res.statusCode === '062'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.isLoading = false;
          }else if(res.statusDescription === 'could_not_delete_the_element_Path_may_be_wrong' || res.statusCode === '063'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.ELEMENT_DOES_NOT_EXIST'),
              'notif-error',
              4000
            );
             // location.reload();
          }
          else{
            this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
          this.isLoading = false;
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
            this.isLoading = false;
        }
      );
    }
    deleteForTerrAttackElementAndPath(element: string, path:string){
      this.isLoading = true;
      this.interactionService.setElementFromMenu(element);
      let elementGame = this.interactionService.getElementFromMenu();
      let elementName = this.elementNameToDelete;
      this.currentPath = this.pushToPath(this.elementPath, elementName!);
      path = this.currentPath;
      this.fileDataService.deleteForTerrAttackElementAndPath(elementGame, this.currentPath, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully' || res.statusCode === '029') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
            this.dialogRef.close();
            // location.reload();
          }else if(res.statusDescription === 'the_directory_was_deleted_successfully' || res.statusCode === '062'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.isLoading = false;
          }else if(res.statusDescription === 'could_not_delete_the_element_Path_may_be_wrong' || res.statusCode === '063'){
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.ELEMENT_DOES_NOT_EXIST'),
              'notif-error',
              4000
            );
             // location.reload();
          }
          else{
            this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
          this.isLoading = false;
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant("ACTIONS_BUTTONS.DELETE.ERROR_MESSAGE"),
            'notif-error',
            4000
          );
            this.isLoading = false;
        }
      );
    }
  
    changeLanguage(code: string, language: string, flag: string) {
      this.language = { code, language, flag };
      localStorage.setItem('language', code);
      this.interactionService.setLanguage({ code, language, flag });
      this.translate.use(
        localStorage.getItem('language')
          ? localStorage.getItem('language')!.toString()
          : 'EN'
      );
    }
  
    onResize(event: any) {
      this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
    }
  
    openSnackBar(notificationMessage: string, type: string, duration: number) {
      this._snackBar.open(notificationMessage, 'X', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: type,
        duration: duration,
      });
    }
    pushToPath(path: string, folderName: string) {
      let p = path ? path : '';
      p += `${folderName}/`;
      return p;
    }
    popFromPath(path: string) {
      let p = path ? path : '';
      let split = p.split('/');
      split.splice(split.length - 2, 1);
      p = split.join('/');
      return p;
    }
  }
  
