<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="setBackgrund">
    <div class="h-screen flex flex-col">
      <app-header class="fixed w-full z-50"></app-header>
      <div class="wrapper">
        <mat-form-field appearance="standard">
          <input matInput (keyup)="applyFilter($event)" placeholder="{{'SEARCH_BOX.PLACEHOLDER' | translate}}" #input>
        </mat-form-field>
        <div id="title">
          <h3>{{'STRUCTURED_DATA.GAME_EVENTS' | translate}}</h3>
          <h5 *ngIf="userProfile?.permission && userProfile?.permission !== 'WRITE'">* {{"READ_PERMISSION" | translate}}</h5>
        </div>
        <div class="functionalities">
          <button (click)="getGameEvents(gameType)" matTooltip="{{'TOOLTIP.REFRESH' | translate}}"
            matTooltipPosition="above"><mat-icon class="self-center mr-6" class="actionIcon">refresh</mat-icon></button>
            <button (click)="addGameEvent()"*ngIf = "userProfile.permission === 'WRITE'" [disabled]="userProfile.permission !== 'WRITE'" matTooltip="{{'TOOLTIP.ADD_SCENARIO' | translate}}"
            matTooltipPosition="above"><mat-icon class="self-center mr-6"
              class="actionIcon">add_circle_outline</mat-icon></button>
            <span class="disabled-wrapper">
              <button (click)="addGameEvent()" matTooltip="{{'TOOLTIP.ADD_SCENARIO' | translate}}"
                 matTooltipPosition="above" *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'"><mat-icon class="self-center mr-6"
                 class="actionIcon">add_circle_outline</mat-icon>
              </button>
            </span>
              <hr>
        </div>
        <div class="game-events-list">
          <table mat-table [dataSource]="dataSource" id="game-events-table">
            <mat-spinner *ngIf="isLoading" [diameter]="spinnerSize" class="mx-auto"></mat-spinner>
            <ng-container class="pt-[10%] text-3xl" matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                {{"GAME_EVENTS.ACTION.TITLE" | translate | uppercase }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.action }}
              </td>
            </ng-container>
            <ng-container class="pt-[10%] text-3xl" matColumnDef="playerId">
              <th mat-header-cell *matHeaderCellDef>
                {{"GAME_EVENTS.PLAYER_ID.TITLE" | translate | uppercase }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.playerId }}
              </td>
            </ng-container>
            <ng-container class="pt-[10%] text-3xl" matColumnDef="sourceEntity">
              <th mat-header-cell *matHeaderCellDef>
                {{"GAME_EVENTS.SOURCE_ENTITY.TITLE" | translate | uppercase }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.sourceEntity }}
              </td>
            </ng-container>
            <ng-container class="pt-[10%] text-3xl" matColumnDef="timestamp">
              <th mat-header-cell *matHeaderCellDef>
                {{"GAME_EVENTS.TIMESTAMP.TITLE" | translate | uppercase }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.timestamp }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                {{"SCENARIO_LIST.ACTIONS" | translate | uppercase}}
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex justify-around">
                  <!-- <mat-icon class="actionIcon"> add </mat-icon> -->
                  <!-- <button *ngIf = "userProfile.permission === 'WRITE'" [disabled]="userProfile.permission !== 'WRITE'" mat-button matTooltip="{{'TOOLTIP.RENAME' | translate}}" matTooltipPosition="above"
                    (click)="openRenameDialog(element.name, element.description, element.category, element.type)">
                    <mat-icon class="actionIcon">edit</mat-icon>
                  </button>
                 <span class="disabled-wrapper">
                  <button mat-button matTooltip="{{'TOOLTIP.RENAME' | translate}}" matTooltipPosition="above"
                  (click)="openRenameDialog(element.name, element.description, element.category, element.type)"  *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'">
                  <mat-icon class="actionIcon">edit</mat-icon>
                </button>
                 </span> -->
                 <!-- <button *ngIf = "userProfile.permission === 'WRITE'" [disabled]="userProfile.permission !== 'WRITE'" mat-button matTooltip="{{'TOOLTIP.RENAME' | translate}}" matTooltipPosition="above"
                 (click)="openRenameDialog(element.name, element.description, element.category, element.type)">
                 <mat-icon class="actionIcon">edit</mat-icon>
               </button> -->
              <!-- <span class="disabled-wrapper">
               <button mat-button matTooltip="{{'TOOLTIP.RENAME' | translate}}" matTooltipPosition="above"
               (click)="openRenameDialog()" class="button-disabled" [disabled]>
               <mat-icon class="actionIcon">edit</mat-icon>
             </button>
              </span> -->
                  <button *ngIf = "userProfile.permission === 'WRITE'"[disabled]="userProfile.permission !== 'WRITE'" mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                    (click)="openDeleteDialog(element.playerId, element.timestamp, element.action, element.sourceEntity)">
                    <mat-icon class="actionIcon">delete</mat-icon>
                  </button>
                  <span class="disabled-wrapper">
                    <button mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                    (click)="openDeleteDialog(element.playerId, element.timestamp, element.action, element.sourceEntity)" *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'">
                    <mat-icon class="actionIcon">delete</mat-icon>
                  </button>
                  </span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">{{"SEARCH_BOX.NO_MATCH" | translate}}</td>
            </tr>
          </table>
          <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
      <app-car-accident-structured-sidenav *ngIf="gameAndDataPath =='carAccident/structuredData'"></app-car-accident-structured-sidenav>
      <app-csi-structured-sidenav *ngIf="gameAndDataPath =='csi/structuredData'"></app-csi-structured-sidenav>
      <app-interrogation-structured-sidenav *ngIf="gameAndDataPath =='interrogation/structuredData'"></app-interrogation-structured-sidenav>
      <app-footer class="fixed bottom-0 w-full"></app-footer>
    </div>
  </div>