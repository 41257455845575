import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-add-ain-response',
  templateUrl: './add-ain-response.component.html',
  styleUrls: ['./add-ain-response.component.css']
})
export class AddAinResponseComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, 
    private router:Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private sdmDataService: SdmDataService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService ) {}

    public username:string ='';
    gameType:string='';
    isLoading: boolean = false;
    spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  ngOnInit(): void {
    this.gameType  = this.interactionService.getSelectedGame();
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  formGroup = this._formBuilder.group({
    playerId: ['', Validators.required],
    timestamp: [
      '', 
      [Validators.required,
      Validators.pattern(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/gm)],
    ],
    text: [
      '',
    [ Validators.required,
      Validators.minLength(2)],
    ],
    sourceEntity: ['', Validators.required],
    audio:[
      '',
     [
      Validators.required, 
      Validators.minLength(2)
     ],
    ]
  });

  formErrors: { field: string; required: string; warningOne: string; warningTwo: string}[] = [
    {
      field: 'playerId',
      required: 'UPDATE_RESPONSE.PLAYER_ID.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'timestamp',
      required: 'UPDATE_RESPONSE.TIMESTAMP.REQUIRED',
      warningOne: 'UPDATE_RESPONSE.TIMESTAMP.WARNINGS.INVALID_FORMAT',
      warningTwo: ""
    },
    {
      field: 'text',
      required: 'UPDATE_RESPONSE.TEXT.REQUIRED',
      warningOne: "UPDATE_RESPONSE.TEXT.WARNINGS.MINLENGTH",
      warningTwo: ""
    },
    {
      field: 'sourceEntity',
      required: 'UPDATE_RESPONSE.SOURCE_ENTITY.REQUIRED',
      warningOne: "UPDATE_RESPONSE.SOURCE_ENTITY.WARNINGS.MINLENGTH",
      warningTwo: ""
    },
    {
      field: 'audio',
      required: 'UPDATE_RESPONSE.AUDIO.REQUIRED',
      warningOne: "UPDATE_RESPONSE.AUDIO.WARNINGS.MINLENGTH",
      warningTwo: ""
    },
  ];

  isFormValid() {
    return this.formGroup.valid;
  }


  addAinResponse(){
    this.isLoading = true;
    this.username = this.interactionService.getUserProfile().userName;
    this.sdmDataService.addAinResponse(
          this.gameType,
          this.formGroup.get('playerId')!.value!,
          this.formGroup.get('timestamp')!.value!,
          this.formGroup.get('text')!.value!,
          this.formGroup.get('sourceEntity')!.value!,
          this.formGroup.get('audio')!.value!
    )
    .subscribe(
              (res: { statusDescription: string; statusCode: string; }) => {
                if (res.statusDescription === 'OK' || res.statusCode === '001') {
                  this.openSnackBar(
                    this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS_NO_REFRESH'),
                    'notif-success',
                    4000
                  );
                  this.isLoading=false;
                  // location.reload();
                  this.dialogRef.close();
                }else {
                  this.openSnackBar(
                    this.translate.instant('SCENARIO_LIST.CREATE_SCENARIO.ERROR'),
                    'notif-error',
                    4000
                  );
                  this.dialogRef.close();
                }
              },
              (err: any) => {
 
              }
            );
  }

  
  onClick(){
    this.dialogRef.close();
  
  }

}
