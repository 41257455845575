import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';

import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-report-vizualization-negotiation-summary',
  templateUrl: './report-vizualization-negotiation-summary.component.html',
  styleUrls: ['./report-vizualization-negotiation-summary.component.css']
})
export class ReportVizualizationNegotiationSummaryComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    public dialogRef: MatDialogRef<ReportVizualizationNegotiationSummaryComponent>, 
    private interactionService: InteractionService
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  closeInvoice() {
    this.dialogRef.close();
  }

  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4');
    const doc = new jsPDF() as any;
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); 
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20);
    const title = 'Negotiation Report';
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
    doc.setFontSize(14);
    doc.setFont('bold');
    doc.setTextColor(255, 255, 255);
    doc.text(title, x, 40); 
    doc.setFont('normal');
    doc.setFontSize(9);
    doc.setTextColor(255, 255, 255); // User info color
    doc.text('User ID: ' + this.data.userId, 10, 50);
    doc.text('Player ID: ' + this.data.playerId, 10, 60);
    doc.text('Participant Full Name: ' + this.data.participantFullName, 10, 70);
    doc.text('Interrogation ID: ' + this.data.interrogationId, 10, 80);
    doc.text('Game Start Timestamp: ' + this.formatTimestamp(this.data.gameStartTimestamp), 10, 90);
    doc.text('Negotiation Start Timestamp: ' + this.formatTimestamp(this.data.negotiationStartTimestamp), 10, 100);
    doc.text('Negotiation End Timestamp: ' + this.formatTimestamp(this.data.negotiationEndTimestamp), 10, 110);
    
    // Adding Negotiation Steps Timestamps
    doc.text('Negotiation Steps Timestamps:', 10, 120);
    
    // Prepare negotiation steps timestamps for PDF
    const negotiationSteps = this.data.negotiationStepsTimestamps.map((timestamp: string) => {
        return this.formatTimestamp(timestamp);
    });

    // Set starting Y position for timestamps
    let startY = 130;
    
    negotiationSteps.forEach((timestamp: string, index: number) => {
        doc.text((index + 1) + '. ' + timestamp, 10, startY);
        startY += 10; // Increment y position for each timestamp
    });

    // Game End Timestamp
    doc.text('Game End Timestamp: ' + this.formatTimestamp(this.data.gameEndTimestamp), 10, startY);

    // Save the generated PDF
    doc.save('negotiation_invoice.pdf');
}

formatTimestamp(timestamp: string | null | undefined): string {
  // Return 'null' if the timestamp is empty or null
  if (!timestamp) {
      return 'null';
  }
  // Format the timestamp if it exists (adjust the formatting as needed)
  return new Date(timestamp).toLocaleString();
}
}
