import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-interrogation-structured-sidenav',
  templateUrl: './interrogation-structured-sidenav.component.html',
  styleUrls: ['./interrogation-structured-sidenav.component.css']
})
export class InterrogationStructuredSidenavComponent implements OnInit {

  public fullPath: string ='';  
  public getGameAndDataTypePath: string ='';
  public isBtnActive: string = '';

  public role:string ='';
  
  constructor(
    private router: Router,
    private interactionService: InteractionService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.role = res.role;

   },
   (err:any) =>{
    console.log("eroare")
   })
  }
}
