import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
//import { EmotionalAssessmentComponent } from 'src/app/components/emotional-assessment/emotional-assessment.component';
//import { AinResponseComponent } from 'src/app/components/ain-response/ain-response.component';



@Component({
  selector: 'app-delete-scenario',
  templateUrl: './delete-scenario.component.html',
  styleUrls: ['./delete-scenario.component.css']
})
export class DeleteScenarioComponent implements OnInit {

  public elementNameToDelete: string = '';
  public ainResponseToDelete: string ='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  public playerId: string ='';
  public timestamp: string ='';
  public gsd: any;
  public overlap: any;
  public value: any;
  public text:string ='';
  public sourceEntity:string ='';
  public audio:string ='';
  public gameType:string =''
  public action:string =''
  public elementFromWhereToDelete ='';
  public userId: string='';
  public interviewId: string='';
  public organizerInstitution: string='';
  public participantFullName: string='';
  public angerGrade: string='';
  public calmGrade: string='';
  public trustGrade: string='';
  public disgustGrade: string='';
  public fearGrade: string='';
  public happinessGrade: string='';
  public sadnessGrade: string='';
  public surpriseGrade: string='';
  public interestGrade: string='';
  public accuracy: string='';
  public gameplaySessionId: string ='';
  public recordId: string ='';
  public motionIndicator: any;
  public speedIndicator: any;
  public kineticEnergyIndicator: any;
  public jointAngles: any;
  public json:any;
  public gameStartTimestamp = '';
  public interrogationStartTimestamp = '';
  public interrogationEndTimestamp = '';
  public confessionTimestamp = '';
  public gameEndTimestamp = '';
  public multipleQuestionRatio = '';
  

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private sdmDataService: SdmDataService,
    private router: Router,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService) { }

    languages = [
      { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
      { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
      { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
      { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
      { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
      { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
      { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
      { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
      { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
      { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
      { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
      { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
    ];
  language = this.interactionService.getLanguage();

  ngOnInit(): void {
    this.isLoading = true;
    this.elementFromWhereToDelete = this.interactionService.getElementFromWhereToDelete();
    this.elementNameToDelete = this.interactionService.getdeletedElementName();
    this.playerId = this.interactionService.getPlayerId();
    this.action = this.interactionService.getAction();
    this.timestamp = this.interactionService.getTimestamp();
    this.audio = this.interactionService.getAudio();
    this.sourceEntity = this.interactionService.getSourceEntity();
    this.text = this.interactionService.getText();
    this.gameType = this.interactionService.getSelectedGame();
    this.userId = this.interactionService.getUserId();
    this.interviewId = this.interactionService.getInterviewId();
    this.organizerInstitution = this.interactionService.getOrganizerInstitution();
    this.participantFullName = this.interactionService.getParticipantFullName();
    this.angerGrade = this.interactionService.getAngerGrade();
    this.calmGrade = this.interactionService.getCalmGrade();
    this.trustGrade = this.interactionService.getTrustGrade();
    this.disgustGrade = this.interactionService.getDisgustGrade();
    this.fearGrade = this.interactionService.getFearGrade();
    this.happinessGrade = this.interactionService.getHappinessGrade();
    this.sadnessGrade = this.interactionService.getSadnessGrade();
    this.surpriseGrade = this.interactionService.getSurpriseGrade();
    this.interestGrade = this.interactionService.getInterestGrade();
    this.accuracy = this.interactionService.getAccuracy();
    this.gsd = this.interactionService.getGSD();
    this.overlap = this.interactionService.getOverlap();
    this.value = this.interactionService.getValue();
    this.gameplaySessionId = this.interactionService.getGameplaySessionId();
    this.recordId = this.interactionService.getRecordId();
    this.motionIndicator = this.interactionService.getMotionIndicator();
    this.speedIndicator = this.interactionService.getSpeedIndicator();
    this.kineticEnergyIndicator = this.interactionService.getKineticEnergyIndicator();
    this.jointAngles = this.interactionService.getJointAngles();
    this.json = this.interactionService.getJson();
    this.gameStartTimestamp = this.interactionService.getGameStartTimestamp();
    this.interrogationStartTimestamp = this.interactionService.getInterrogationStartTimestamp();
    this.interrogationEndTimestamp = this.interactionService.getInterrogationEndTimestamp();
    this.confessionTimestamp = this.interactionService.getConfessionTimestamp();
    this.gameEndTimestamp = this.interactionService.getGameEndTimestamp();
    this.multipleQuestionRatio = this.interactionService.getMultipleQuestionRatio();
    this.isLoading = false;
  }

  onClick() {
    this.dialogRef.close();

  }

  onDelete() {
    this.isLoading = true;
    this.sdmDataService.deleteScenario(this.elementNameToDelete)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK' && res.statusCode === '001') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("DELETE_SCENARIO.ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteAinResponse(){
    this.isLoading = true;
    this.sdmDataService.deleteAinResponse(this.playerId, this.timestamp, this.audio, this.sourceEntity, this.text, this.gameType)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }
////
  onDeleteEmotionalAssessment(){
    this.isLoading = true;
    this.sdmDataService.deleteEmotionalAssessment(this.userId, this.interviewId, this.timestamp, this.organizerInstitution, this.participantFullName, this.angerGrade, this.calmGrade, this.trustGrade, this.disgustGrade, this.fearGrade, this.happinessGrade, this.sadnessGrade, this.surpriseGrade, this.interestGrade, this.accuracy)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'emotion_recognition_recording_successfully_deleted') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERROGATION.EMOTIONAL_ASSESSMENT.DELETE_SUCCESS'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.emotionalAssessment.getEmotionalAssessment()
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("INTERROGATION.EMOTIONAL_ASSESSMENT.DELETE_ERROR_MESSAGE"), 
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }
  //////

  onDeleteNpcResponse(){
    this.isLoading = true;
    this.sdmDataService.deleteNpcResponse(this.playerId, this.timestamp, this.audio, this.sourceEntity, this.text, this.gameType)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("NPC_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteApartmentScenario(){
    this.isLoading = true;
    this.sdmDataService.deleteApartmentScenario(this.playerId, this.timestamp)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteCityAttacker(){
    this.isLoading = true;
    this.sdmDataService.deleteCityAttacker(this.playerId, this.timestamp)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteTwoCarsCrash(){
    this.isLoading = true;
    this.sdmDataService.deleteTwoCarsCrash(this.playerId, this.timestamp)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteVictimCrossing(){
    this.isLoading = true;
    this.sdmDataService.deleteVictimCrossing(this.playerId, this.timestamp)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteImageryAcquisition(){
    this.isLoading = true;
    let gameType = this.interactionService.getSelectedGame();
    this.sdmDataService.deleteImageryAcquisition(this.userId, this.timestamp, gameType)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteVictimOnCurb(){
    this.isLoading = true;
    this.sdmDataService.deleteVictimOnCurb(this.playerId, this.timestamp)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  
  onDeleteGameEvent(){
    this.isLoading = true;
    this.sdmDataService.deleteGameEvent(this.playerId, this.timestamp, this.action, this.sourceEntity,  this.gameType)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'the_file_was_deleted_successfully') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("GAME_EVENTS.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteQualityAssReport(){
    this.isLoading = true;
    let gameType = this.interactionService.getSelectedGame();
    this.sdmDataService.onDeleteQualityAssReport(this.playerId, this.timestamp, gameType, this.gsd, this.overlap,  this.value)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'quality_assessment_report_successfully_deleted') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("GAME_EVENTS.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  // onDeleteAvatarMoves(){
  //   this.isLoading = true;
  //   this.sdmDataService.deleteAvatarMoves(this.gameplaySessionId)
  //     .subscribe(
  //       // If the response is good then deactivate loading spinner from UI and display the success notification
  //       (res) => {
  //         if (res.statusDescription === 'OK') {
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
  //             'notif-success',
  //             4000
  //           );
  //           // location.reload();
  //           this.dialogRef.close();
  //           this.isLoading = false;
  //           //this.ainResponse.getAinResponse(this.gameType);
  //         } else {
  //           this.isLoading = false;
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
  //             'notif-error',
  //             4000
  //           );
  //         }

  //       },
        
  //       (err: any) => {
  //         this.isLoading = false;
  //         this.dialogRef.close();
  //         this.openSnackBar(
  //           this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
  //           'notif-warning',
  //           4000
  //         );;
  //       }
  //     );
  // }

  deleteAvatarMovesByAll(){
    this.isLoading = true;
    this.sdmDataService.deleteAvatarMovesByAll(this.json)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  // onDeleteCameraSurveillance(){
  //   this.isLoading = true;
  //   this.sdmDataService.deleteCameraSurveillance(this.gameplaySessionId)
  //     .subscribe(
  //       // If the response is good then deactivate loading spinner from UI and display the success notification
  //       (res) => {
  //         if (res.statusDescription === 'OK') {
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
  //             'notif-success',
  //             4000
  //           );
  //           // location.reload();
  //           this.dialogRef.close();
  //           this.isLoading = false;
  //           //this.ainResponse.getAinResponse(this.gameType);
  //         } else {
  //           this.isLoading = false;
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
  //             'notif-error',
  //             4000
  //           );
  //         }

  //       },
        
  //       (err: any) => {
  //         this.isLoading = false;
  //         this.dialogRef.close();
  //         this.openSnackBar(
  //           this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
  //           'notif-warning',
  //           4000
  //         );;
  //       }
  //     );
  // }

  onDeleteCameraSurveillanceByAll(){
    this.isLoading = true;
    this.sdmDataService.deleteCameraSurveillance(this.json)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  // onDeleteGameActions(){
  //   this.isLoading = true;
  //   this.sdmDataService.deleteGameAction(this.gameplaySessionId)
  //     .subscribe(
  //       // If the response is good then deactivate loading spinner from UI and display the success notification
  //       (res) => {
  //         if (res.statusDescription === 'OK') {
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
  //             'notif-success',
  //             4000
  //           );
  //           // location.reload();
  //           this.dialogRef.close();
  //           this.isLoading = false;
  //           //this.ainResponse.getAinResponse(this.gameType);
  //         } else {
  //           this.isLoading = false;
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
  //             'notif-error',
  //             4000
  //           );
  //         }

  //       },
        
  //       (err: any) => {
  //         this.isLoading = false;
  //         this.dialogRef.close();
  //         this.openSnackBar(
  //           this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
  //           'notif-warning',
  //           4000
  //         );;
  //       }
  //     );
  // }

  onDeleteGameActionsByAll(){
    this.isLoading = true;
    this.sdmDataService.deleteGameAction(this.json)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }



  // onDeleteNPCMoves(){
  //   this.isLoading = true;
  //   this.sdmDataService.deleteNPCMove(this.gameplaySessionId)
  //     .subscribe(
  //       // If the response is good then deactivate loading spinner from UI and display the success notification
  //       (res) => {
  //         if (res.statusDescription === 'OK') {
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
  //             'notif-success',
  //             4000
  //           );
  //           // location.reload();
  //           this.dialogRef.close();
  //           this.isLoading = false;
  //         } else {
  //           this.isLoading = false;
  //           this.dialogRef.close();
  //           this.openSnackBar(
  //             this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
  //             'notif-error',
  //             4000
  //           );
  //         }

  //       },
        
  //       (err: any) => {
  //         this.isLoading = false;
  //         this.dialogRef.close();
  //         this.openSnackBar(
  //           this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
  //           'notif-warning',
  //           4000
  //         );;
  //       }
  //     );
  // }

  onDeleteNPCMovesByAll(){
    this.isLoading = true;
    this.sdmDataService.deleteNPCMove(this.json)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteRealTimeRecordQuest(){
    this.isLoading = true;
    this.sdmDataService.deleteRealTimeRecordQuest(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  

  onDeleteHumanStance(){
    this.isLoading = true;
    this.sdmDataService.onDeleteHumanStance(this.playerId, this.userId, this.interviewId, this.participantFullName, this.timestamp, this.motionIndicator, this.speedIndicator, this.kineticEnergyIndicator, this.jointAngles)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteInterrogationSummary(){
    this.isLoading = true;
    this.sdmDataService.onDeleteInterrogationSummary(this.userId, this.playerId, this.participantFullName, 
      this.interviewId, this.gameStartTimestamp, this.interrogationStartTimestamp, this.interrogationEndTimestamp, 
      this.confessionTimestamp, this.gameEndTimestamp, this.multipleQuestionRatio)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteNegotiationSummary(){
    this.isLoading = true;
    this.sdmDataService.onDeleteNegotiationSummary(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }
  onDeleteCSIReportGeneration(){
    this.isLoading = true;
    this.sdmDataService.onDeleteCSIReportGeneration(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteAnomalyDetection(){
    this.isLoading = true;
    this.sdmDataService.onDeleteAnomalyDetection(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteLocationPrediction(){
    this.isLoading = true;
    this.sdmDataService.onDeleteLocationPrediction(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }
  onDeleteAiResult(){
    this.isLoading = true;
    this.sdmDataService.onDeleteAiResult(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteBloodstainOriginPointAnalysis(){
    this.isLoading = true;
    this.sdmDataService.onDeleteBloodstainOriginPointAnalysis(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteBulletTrajectoryAnalysis(){
    this.isLoading = true;
    this.sdmDataService.onDeleteBulletTrajectoryAnalysis(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteCarDeformationsAnalysis(){
    this.isLoading = true;
    this.sdmDataService.onDeleteCarDeformationsAnalysis(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteAssessmentResults(){
    this.isLoading = true;
    this.sdmDataService.onDeleteAssessmentResults(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }


  onDeleteObjectCentroids(){
    this.isLoading = true;
    this.sdmDataService.onDeleteObjectCentroids(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteLmsQuizResults(){
    this.isLoading = true;
    this.sdmDataService.onDeleteLmsQuizResults(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  onDeleteVRScenarios(){
    this.isLoading = true;
    this.sdmDataService.onDeleteVRScenarios(this.recordId)
      .subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.DELETE.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            // location.reload();
            this.dialogRef.close();
            this.isLoading = false;
            //this.ainResponse.getAinResponse(this.gameType);
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("AIN_RESPONSE.DELETE_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
          }

        },
        (err: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
            'notif-warning',
            4000
          );;
        }
      );
  }

  

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  

}
