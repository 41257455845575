<p class="3xl:text-lg 4k:text-3xl">{{ "LOGOUT.QUESTION" | translate }}</p>

<div>
    <!-- Overlay loading spinner -->
    <div
    *ngIf="isLoading === true"
    class="fixed h-full w-full bg-white opacity-75 z-50"
  >
    <div
      class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4"
    >
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <mat-toolbar class="bg-white">
    <mat-toolbar-row>
      <div
      class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded " 
      (click)="onLogout()"
      >
        {{ "LOGOUT.YES" | translate }}
      </div>
      <div class="w-[30%] inline-block bg-white"></div>
      <div
      class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded " 
      (click)="onClick()"
      >
        {{ "LOGOUT.NO" | translate }}
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
