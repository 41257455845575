<div class="form">
    <div *ngIf="isLoading === true" class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
        <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
          <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
        </div>
      </div>
      <h2 mat-dialog-title>{{"AIN_RESPONSE.CREATE_AIN_RESPONSE" | translate}}</h2>
      <form [formGroup]="formGroup" class=" text-[#ffc601] " (keyup.enter)="addAinResponse()">
  
        <mat-form-field class="w-full text-[#ffc601]">
          <mat-label>{{ "NPC_RESPONSE.PLAYER_ID" | translate}}</mat-label>
          <input type="text" matInput formControlName="playerId" />
        </mat-form-field>
    
        <mat-form-field class="w-full text-[#ffc601]">
          <mat-label>{{ "NPC_RESPONSE.TEXT" | translate}}</mat-label>
          <input type="text" matInput minlength="2" formControlName="text" />
        </mat-form-field>

        <mat-form-field class="w-full text-[#ffc601]">
            <mat-label>{{ "NPC_RESPONSE.TIMESTAMP" | translate}}</mat-label>
            <input type="text" matInput formControlName="timestamp" />
          </mat-form-field>

          <mat-form-field class="w-full text-[#ffc601]">
            <mat-label>{{ "NPC_RESPONSE.SOURCE_ENTITY" | translate}}</mat-label>
            <input type="text" matInput formControlName="sourceEntity" />
          </mat-form-field>

          <mat-form-field class="w-full text-[#ffc601]">
            <mat-label>{{ "NPC_RESPONSE.AUDIO" | translate}}</mat-label>
            <input type="text" matInput formControlName="audio" />
          </mat-form-field>

      </form>
    </div>
    <div class="text-[10px]">
      * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
    </div>
    <div *ngFor="let error of formErrors">
      <div class="text-[#ffc601]" *ngIf="
          (formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.touched) ||
          (formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.dirty)
        ">
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
            formGroup.get(error.field)?.invalid &&
            formGroup.get(error.field)?.errors?.['required']
          ">
          {{ error.required | translate }}
        </div>
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
            formGroup.get(error.field)?.invalid &&
            (formGroup.get(error.field)?.errors?.['pattern'] ||
              formGroup.get(error.field)?.errors?.['minlength'])
          ">
          {{ error.warningOne | translate }}
        </div>
        <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
        formGroup.get(error.field)?.invalid &&
        (formGroup.get(error.field)?.errors?.['pattern'] ||
          formGroup.get(error.field)?.errors?.['minlength'])
      ">
       {{ error.warningTwo | translate }}
    </div>
      </div>
    </div>
    <mat-dialog-actions class="cursor-pointer">
        <button mat-raised-button (click)="onClick()"
          class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">Cancel</button>
        <button mat-raised-button (click)="addAinResponse()"[disabled]="!isFormValid()"
          class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">OK
        </button>
      </mat-dialog-actions>