import { Component, OnInit } from '@angular/core';
import { LmsQuizResultsComponent } from '../lms-quiz-results/lms-quiz-results.component';
import { MatDialogRef } from '@angular/material/dialog';
import { InteractionService } from 'src/app/services/interaction.service';
import { jsPDF } from 'jspdf';

import 'jspdf-autotable'; 


@Component({
  selector: 'app-report-vizualization-lms-quiz-results',
  templateUrl: './report-vizualization-lms-quiz-results.component.html',
  styleUrls: ['./report-vizualization-lms-quiz-results.component.css']
})
export class ReportVizualizationLmsQuizResultsComponent implements OnInit {

 isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data: any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    private interactionService: InteractionService,
    public dialogRef: MatDialogRef<LmsQuizResultsComponent>
  ) { }

  ngOnInit(): void {
    this.data = this.interactionService.getJson();
     this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  closeReport() {
    this.dialogRef.close();
  }

  formatTimestamp(timestamp: number | string | null | undefined): string {
    // Check if the timestamp is null or undefined
    if (timestamp === null || timestamp === undefined) {
        return 'null'; // Return 'null' for undefined or null values
    }

    // If the timestamp is a string, check if it is '0'
    if (typeof timestamp === 'string') {
        if (timestamp === '0') {
            return '0'; // Return '0' if the string is exactly '0'
        }
        // Try to parse the string as a number
        timestamp = parseInt(timestamp, 10);
        if (isNaN(timestamp)) {
            return 'Invalid timestamp'; // Handle invalid number strings
        }
    }

    // If the timestamp is 0 (after potential parsing), return '0'
    if (timestamp === 0) {
        return '0';
    }

    // Convert Unix timestamp (seconds) to JavaScript Date object (milliseconds)
    const date = new Date(timestamp * 1000);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format as 'yyyy-MM-dd HH:mm:ss'
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4');
    const doc = new jsPDF() as any;
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); 
    const title = 'Quiz Report for ' + this.data.userData.first_name + ' ' + this.data.userData.last_name;
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
  
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); 

    doc.setFontSize(14); 
    doc.setFont('bold'); 
    doc.setTextColor(255, 255, 255); 
    doc.text(title, x, 40); 

    doc.setFont('normal'); 
    doc.setFontSize(9); 
    doc.setTextColor(255, 255, 255); 
    doc.text('User ID: ' + this.data.userData.id, 10, 50);
    doc.text('Email: ' + this.data.userData.email, 10, 60);
    
    const courseInfotTitle = 'Course Information';
    const courseInfoWidth = doc.getTextWidth(courseInfotTitle);
    const courseInfoCenter = (doc.internal.pageSize.getWidth() - courseInfoWidth) / 2;
    doc.text(courseInfotTitle, courseInfoCenter, 70);
    doc.setFontSize(12);

    doc.setFont('normal'); 
    doc.setFontSize(9); 
    doc.text('Course Name: ' + this.data.courseName, 10, 80);
    doc.text('Quiz Name: ' + this.data.quizName, 10, 90);

    const quizDetailsTitle = 'Quiz Details';
    const quizDetailsWidth = doc.getTextWidth(quizDetailsTitle);
    const quizDetailsCenter = (doc.internal.pageSize.getWidth() - quizDetailsWidth) / 2;

    doc.text(quizDetailsTitle, quizDetailsCenter, 100);
    doc.text('Time Open: ' + this.formatTimestamp(this.data.quizDetails.timeopen), 10, 110);
    doc.text('Time Close: ' + this.formatTimestamp(this.data.quizDetails.timeclose), 10, 120);
    doc.text('Time Limit: ' + this.data.quizDetails.timelimit + ' minutes', 10, 130);
    doc.text('Grade: ' + this.data.quizDetails.grade, 10, 140);


    const quizAttemptsDetailsTitle = 'Quiz Attempts Details';
    const quizAttemptsDetailsWidth = doc.getTextWidth(quizAttemptsDetailsTitle);
    const quizAttemptsDetailsCenter = (doc.internal.pageSize.getWidth() - quizAttemptsDetailsWidth) / 2;
    doc.setFont('normal');
    doc.setFontSize(10); 
    doc.text(quizAttemptsDetailsTitle, quizAttemptsDetailsCenter, 150);
    const tableData = this.data.quizAttempts.map((attempt: any) => [
        attempt.id,
        attempt.quiz,
        attempt.userid,
        attempt.attempt,
        attempt.uniqueid,
        attempt.layout,
        attempt.currentpage,
        attempt.preview,
        attempt.state,
        this.formatTimestamp(attempt.timestart),
        this.formatTimestamp(attempt.timefinish),
        this.formatTimestamp(attempt.timemodified),
        this.formatTimestamp(attempt.timemodifiedoffline),
        attempt.timecheckstate || 'N/A',
        attempt.sumgrades,
        this.formatTimestamp(attempt.gradednotificationsenttime),
    ]);
    const tableHeaders = [
        'ID', 'Quiz', 'User ID', 'Attempt', 'Unique ID', 'Layout', 'Current Page', 'Preview',
        'State', 'Time Start', 'Time Finish', 'Time Modified', 'Time Modified Offline',
        'Time Check State', 'Sum Grades', 'Graded Notification Sent Time'
    ];
    (doc as any).autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 160,
        theme: 'grid', 
        styles: {
            fontSize: 6, 
            textColor: [255, 255, 255], 
            fillColor: null, 
            halign: 'center',
        },
        headStyles: {
            fillColor: [60, 25, 138], 
            textColor: [255, 255, 255],
            fontSize: 6, 
            halign: 'center', 
        },
        columnStyles: { 
            0: { cellWidth: 7 },   
            1: { cellWidth: 8 },   
            2: { cellWidth: 8 },   
            3: { cellWidth: 12 },  
            4: { cellWidth: 11 },  
            5: { cellWidth: 12 },  
            6: { cellWidth: 11 },  
            7: { cellWidth: 12 },  
            8: { cellWidth: 11 },  
            9: { cellWidth: 14 }, 
            10: { cellWidth: 14 }, 
            11: { cellWidth: 14 }, 
            12: { cellWidth: 13 }, 
            13: { cellWidth: 13 }, 
            14: { cellWidth: 12 }, 
            15: { cellWidth: 16 }, 
        },
        margin: { top: 10 }, 
    });
    doc.save('quiz_report.pdf');
}
}