import { AbstractControl, ValidationErrors } from '@angular/forms';
// import * as bcrypt from "bcryptjs";
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';

export class PasswordValidators {

  constructor(){
    
  }
  static shouldBeOldPassword(control: AbstractControl): Promise<ValidationErrors | null> {
    return new Promise((resolve, reject) => {
      resolve({ shouldBeOldPassword: true });
         
    });
  }
  
  static newPasswordSameAsOldPassword(control: AbstractControl) {

    let oldPassword = control.get('oldPassword');
    let newPAssword = control.get('newPassword');
    if(oldPassword!.value === newPAssword!.value){
      return { newPasswordSameAsOldPassword: true };
    }
    return null;
  }
  // static newPasswordSameAsOldPassword(control: AbstractControl): Promise<ValidationErrors | null> {
  //   return new Promise((resolve, reject) => {
  //     if (localStorage.getItem('admin_user_profile')) {
  //       let response = JSON.parse(localStorage.getItem('admin_user_profile')!);
  
  //       if (response.passwordHash != null) {
  //         let oldPassword = response.passwordHash;
  //         if (bcrypt.compareSync(control.value, oldPassword)) {
  //           resolve({ newPasswordSameAsOldPassword: true });
  //         } else {
  //           resolve(null);
  //         }
  //       } else {
  //         resolve(null);
  //       }
  //     } else {
  //       resolve(null);
  //     }
  //   });
  // }
  


  static matchPasswords(control: AbstractControl) {

    let newPassword2 = control.get('newPassword');
    let confirmPassword2 = control.get('confirmPassword');
    if(newPassword2!.value !== confirmPassword2!.value){
      return { passwordsDontMatch: true };
    }
    return null;
  }


}