import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
// import { DeleteDialogComponent } from 'src/app/file-explorer/modals/delete-dialog/delete-dialog.component';
import { FileDataService } from 'src/app/services/file-data/file-data.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-element',
  templateUrl: './edit-element.component.html',
  styleUrls: ['./edit-element.component.css']
})
export class EditElementComponent implements OnInit {

  isLoading: boolean = false;
  public isDirectory: any;
  public elementPath: string = '';
  currentPath!: string;
  public currentName: string = '';
  public elementNameToRename: string ='';
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  formGroup = this._formBuilder.group({
    newName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^(?=.{1,255}$)[\w\-. ]+\w$/),
      ],
    ],
  });
  formErrors: { field: string; required: string; warningOne: string; warningTwo: string}[] = [
    {
      field: 'newName',
      required: 'UPDATE_SCENARIO.NEW_NAME.REQUIRED',
      warningOne: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.MINLENGTH',
      warningTwo: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.INVALID_FORMAT'
    },
  ];


  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private fileDataService: FileDataService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private interactionService: InteractionService) { }
  public newName: string = '';

  ngOnInit(): void {
    this.isLoading = true;
    this.elementPath = this.interactionService.getElementPath();
    this.elementNameToRename=this.interactionService.getRenamedElementName();
    this.currentName = this.interactionService.getRenamedElementName();
    this.isDirectory = this.interactionService.getElementType();
    this.isLoading = false;
  }

  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();

  onClick() {
    this.dialogRef.close();

  }
  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }

  onRename() {
    this.isLoading = true;
    let formName = this.formGroup.get('newName')!.value!
    let element = this.interactionService.getElementFromMenu();
    this.currentPath = this.pushToPath(this.elementPath, formName!);
    let gameSelected = this.interactionService.getSelectedGame();
    switch(gameSelected){
      case "carAccident":{
         this.renameForCarrAccidentByElementAndPath(element, this.currentPath);
         break;
      }
      case "interrogation":{
        this.renameForInterrogationElementAndPath(element, this.currentPath);
        break;
      }
      case "csi":{
        this.renameForCsiElementAndPath(element, this.currentPath);
        break;
      }
      case "terroristAttack":{
        this.renameForTerrAttackElementAndPath(element, this.currentPath);
        break;
      }
    }
  }

  renameForCarrAccidentByElementAndPath(element: string, path:string){
    this.isLoading = true;
    this.interactionService.setElementFromMenu(element);
    let elementGame = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    let elementName = this.elementNameToRename;
    this.currentPath = this.pushToPath(this.elementPath, elementName!);
    this.fileDataService.renameForCarrAccidentByElementAndPath(elementGame, this.currentPath, this.formGroup.get('newName')!.value!, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'OK' || res.statusCode === '001') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.RENAME_ELEMENT.ERROR"),
              'notif-error',
              4000
            );
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          if(err.error.statusDescription === 'duplicate_element_name_please_give_another_name'){
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.DUPLICATE_ERROR'),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }else{
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        }
      );
  }
  renameForInterrogationElementAndPath(element: string, path:string){
    this.isLoading = true;
    this.interactionService.setElementFromMenu(element);
    let elementGame = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    let elementName = this.elementNameToRename;
    this.currentPath = this.pushToPath(this.elementPath, elementName!);
    this.fileDataService.renameForInterrogationElementAndPath(elementGame, this.currentPath, this.formGroup.get('newName')!.value!, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'OK' || res.statusCode === '001') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.RENAME_ELEMENT.ERROR"),
              'notif-error',
              4000
            );
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          if(err.error.statusDescription === 'duplicate_element_name_please_give_another_name'){
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.DUPLICATE_ERROR'),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }else{
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        }
      );
  }
  renameForCsiElementAndPath(element: string, path:string){
    this.isLoading = true;
    this.interactionService.setElementFromMenu(element);
    let elementGame = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    let elementName = this.elementNameToRename;
    this.currentPath = this.pushToPath(this.elementPath, elementName!);
    this.fileDataService.renameForCsiElementAndPath(elementGame, this.currentPath, this.formGroup.get('newName')!.value!, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'OK' || res.statusCode === '001') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.RENAME_ELEMENT.ERROR"),
              'notif-error',
              4000
            );
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          if(err.error.statusDescription === 'duplicate_element_name_please_give_another_name'){
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.DUPLICATE_ERROR'),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }else{
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        }
      );
  }
  renameForTerrAttackElementAndPath(element: string, path:string){
    this.isLoading = true;
    this.interactionService.setElementFromMenu(element);
    let elementGame = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    let elementName = this.elementNameToRename;
    this.currentPath = this.pushToPath(this.elementPath, elementName!);
    this.fileDataService.renameForTerrAttackElementAndPath(elementGame, this.currentPath, this.formGroup.get('newName')!.value!, this.isDirectory)
      .subscribe(
        (res: { statusDescription: string; statusCode: string; }) => {
          if (res.statusDescription === 'OK' || res.statusCode === '001') {
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS_NO_REFRESH'),
              'notif-success',
              4000
            );
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.RENAME_ELEMENT.ERROR"),
              'notif-error',
              4000
            );
          }
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          if(err.error.statusDescription === 'duplicate_element_name_please_give_another_name'){
            this.openSnackBar(
              this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.DUPLICATE_ERROR'),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }else{
            this.isLoading = false;
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        }
      );
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }
  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }
  isFormValid() {
    return this.formGroup.valid;
  }
}
