import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const lawgameCommunicationAPIURL = environment.lawgameCommunication;

export interface USER_PROFILE {
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    role: string;
    languageCode: string;
    organizationDescription: string;
    blocked: boolean;
    permission: string;
  }

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }

  
  updatePassword(oldPassword:string, newPassword:string){
    const body = {
      old_password: oldPassword,
      new_password: newPassword
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/updatePassword', body)
      .pipe(catchError(ProfileService.handleError));
  }

  updateProfile(newFirstName:string, newLastName:string){
    const body = {
      newFirstName: newFirstName,
      newLastName: newLastName
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/userProfile', body)
      .pipe(catchError(ProfileService.handleError));
  }



  
}
