
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidators } from './password.validators';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/token/token.service';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent {
  changePasswordFormGroup: FormGroup; 
  isLoading: Boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  
  constructor(
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private _snackBar: MatSnackBar, 
    private authService: AuthService,
    private translate: TranslateService,){

    this.changePasswordFormGroup = this._formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#\$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d!\"#\$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{9,}$/)]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: [PasswordValidators.matchPasswords, PasswordValidators.newPasswordSameAsOldPassword]
    });
  }
  onChangePassword(){
    if(this.isFormValid()){
      this.isLoading = true;
      this.profileService.updatePassword(this.changePasswordFormGroup.get('oldPassword')!.value, this.changePasswordFormGroup.get('newPassword')!.value).subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK' || res.status === true) {
            this.isLoading = false;
            //this.interactionService.setPasswordUpdated(true); 

            this.authService.logout(); // Log out the user
          
            this.openSnackBar(
              "Password updated, please login with new password",
                'notif-success',
                4000
              );
              location.reload();
          } 
          
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          if(err.error.statusDescription == "incorrect_old_password"){
            this.isLoading = false;
          //  this.oldPassword?.setErrors(PasswordValidators.shouldBeOldPassword)
            this.openSnackBar(
              this.translate.instant("Old password is not correct"),
              'notif-error',
              4000
            );
          }
          else if(err.error.statusDescription == "old_password_same_with_new_password"){
            this.isLoading = false;
          //  this.oldPassword?.setErrors(PasswordValidators.shouldBeOldPassword)
            this.openSnackBar(
              this.translate.instant("New password same as old password"),
              'notif-error',
              4000
            );
          }
          
          else{
   
            this.openSnackBar(
              "Session expired",
               'notif-warning',
               4000
             );
          }
       
          this.isLoading = false;
        }
      )
    }
  }

  get oldPassword(){
    return this.changePasswordFormGroup.get('oldPassword');
  }

   get newPassword(){
    return this.changePasswordFormGroup.get('newPassword');
  }

   get confirmPassword(){
    return this.changePasswordFormGroup.get('confirmPassword');
  }
  isFormValid() {
    return this.changePasswordFormGroup.valid;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }



}
