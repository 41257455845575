
<div
*ngIf="isLoading === true"
class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
<div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
  <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
</div>
</div>
<p class="3xl:text-lg 4k:text-3xl"> {{"ACTIONS_BUTTONS.DELETE.QUESTION" | translate}} {{elementNameToDelete + "?"}}</p>

<!-- <div *ngIf="">
  <mat-toolbar class="bg-white" (keyup.enter)="onDelete()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDelete()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div> -->


<div *ngIf="elementFromWhereToDelete === 'ain'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteAinResponse()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteAinResponse()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'emotional'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteEmotionalAssessment()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteEmotionalAssessment()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'npc'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteNpcResponse()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteNpcResponse()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'gameEvents'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteGameEvent()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteGameEvent()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<!-- ///// -->

<div *ngIf="elementFromWhereToDelete === 'cityAttack'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteCityAttacker()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteCityAttacker()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'apartmentScenario'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteApartmentScenario()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteApartmentScenario()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'twoCarsCrash'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteTwoCarsCrash()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteTwoCarsCrash()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'victimCrossing'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteVictimCrossing()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteVictimCrossing()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'victimOnCurb'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteVictimOnCurb()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteVictimOnCurb()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'imageryAcquisition'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteImageryAcquisition()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteImageryAcquisition()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'qualityReport'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteQualityAssReport()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteQualityAssReport()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'avatarMoves'">
  <mat-toolbar class="bg-white" (keyup.enter)="deleteAvatarMovesByAll()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="deleteAvatarMovesByAll()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'cameraSurveillance'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteCameraSurveillanceByAll()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteCameraSurveillanceByAll()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'gameActions'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteGameActionsByAll()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteGameActionsByAll()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'npcMoves'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteNPCMovesByAll()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteNPCMovesByAll()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'realTimeRecordQuests'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteRealTimeRecordQuest()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteRealTimeRecordQuest()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'humanStance'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteHumanStance()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteHumanStance()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'interrogationSummary'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteInterrogationSummary()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteInterrogationSummary()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'negotiationSummary'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteNegotiationSummary()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteNegotiationSummary()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'anomalyDetection'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteAnomalyDetection()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteAnomalyDetection()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'locationPrediction'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteLocationPrediction()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteLocationPrediction()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'aiResults'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteAiResult()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteAiResult()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'reportGenerationCSI'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteCSIReportGeneration()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteCSIReportGeneration()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'bulletTrajectoryAnalysis'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteBulletTrajectoryAnalysis()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteBulletTrajectoryAnalysis()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'bloodstainOriginPointAnalysis'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteBloodstainOriginPointAnalysis()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteBloodstainOriginPointAnalysis()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'carDeformationsAnalysis'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteCarDeformationsAnalysis()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteCarDeformationsAnalysis()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'assessmentResults'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteAssessmentResults()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteAssessmentResults()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'objectCentroids'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteObjectCentroids()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteObjectCentroids()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="elementFromWhereToDelete === 'lmsQuizData'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteLmsQuizResults()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteLmsQuizResults()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>


<div *ngIf="elementFromWhereToDelete === 'vrScenarios'">
  <mat-toolbar class="bg-white" (keyup.enter)="onDeleteVRScenarios()">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDeleteVRScenarios()">{{"ACTIONS_BUTTONS.DELETE.YES" | translate}}</div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">{{"ACTIONS_BUTTONS.DELETE.NO" | translate}}</div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
