import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InteractionService } from 'src/app/services/interaction.service';
import { ReportVisualizationComponent } from '../report-visualization/report-visualization.component';
import { jsPDF } from 'jspdf';

import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-report-vizualization-csi-and-lms-results',
  templateUrl: './report-vizualization-csi-and-lms-results.component.html',
  styleUrls: ['./report-vizualization-csi-and-lms-results.component.css']
})
export class ReportVizualizationCsiAndLmsResultsComponent implements OnInit {
  username: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data: any;
  backgroundImageBase64: string = '';
  logoBase64: string = '';
  constructor(
    public dialogRef: MatDialogRef<ReportVizualizationCsiAndLmsResultsComponent>,
    private interactionService: InteractionService
  ) { }

  ngOnInit(): void {
    this.data = this.interactionService.getJson();

    // Log the data for debugging purposes
    console.log('Data received:', this.data);

    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  photoError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/default.jpg';
  }

  closeReport() {
    this.dialogRef.close();
  }

  formatTimestamp(timestamp: number | null | undefined): string {
    // Check if the timestamp is null, undefined, or 0
    if (timestamp === null || timestamp === undefined || timestamp === 0) {
      return timestamp === 0 ? '0' : 'null'; // Return '0' if timestamp is exactly 0, otherwise return 'null'
    }

    // Convert Unix timestamp (seconds) to JavaScript Date object (milliseconds)
    const date = new Date(timestamp * 1000);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format as 'yyyy-MM-dd HH:mm:ss'
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  downloadPDF(): void {
    // const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm

    // Function to add background image to the current page
    const addBackgroundImage = () => {
      doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, pageWidth, pageHeight);
    };

    // Function to check and add a new page if necessary
    const checkPageOverflow = () => {
      if (positionY > pageHeight - 40) { // 40 mm for bottom margin
        doc.addPage(); // Add a new page
        addBackgroundImage(); // Add background to the new page
        positionY = 20; // Reset positionY for new page
      }
    };

    // Add the background image for the first page BEFORE adding content
    addBackgroundImage();

    // Title text
    const title = 'CSI Final Report for ' + this.data.username;
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

    // Add logo (base64 image)
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed

    // Add report title with decreased font size and bold
    doc.setFontSize(12); // Smaller font size for title
    doc.setFont('bold'); // Set font to bold
    doc.setTextColor(255, 255, 255); // Set title color to white for better contrast
    doc.text(title, x, 40); // Adjust y position to accommodate logo

    // Reset to normal font and set text color
    doc.setFont('normal'); // Reset to normal font
    doc.setFontSize(9); // Adjust font size for user info
    doc.setTextColor(255, 255, 255); // Set text color to white for user info

    let positionY = 50;

    // Add user info (Username, Date, Module Name, etc.)
    const userInfo = [
      { label: 'Date and Time', value: this.data.datetime },
      { label: 'Module Name', value: this.data.moduleName },
      { label: 'Scenario URL', value: this.data.scenarioUrl || 'N/A' },
      { label: 'Scenario Max Points', value: this.data.scenarioMaxPoints },
      { label: 'Final Score', value: this.data.finalScore },
      { label: 'Trainer Name', value: this.data.trainerName },
      { label: 'Evaluator Name', value: this.data.evaluatorName },
    ];

    userInfo.forEach(info => {
      doc.text(`${info.label}: ${info.value}`, 10, positionY);
      positionY += 10;
      checkPageOverflow(); // Check for page overflow after each entry
    });

    // Add a section title for Procedure Details
    const procedureTitle = 'Procedure Details';
    const procedureTitleWidth = doc.getTextWidth(procedureTitle);
    const w = (doc.internal.pageSize.getWidth() - procedureTitleWidth) / 2;
    doc.setFontSize(11);
    doc.text(procedureTitle, w, positionY);
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    // Add procedure Score and Allocated Points
    doc.setFontSize(9); // Ensure font size matches previous entries
    doc.text(`Score: ${this.data.procedure.Score}`, 10, positionY);  // Add Procedure Score
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    doc.text(`Allocated Points: ${this.data.procedure.AllocatedPoints}`, 10, positionY); // Add Allocated Points
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    // Procedure Actions Table
    autoTable(doc, {
      head: [['Action', 'Points', 'Max Points']],
      body: this.data.procedure.Actions.map((action: any) => [
        action.Action,
        action.Points.toString(),
        action.MaxPoints.toString(),
      ]),
      startY: positionY,
      styles: {
        fontSize: 8, // Smaller font size for table content
        textColor: [255, 255, 255], // Set table text color to white
        fillColor: undefined, // Set to null to match the background
        halign: 'center', // Center align the cell text
      },
      headStyles: {
        fillColor: [60, 25, 138], // Header background color
        textColor: [255, 255, 255], // Set header text color to white
        fontSize: 8, // Smaller font size for headers
        halign: 'center', // Center align the header text
      },
      theme: 'grid',
      didDrawPage: (data: any) => {
        // Add the background image BEFORE content on every new page
        if (data.pageNumber > 1) {
          addBackgroundImage();
        }
      },
    });

    positionY = doc.autoTable.previous.finalY + 10; // Update positionY after table
    checkPageOverflow(); // Check for overflow

    // Evidence Gathering Section
    const evidenceGatheringTitle = 'Evidence Gathering';
    const evidenceGatheringTitleWidth = doc.getTextWidth(evidenceGatheringTitle);
    const z = (doc.internal.pageSize.getWidth() - evidenceGatheringTitleWidth) / 2;
    doc.setFontSize(11);
    doc.text(evidenceGatheringTitle, z, positionY);
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    doc.text('Score: ' + this.data.evidenceGathering.Score, 10, positionY);
    positionY += 10;
    checkPageOverflow(); // Check for overflow
    doc.text('Allocated Points: ' + this.data.evidenceGathering.AllocatedPoints, 10, positionY);
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    // Photos Section
    const photosTitle = 'Photos';
    const photosTitleWidth = doc.getTextWidth(photosTitle);
    const p = (doc.internal.pageSize.getWidth() - photosTitleWidth) / 2;
    doc.setFontSize(9);
    doc.text(photosTitle, p, positionY);
    positionY += 10;
    checkPageOverflow(); // Check for overflow

    if (this.data.photos.length > 0) {
      let hasValidPhotos = false;

      this.data.photos.forEach((photo: any) => {
        const photoDescription = photo.Description || 'No description';
        const photoURL = photo.URL.trim(); // Trim to avoid accidental spaces

        if (photoURL) { // Only add photos with valid URLs
          hasValidPhotos = true; // Indicate there is at least one valid photo
          doc.text(photoDescription, 10, positionY);
          positionY += 10;
          checkPageOverflow(); // Check for overflow
          doc.addImage(photoURL, 'PNG', 10, positionY, 60, 60); // Adjust width/height as needed
          positionY += 70; // Adjust Y position for the next photo
          checkPageOverflow(); // Check for overflow
        }
      });

      if (!hasValidPhotos) {
        doc.text('No valid photos available.', 10, positionY);
        positionY += 10;
        checkPageOverflow(); // Check for overflow
      }
    } else {
      doc.text('No photos available.', 10, positionY);
      positionY += 10;
      checkPageOverflow(); // Check for overflow
    }


    // Quiz Attempts Section
    const quizAttemptsTitle = 'Quiz Attempts Details';
    const quizAttemptsTitleWidth = doc.getTextWidth(quizAttemptsTitle);
    const q = (doc.internal.pageSize.getWidth() - quizAttemptsTitleWidth) / 2;
    doc.setFontSize(11);
    doc.text(quizAttemptsTitle, q, positionY);
    positionY += 10; // Increase distance before adding quiz attempts
    checkPageOverflow(); // Check for overflow

    // Quiz Attempts Table
    autoTable(doc, {
      head: [
        ['ID', 'Quiz', 'User ID', 'Attempt', 'Unique ID', 'Layout', 'Current Page', 'Preview', 'State', 'Time Start', 'Time Finish', 'Time Modified', 'Time Modified Offline', 'Time Check State', 'Sum Grades', 'Graded Notification Sent Time']
      ],
      body: this.data.quizAttempts.map((attempt: any) => [
        attempt.id,
        attempt.quiz,
        attempt.userid,
        attempt.attempt,
        attempt.uniqueid,
        attempt.layout,
        attempt.currentpage,
        attempt.preview,
        attempt.state,
        this.formatTimestamp(attempt.timestart),
        this.formatTimestamp(attempt.timefinish),
        this.formatTimestamp(attempt.timemodified),
        this.formatTimestamp(attempt.timemodifiedoffline),
        attempt.timecheckstate || 'N/A',
        attempt.sumgrades,
        this.formatTimestamp(attempt.gradednotificationsenttime),
      ]),
      startY: positionY,
      styles: {
        fontSize: 6, // Smaller font size for table content
        textColor: [255, 255, 255], // Set table text color to white
        fillColor: undefined, // Set to null to match the background
        halign: 'center', // Center align the cell text
      },
      headStyles: {
        fillColor: [60, 25, 138], // Header background color
        textColor: [255, 255, 255], // Set header text color to white
        fontSize: 6, // Smaller font size for headers
        halign: 'center', // Center align the header text
      },
      theme: 'grid',
      didDrawPage: (data: any) => {
        // Add the background image BEFORE content on every new page
        if (data.pageNumber > 1) {
          addBackgroundImage();
        }
      },
    });

    // Save PDF
    doc.save('Final_Report.pdf');
  }
}