import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omitField'
})
export class OmitFieldPipe implements PipeTransform {

  transform(value: any, field: string): any {
    if (!value || typeof value !== 'object') {
      return value;
    }
    
    // Clone the object to avoid mutating the original data
    const result = { ...value };
    delete result[field];
    return result;
  }
}