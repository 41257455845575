import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { InteractionService } from 'src/app/services/interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-game-statistics',
  templateUrl: './game-statistics.component.html',
  styleUrls: ['./game-statistics.component.css']
})
export class GameStatisticsComponent implements OnInit {

  constructor(
    private router: Router,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private userService: UserService
  ) {}
  
  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    let structuredDataTwoPath = this.interactionService.getstructuredDataTwoPath();
    switch(structuredDataTwoPath){
      case "carAccident/structuredData/gameStatistics": {
        this.router.navigate(['/structuredData/gameStatistics']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.GAME_STATISTICS', location: '/gameScenarios' }
        ]);
        break;
      }
      case "interrogation/structuredData/gameStatistics":{
        this.router.navigate(['/structuredData/gameStatistics']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.GAME_STATISTICS', location: '/gameScenarios' }
        ]);
        break;
      }
      case "csi/structuredData/gameStatistics":{
        this.router.navigate(['/structuredData/gameStatistics']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CSI', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.GAME_STATISTICS', location: '/gameScenarios' }
        ]);
        break;
      }
      case "terroristAttack/structuredData/gameStatistics":{
        this.router.navigate(['/structuredData/gameStatistics']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.GAME_STATISTICS', location: '/gameScenarios' }
        ]);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
        ]);
        break;
      }
    }
    this.isLoading = false;
  }

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
}
