import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
@Component({
  selector: 'app-sidenav-structured-data',
  templateUrl: './sidenav-structured-data.component.html',
  styleUrls: ['./sidenav-structured-data.component.css']
})
export class SidenavStructuredDataComponent implements OnInit {
  public isBtnActive: string = '';
  constructor(
    private router: Router,
    private interactionService: InteractionService
  ) {}


  ngOnInit(): void {
  }

  toggleBtnScenario(name: string) {
    this.isBtnActive = name
    this.interactionService.setIsBtnActive(true);
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let structuredDataOnePath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setScenariosPath(structuredDataOnePath);
    switch(structuredDataOnePath){
      case "carAccident/structuredData/scenario": {
        this.router.navigate(['/structuredData/scenarios'])
        break;
      }
      case "interrogation/structuredData/scenario":{
        this.router.navigate(['/structuredData/scenarios'])
        break;
      }
      case "csi/structuredData/scenario":{
        this.router.navigate(['/structuredData/scenarios'])
        break;
      }
      case "terroristAttack/structuredData/scenario":{
        this.router.navigate(['/structuredData/scenarios'])
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    }
;
  }
  toggleBtnGameStatistics(name: string){
    this.isBtnActive = name
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let structuredDataTwoPath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setGameStatisticsPath(structuredDataTwoPath);
    this.router.navigate(['/structuredData/gameStatistics']);
  }

}
