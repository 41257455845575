import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-report-vizualization-location-prediction',
  templateUrl: './report-vizualization-location-prediction.component.html',
  styleUrls: ['./report-vizualization-location-prediction.component.css']
})
export class ReportVizualizationLocationPredictionComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    public dialogRef: MatDialogRef<ReportVizualizationLocationPredictionComponent>, 
    private interactionService: InteractionService
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
        this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  closeInvoice() {
    this.dialogRef.close();
  }

  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;

    // Step 1: Add the background image using the base64 string
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); // Full page background

    // Title text
    const title = 'Location Prediction Report';

    // Calculate the width of the title
    const titleWidth = doc.getTextWidth(title);

    // Calculate x position for center alignment
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

    // Add logo (base64 image)
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed

    // Add report title with decreased font size and bold
    doc.setFontSize(16); // Use a suitable font size for the title
    doc.setFont('bold'); // Set font to bold
    doc.setTextColor(255, 255, 255); // Set title color to white for better contrast
    doc.text(title, x, 40); // Adjust y position to accommodate logo

    // Reset to normal font and set text color
    doc.setFont('normal'); // Reset to normal font
    doc.setFontSize(10); // Adjust font size for user info
    doc.setTextColor(255, 255, 255); // Set text color to white for user info

    // Add data from the component to the PDF
    const dataLines = [
        { label: 'Session ID:', value: this.data.sessionId },
        { label: 'Username:', value: this.data.username },
        { label: 'Timestamp:', value: this.data.timestamp },
        { label: 'Avatar ID:', value: this.data.avatarId },
        { label: 'Avatar Position X:', value: this.data.avatarPositionX },
        { label: 'Avatar Position Y:', value: this.data.avatarPositionY },
        { label: 'Avatar Position Z:', value: this.data.avatarPositionZ }
    ];

    // Starting y position for the text
    let yPosition = 60; // Adjust starting position below title

    // Loop through the data lines and add them to the PDF
    dataLines.forEach(item => {
        doc.text(`${item.label} ${item.value}`, 10, yPosition);
        yPosition += 10; // Increase y position for the next line
    });

    // Save the generated PDF
    doc.save('Location_Prediction_Report.pdf');
}

}
