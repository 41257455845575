import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, mergeMap, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

const OAUTH_CLIENT = 'lawgame@#$-client';
const OAUTH_SECRET = 'lawgame)(*&-secret';
const lawgameAuthenticationAPIURL = environment.lawgameAuthentication;
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + OAUTH_SECRET),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '';

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isRefreshingToken: boolean = false;

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error!.status}, ` + `body was: ${error!.error}`
      );
    }
    return throwError(error);
  }

  private static log(message: string): any {
    console.log(message);
  }

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('grant_type', 'password');

    return this.http
      .post<any>(
        lawgameAuthenticationAPIURL + 'oauth/token',
        body,
        HTTP_OPTIONS
      )
      .pipe(
        tap((res) => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        }),
        catchError(AuthService.handleError)
      );
  }

  refreshToken(refreshData: any): Observable<any> {
     this.tokenService.removeToken();
     this.tokenService.removeRefreshToken();
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);
      const body = new HttpParams()
        .set('refresh_token', refreshData.refresh_token)
        .set('grant_type', 'refresh_token');
      return this.http
        .post<any>(
          lawgameAuthenticationAPIURL + 'oauth/token',
          body,
          HTTP_OPTIONS
        )
        .pipe(
          tap(res => {
            this.tokenService.saveToken(res.access_token);
            this.tokenService.saveRefreshToken(res.refresh_token);
                 
          }),
            catchError(AuthService.handleError)
          );
      }else {
        // If token refresh is already in progress, wait for the new token to be available
        return this.refreshTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          mergeMap(() => {
            return this.refreshToken(refreshData);
          })
        );
  }
}

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    window.localStorage.clear();
    
  }
}
