import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-add-emotional-assessment',
  templateUrl: './add-emotional-assessment.component.html',
  styleUrls: ['./add-emotional-assessment.component.css']
})
export class AddEmotionalAssessmentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, 
    private router:Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private sdmDataService: SdmDataService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService ) {}

    public username:string ='';
    gameType:string='';
    isLoading: boolean = false;
    spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  ngOnInit(): void {
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  formGroup = this._formBuilder.group({
    userId: ['', Validators.required],
    timestamp: [
      '', 
      [Validators.required,
      Validators.pattern(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/gm)],
    ],
    interviewId: ['', Validators.required],
    organizerInstitution: ['', Validators.required],
    participantFullName: ['', Validators.required],
    angerGrade: ['', Validators.required],
    calmGrade: ['', Validators.required],
    trustGrade: ['', Validators.required],
    disgustGrade: ['', Validators.required],
    fearGrade: ['', Validators.required],
    happinessGrade: ['', Validators.required],
    sadnessGrade: ['', Validators.required],
    surpriseGrade: ['', Validators.required],
    interestGrade: ['', Validators.required],
    accuracy: ['', Validators.required]
  });

  formErrors: { field: string; required: string; warningOne: string; warningTwo: string}[] = [
    {
      field: 'timestamp',
      required: 'GAME_EVENTS.TIMESTAMP.REQUIRED',
      warningOne: 'GAME_EVENTS.TIMESTAMP.INVALID_FORMAT',
      warningTwo: ""
    },
    {
      field: 'User Id',
      required: 'INTERROGATION.USER_ID.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Interview Id',
      required: 'INTERROGATION.INTERVIEW_ID.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Organizer Instituition',
      required: 'INTERROGATION.ORGANIZER_INSTITUTION.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Participant Full Name',
      required: 'INTERROGATION.PARTICIPANT_FULL_NAME.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Anger Grade',
      required: 'INTERROGATION.ANGER_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Calm Grade',
      required: 'INTERROGATION.CALM_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Trust Grade',
      required: 'INTERROGATION.TRUST_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Disgust Grade',
      required: 'INTERROGATION.DISGUST_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Fear Grade',
      required: 'INTERROGATION.FEAR_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Happiness Grade',
      required: 'INTERROGATION.HAPPINESS_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Sadness Grade',
      required: 'INTERROGATION.SADNESS_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Surprise Grade',
      required: 'INTERROGATION.SURPRISE_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Interest Grade',
      required: 'INTERROGATION.INTEREST_GRADE.REQUIRED',
      warningOne: '',
      warningTwo: ''
    },
    {
      field: 'Accuracy',
      required: 'INTERROGATION.ACCURACY.REQUIRED',
      warningOne: '',
      warningTwo: ''
    }
  ];

  isFormValid() {
    return this.formGroup.valid;
  }


  addEmotionalAssessment(){
    this.isLoading = true;
    this.username = this.interactionService.getUserProfile().userName;
    this.sdmDataService.addEmotionalAssessment(
          this.formGroup.get('userId')!.value!,
          this.formGroup.get('timestamp')!.value!,
          this.formGroup.get('interviewId')!.value!,
          this.formGroup.get('organizerInstitution')!.value!,
          this.formGroup.get('participantFullName')!.value!,
          this.formGroup.get('angerGrade')!.value!,
          this.formGroup.get('calmGrade')!.value!,
          this.formGroup.get('trustGrade')!.value!,
          this.formGroup.get('disgustGrade')!.value!,
          this.formGroup.get('fearGrade')!.value!,
          this.formGroup.get('happinessGrade')!.value!,
          this.formGroup.get('sadnessGrade')!.value!,
          this.formGroup.get('surpriseGrade')!.value!,
          this.formGroup.get('interestGrade')!.value!,
          this.formGroup.get('accuracy')!.value!,
          
    )
    .subscribe(
              (res: { statusDescription: string; statusCode: string; }) => {
                if (res.statusCode === "139" || res.statusDescription === "emotion_recognition_recording_successfully_saved") {
                  this.openSnackBar(
                    this.translate.instant('INTERROGATION.EMOTIONAL_ASSESSMENT.CREATE_SUCCESS'),
                    'notif-success',
                    4000
                  );
                  this.isLoading=false;

                  // location.reload();
                  this.dialogRef.close();
                }else {
                  this.openSnackBar(
                    this.translate.instant('INTERROGATION.EMOTIONAL_ASSESSMENT.CREATE_ERROR'),
                    'notif-error',
                    4000
                  );
                  this.dialogRef.close();
                }
              },
              (err: any) => {
 
              }
            );
  }

  
  onClick(){
    this.dialogRef.close();
  
  }

}


