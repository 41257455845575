<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="wrapper">
    <div class="invoice">
      <button class="close-button" (click)="closeInvoice()">×</button>
          <!-- Download Button -->
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
      <h2>Report for {{ data.playerId }}</h2>
      <p><strong>Player ID:</strong> {{ data.playerId }}</p>
      <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
      
      <h3>Car Deformations Attributes</h3>
      <p><strong>Deformation Lengths:</strong></p>
      <table class="deformation-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Length</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let length of data.carDeformationsAttributes.deformation_lengths_response; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ length }}</td>
          </tr>
        </tbody>
      </table>
      <p><strong>Deformed Car Image:</strong></p>
      <ng-container *ngIf="isBinaryStream(data.carDeformationsAttributes.deformed_car_image_name_response); else localImage">
        <img [src]="data.carDeformationsAttributes.deformed_car_image_name_response" alt="Deformed Car" width="200">
      </ng-container>
      <ng-template #localImage>
        <p>Image is related to local storage.</p>
      </ng-template>
    </div>
  </div>
  
  