<div class="form">
    <div *ngIf="isLoading === true" class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
      <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
        <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
      </div>
    </div>
    <!-- <h1>IN PROGRESS</h1> -->
    <h2 mat-dialog-title>{{ "TOOLTIP.CREATE_FOLDER" | translate}}</h2>
    <form [formGroup]="formGroup" class=" text-[#ffc601] " (keyup.enter)="addDirectory()">
  
      <mat-form-field class="w-full text-[#ffc601]">
        <mat-label>{{ "ROOT.TABLE.NAME" | translate}}</mat-label>
        <input type="text" matInput minlength="2" formControlName="directoryName" />
      </mat-form-field>
    </form>
  </div>
  <div class="text-[10px]">
    * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
  </div>
  <div *ngFor="let error of formErrors">
    <div class="text-[#ffc601]" *ngIf="
        (formGroup.get(error.field)?.invalid &&
          formGroup.get(error.field)?.touched) ||
        (formGroup.get(error.field)?.invalid &&
          formGroup.get(error.field)?.dirty)
      ">
      <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
          formGroup.get(error.field)?.invalid &&
          formGroup.get(error.field)?.errors?.['required']
        ">
        * {{ error.required | translate }}
      </div>
      <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
          formGroup.get(error.field)?.invalid &&
          (formGroup.get(error.field)?.errors?.['pattern'] ||
            formGroup.get(error.field)?.errors?.['minlength'])
        ">
        * {{ error.warningOne | translate }}
      </div>
      <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
      formGroup.get(error.field)?.invalid &&
      (formGroup.get(error.field)?.errors?.['pattern'] ||
        formGroup.get(error.field)?.errors?.['minlength'])
    ">
    * {{ error.warningTwo | translate }}
  </div>
    </div>
  </div>
  <mat-dialog-actions class="cursor-pointer">
    <button mat-raised-button (click)="onClick()"
      class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">Cancel</button>
    <button mat-raised-button (click)="addDirectory()"[disabled]="!isFormValid()"
      class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">OK
    </button>
  </mat-dialog-actions>