<p class="3xl:text-lg 4k:text-3xl"> Are you sure you want to delete {{elementNameToDelete + "?"}}</p>

<div>
  <mat-toolbar class="bg-white">
    <mat-toolbar-row>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onDelete()">
        <!-- {{'DELETE_FILE.YES'| translate}} -->
        YES
      </div>
      <div class="w-[20%] inline-block "></div>
      <div
        class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
        (click)="onClick()">
        <!-- {{'DELETE_FILE.NO' | translate}} -->
        NO
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>