import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-sidenav-unstructured-data',
  templateUrl: './sidenav-unstructured-data.component.html',
  styleUrls: ['./sidenav-unstructured-data.component.css']
})
export class SidenavUnstructuredDataComponent implements OnInit {
  public isBtnActive: string = '';
  constructor(
    private router: Router,
    private interactionService: InteractionService
  ) {}

  ngOnInit(): void {
  }

  toggleBtnRecording(name:string){
    this.isBtnActive = name;
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let recordingsPath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setRecordingsPath(recordingsPath);
    switch(recordingsPath){
      case "carAccident/unstructuredData/recordings": {
        this.router.navigate(['/unstructuredData/recordings']);
        break;
      }
      case "interrogation/unstructuredData/recordings":{
        this.router.navigate(['/unstructuredData/recordings']);
        break;
      }
      case "csi/unstructuredData/recordings":{
        this.router.navigate(['/unstructuredData/recordings']);
        break;
      }
      case "terroristAttack/unstructuredData/recordings":{
        this.router.navigate(['/unstructuredData/recordings']);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    }
    
  }

  toggleBtnTextures(name:string){
    this.isBtnActive = name
    // this.interactionService.setIsBtnActive(true);
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let texturePath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setTexturePath(texturePath);
    switch(texturePath){
      case "carAccident/unstructuredData/textures": {
        this.router.navigate(['/unstructuredData/textures']);
        break;
      }
      case "interrogation/unstructuredData/textures":{
        this.router.navigate(['/unstructuredData/textures']);
        break;
      }
      case "csi/unstructuredData/textures":{
        this.router.navigate(['/unstructuredData/textures']);
        break;
      }
      case "terroristAttack/unstructuredData/textures":{
        this.router.navigate(['/unstructuredData/textures']);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    }
    

  }
  toggleBtnConfigurations(name:string){
    this.isBtnActive = name
    // this.interactionService.setIsBtnActive(true);
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let configPath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setConfigPath(configPath);
    switch(configPath){
      case "carAccident/unstructuredData/configurations": {
        this.router.navigate(['/unstructuredData/configurations']);
        break;
      }
      case "interrogation/unstructuredData/configurations":{
        this.router.navigate(['/unstructuredData/configurations']);
        break;
      }
      case "csi/unstructuredData/configurations":{
        this.router.navigate(['/unstructuredData/configurations']);
        break;
      }
      case "terroristAttack/unstructuredData/configurations":{
        this.router.navigate(['/unstructuredData/configurations']);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    } 
  }

  toggleBtnModels(name:string){
    this.isBtnActive = name
    // this.interactionService.setIsBtnActive(true);
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let modelsPath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setModelsPath(modelsPath);
    switch(modelsPath){
      case "carAccident/unstructuredData/models": {
        this.router.navigate(['/unstructuredData/models']);
        break;
      }
      case "interrogation/unstructuredData/models":{
        this.router.navigate(['/unstructuredData/models']);
        break;
      }
      case "csi/unstructuredData/models":{
        this.router.navigate(['/unstructuredData/models']);
        break;
      }
      case "terroristAttack/unstructuredData/models":{
        this.router.navigate(['/unstructuredData/models']);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    } 
  }
 
  toggleBtnSnapshots(name:string){
    this.isBtnActive = name
    // this.interactionService.setIsBtnActive(true);
    let getGameAndDataTypePath = this.interactionService.getGameAndDataTypePath();
    let snapshotsPath =  getGameAndDataTypePath + '/' + this.isBtnActive;
    this.interactionService.setSnapshotsPath(snapshotsPath);
    switch(snapshotsPath){
      case "carAccident/unstructuredData/snapshots": {
        this.router.navigate(['/unstructuredData/snapshots']);
        break;
      }
      case "interrogation/unstructuredData/snapshots":{
        this.router.navigate(['/unstructuredData/snapshots']);
        break;
      }
      case "csi/unstructuredData/snapshots":{
        this.router.navigate(['/unstructuredData/snapshots']);
        break;
      }
      case "terroristAttack/unstructuredData/snapshots":{
        this.router.navigate(['/unstructuredData/snapshots']);
        break;
      }
      default:{
        this.router.navigate(['/home']);
        break;
      }
    }
    
  }
}
