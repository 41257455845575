<div class="sidebar">
    <div class="buttonsWrapper">
        <div>
            <button mat-raised-button  [routerLink]="['/reportInterrogationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.INTERROGATION_SUMMARY' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportNegotiationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.NEGOTIATION_SUMMARY' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportLmsQuizResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.LMS_QUIZ_RESULTS' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>