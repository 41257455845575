<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="scenarios">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'scenario'}" (click)="toggleBtnScenario('scenario')"><mat-icon class="self-center mr-4">assignment</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.SCENARIOS' | translate}}</span>
            </button>
        </div>
        <div class="gameStatistics">
            <button mat-raised-button [ngClass]="{'active': isBtnActive === 'gameStatistics'}" (click)="toggleBtnGameStatistics('gameStatistics')"><mat-icon class="self-center ml-3">games</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.GAME_STATISTICS' | translate}}</span></button>
        </div>
        
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>