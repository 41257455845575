<!-- Overlay loading spinner -->
<div *ngIf="isLoading" class="fixed inset-0 flex items-center justify-center bg-white opacity-75 z-[100]">
  <div class="flex flex-col items-center">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    <p class="text-center mt-4 text-lg font-semibold text-gray-700">
      Please wait. This will take a few moments
    </p>
  </div>
</div>

<div class="setBackgrund">
  <div class="h-screen flex flex-col">
    <app-header class="fixed w-full z-50"></app-header>
    <div class="wrapper">
      <div id="title">
        <h3>{{"STRUCTURED_DATA.SCENARIO_GENERATION.TITLE" | translate}}</h3>
        <h5 *ngIf="userProfile?.permission && userProfile?.permission !== 'WRITE'">* {{"READ_PERMISSION" | translate}}</h5>
      </div>
       <!--FORM -->
       <div>
        <div>
          <form [formGroup]="formGroup" class="text-[#ffc601]" (keyup.enter)="onSubmit()">
            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"STRUCTURED_DATA.SCENARIO_GENERATION.SCENARIO_ID" | translate}}</mat-label>
              <input
                type="text"
                matInput
                minlength="2"
                maxlength="30"
                formControlName="scenarioId"
              />
            </mat-form-field>

            <mat-form-field class="w-full text-[#ffc601]">
              <mat-label>{{"STRUCTURED_DATA.SCENARIO_GENERATION.PLAYER_ID" | translate}}</mat-label>
              <input
                type="text"
                matInput
                minlength="2"
                maxlength="30"
                formControlName="playerId"
              />
            </mat-form-field>
  
            <mat-form-field class="w-full 3xl:text-3xl 4k:text-5xl">
              <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
                {{ "STRUCTURED_DATA.SCENARIO_GENERATION.SCENARIO_TYPE" | translate }}
              </mat-label>
              <mat-select  formControlName="scenarioType" class="text-white" >
                  <mat-option class="text-white" value="city">
                    {{ "STRUCTURED_DATA.SCENARIO_GENERATION.CITY_ATTACK" | translate}}
                  </mat-option>
              </mat-select>
            </mat-form-field>

          </form>

          <div class="text-[10px]">
            * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
          </div>
          <div *ngFor="let error of formErrors">
            <div
              class="text-[#ffc601]"
              *ngIf="
                (formGroup.get(error.field)!.invalid &&
                  formGroup.get(error.field)!.touched) ||
                (formGroup.get(error.field)!.invalid &&
                  formGroup.get(error.field)!.dirty)
              "
            >
              <div
                class="text-[10px] 3xl:text-lg 4k:text-3xl"
                *ngIf="
                  formGroup.get(error.field)!.invalid &&
                  formGroup.get(error.field)!.errors?.['required']
                "
              >
                * {{ error.required | translate }}
              </div>
              <div
                class="text-[10px] 3xl:text-lg 4k:text-3xl"
                *ngIf="
                  formGroup.get(error.field)!.invalid &&
                  (formGroup.get(error.field)!.errors?.['pattern'] ||
                    formGroup.get(error.field)!.errors?.['minlength'])
                "
              >
                * {{ error.others | translate }}
              </div>
            </div>
          </div>
          <br>
          <div class="warning-section">
            <p>{{ "STRUCTURED_DATA.SCENARIO_GENERATION.NOTE" | translate}}</p>
            <p>{{ "STRUCTURED_DATA.SCENARIO_GENERATION.WARNING_TIME" | translate}}</p>
            <p>{{ "STRUCTURED_DATA.SCENARIO_GENERATION.WARNING_RESULTS_TERR_ATTACK" | translate}}</p>
          </div>
          <div class="self-center">
            <button
            mat-raised-button *ngIf = "userProfile.permission === 'WRITE'"[disabled]="userProfile.permission !== 'WRITE'"
            class="w-auto self-center left-[40%] top-[4%] text-black h-10 bg-[#ffc601] 3xl:text-3xl 3xl:h-10 4xl:h-20 4k:text-5xl"
            (click)="onSubmit()"
            [disabled]="!isFormValid()"
          >
          {{ "STRUCTURED_DATA.SCENARIO_GENERATION.GENERATE_SCENARIO" | translate}}
          </button>
          <span class="disabled-wrapper">
          <button
          mat-raised-button
          class="w-auto self-center left-[40%] top-[4%] text-black h-10 bg-[#ffc601] 3xl:text-3xl 3xl:h-10 4xl:h-20 4k:text-5xl"
          *ngIf = "userProfile.permission !== 'WRITE'" [disabled]="userProfile.permission !== 'WRITE'"
          (click)="onSubmit()"
          [disabled]="userProfile.permission !== 'WRITE'"
        >
        {{ "STRUCTURED_DATA.SCENARIO_GENERATION.GENERATE_SCENARIO" | translate}}
        </button>
      </span>
          </div>
         </div>
      </div>
    </div>
   <app-terr-attack-structured-sidenav></app-terr-attack-structured-sidenav>
    <app-footer class="fixed bottom-0 w-full"></app-footer>
  </div>
</div>