import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-home-pilot',
  templateUrl: './home-pilot.component.html',
  styleUrls: ['./home-pilot.component.css']
})
export class HomePilotComponent implements OnInit {
  public isBtnActive: string = '';
  public dataType: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  constructor(
    private router: Router,
    private interactionService: InteractionService,
    private userService: UserService,
  ) {}

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading=true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
    }, (err: any)=>{
      console.log("error" + err.error);
    })
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot'},
      ]);
      this.isLoading=false;
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  toggleGame(name: string) {
    this.isBtnActive = name
    this.interactionService.setSelectedGame(this.isBtnActive);
    let path = this.interactionService.getSelectedGame();
    switch(path){
      case "carAccident": {
        this.router.navigate(['reportsCarAccident']);
        break;
      }
      case "interrogation":{
        this.router.navigate(['reportsInterrogation']);
        break;
      }
      case "csi":{
        this.router.navigate(['reportsCsi']);
        break;
      }
      case "terroristAttack":{
        this.router.navigate(['reportsTerroristAttack']);
        break;
      }
    }
  }

}
