import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileDataService } from 'src/app/services/file-data/file-data.service';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  public elementNameToDelete: string ='';
  public isDirectory: any;
  isLoading: boolean = false;
    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, 
      private fileDataService: FileDataService,
      private router:Router,
      private translate: TranslateService,
      private interactionService: InteractionService ) {}
  
      languages = [
        { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
        { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
        { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
        { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
        { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
        { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
        { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
        { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
        { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
        { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
        { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
        { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
      ];
    language = this.interactionService.getLanguage();
  
    ngOnInit(): void {
      this.isLoading = true;
      this.elementNameToDelete=this.interactionService.getdeletedElementName()
      this.isDirectory = this.interactionService.getElementType();
      this.isLoading = false;
    }
  
    onClick(){
      this.dialogRef.close();
    
    }
    onDelete() {
      // Check if backend is available or the platform is used only for testing purposes
    this.fileDataService.deleteFromServer(this.elementNameToDelete, this.isDirectory)
          .subscribe(
            // If the response is good then deactivate loading spinner from UI and display the success notification
            (res: { statusDescription: string; statusCode: string; }) => {
              if (res.statusDescription === 'the_file_was_deleted_successfully' || res.statusCode == '029') {
                
                // location.reload();
                this.dialogRef.close();
              }
               if(res.statusDescription === 'the_directory_was_deleted_successfully' || res.statusCode == '062'){
                // location.reload();
                this.dialogRef.close();
              }
             
            },
            // Otherwise display the error and deactivate the loading spinner
            (err: any) => {
              // Print error in console for development and testing purposes, could be removed
              console.log('ERR:', err);
            }
          );
    }
  
    changeLanguage(code: string, language: string, flag: string) {
      this.language = { code, language, flag };
      localStorage.setItem('language', code);
      this.interactionService.setLanguage({ code, language, flag });
      this.translate.use(
        localStorage.getItem('language')
          ? localStorage.getItem('language')!.toString()
          : 'EN'
      );
    }
  
  }