<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="ainResponse">
            <button mat-raised-button [routerLink]="['/structuredData/ainResponse']" routerLinkActive="active">
                <mat-icon class="material-icons">memory</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.AIN_RESPONSE' | translate}}</span></button>
        </div>
        <div class="gameEvents">
            <button mat-raised-button [routerLink]="['/structuredData/gameEvents']" routerLinkActive="active">
                <mat-icon class="material-icons">games</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.GAME_EVENTS' | translate}}</span></button>
        </div>
        <div class="imageryAcquisition">
            <button mat-raised-button [routerLink]="['/structuredData/imageryAcquisition']" routerLinkActive="active">
                <mat-icon class='material-icons'>data_exploration</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.IMAGERY_ACQUISITION' | translate}}</span></button>
        </div>
        <div class="twoCarsCrah">
            <button mat-raised-button [routerLink]="['/structuredData/twoCarsCrash']" routerLinkActive="active">
                <mat-icon class="material-symbols-outlined">car_crash</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.TWO_CARS_CRASH' | translate}}</span></button>
        </div>
        <div class="npcResponse">
            <button mat-raised-button [routerLink]="['/structuredData/npcResponse']" routerLinkActive="active">
                <mat-icon class="material-icons">smart_toy</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.NPC_RESPONSE' | translate}}</span></button>
        </div>
        <div class="victimCrossing">
            <button mat-raised-button  [routerLink]="['/structuredData/victimCrossing']" routerLinkActive="active">
                <mat-icon class="material-icons">directions_run</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.VICTIM_CROSSING' | translate}}</span></button>
        </div>
        <div class="victimOnCurb">
            <button mat-raised-button  [routerLink]="['/structuredData/victimOnCurb']" routerLinkActive="active">
                <mat-icon class="material-icons">personal_injury</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.VICTIM_ON_CURB' | translate}}</span></button>
        </div>
        <div class="qualityReport">
            <button mat-raised-button  [routerLink]="['/structuredData/qualityReport']" routerLinkActive="active">
                <mat-icon class="material-icons">summarize</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.QUALITY_REPORT' | translate}}</span></button>
        </div>
        <div class="carDeformationsAnalysis">
            <button mat-raised-button  [routerLink]="['/structuredData/carDeformationsAnalysis']" routerLinkActive="active">
                <mat-icon class="material-icons">directions_car</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.CAR_DEFORMATION_ANALYSIS' | translate}}</span></button>
        </div>

        <div class="objectCentroids">
            <button mat-raised-button  [routerLink]="['/structuredData/objectCentroids']" routerLinkActive="active">
                <mat-icon class="material-icons">center_focus_strong</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.OBJECT_CENTROIDS' | translate}}</span></button>
        </div>
        <div class="scenarioGeneration">
            <button mat-raised-button  [routerLink]="['/structuredData/scenarioGenerationCarAccident']" routerLinkActive="active">
                <mat-icon class="material-icons">description</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
        </div>
        <div class="scenarioGeneration">
            <button mat-raised-button  [routerLink]="['/structuredData/vrScenarios']" routerLinkActive="active">
                <mat-icon class="material-icons">headset_mic</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.VR_SCENARIOS.TITLE' | translate}}</span></button>
        </div>
        <div class="reportGeneration">
            <button mat-raised-button  [routerLink]="['/reportGenerationCarDeformation']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_CAR_DEFORMATION' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/structuredData/lmsQuizRsults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>
    