import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-report-vizualization-bullet-trajectory',
  templateUrl: './report-vizualization-bullet-trajectory.component.html',
  styleUrls: ['./report-vizualization-bullet-trajectory.component.css']
})
export class ReportVizualizationBulletTrajectoryComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    private interactionService: InteractionService,
    public dialogRef: MatDialogRef<ReportVizualizationBulletTrajectoryComponent>, 
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }
  photoError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/default.jpg';
  }

  isBinaryStream(imageSrc: string): boolean {
    return imageSrc.startsWith('data:image'); // Checks if the image source is base64
  }
  

  closeInvoice() {
    this.dialogRef.close();
  }

  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;
    console.log("Generating PDF...");

    //Add the background image using the base64 string
    if (this.backgroundImageBase64) {
        doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); // Full page background
    } else {
        console.warn("No background image provided.");
    }

    // Title text
    const title = 'Bullet Trajectory Report';
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

    // Add logo (base64 image)
    if (this.logoBase64) {
        doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed
    } else {
        console.warn("No logo image provided.");
    }

    // Add report title
    doc.setFontSize(14);
    doc.setFont('bold');
    doc.setTextColor(255, 255, 255); // White title
    doc.text(title, x, 40);

    // Player information
    doc.setFont('normal');
    doc.setFontSize(9);
    doc.setTextColor(255, 255, 255); // Set text color to white
    doc.text('Player ID: ' + (this.data?.playerId ?? 'null'), 10, 60); // Display 'null' if playerId is not present
    doc.text('Timestamp: ' + (this.data?.timestamp ?? 'null'), 10, 70); // Display 'null' if timestamp is not present

    // Bullet Hole Attributes Section
    const bulletHoleAttributesY = 80; // Starting Y position for Bullet Hole Attributes
    doc.setFontSize(11);
    doc.setFont('bold');
    const bulletHoleAttributesTitle = 'Bullet Hole Attributes';
    const bulletHoleAttributesWidth = doc.getTextWidth(bulletHoleAttributesTitle);
    const bulletHoleAttributesCenter = (doc.internal.pageSize.getWidth() - bulletHoleAttributesWidth) / 2;
    doc.text(bulletHoleAttributesTitle, bulletHoleAttributesCenter, bulletHoleAttributesY);
    doc.setFont('normal');
    doc.setFontSize(9);
    
    // Display attributes, using null for missing values
    doc.text('Directionality: ' + (this.data?.bulletHoleAttributes?.directionality ?? 'null'), 10, bulletHoleAttributesY + 10);
    doc.text('Azimuthian Impact Angle: ' + (this.data?.bulletHoleAttributes?.azimuthian_impact_angle ?? 'null'), 10, bulletHoleAttributesY + 20);
    doc.text('Elevation Impact Angle: ' + (this.data?.bulletHoleAttributes?.elevation_impact_angle ?? 'null'), 10, bulletHoleAttributesY + 30);

    // Bullet Hole Object Position Table
    const bulletHolePositions: number[] = this.data?.bulletHoleAttributes?.bullet_hole_object_position_response || []; // Ensure safe access

    // Prepare table data
    const tableData = bulletHolePositions.length > 0 
        ? bulletHolePositions.map(position => [position.toString()]) // Convert positions to a 2D array for the table
        : [['null']]; // Display 'null' if no positions are available

    const tableColumns = [{ header: 'Bullet Hole Object Position' }]; // Define column header

    const tableYPosition = bulletHoleAttributesY + 40; // Position to start the table
    autoTable(doc, {
        head: tableColumns,
        body: tableData,
        startY: tableYPosition,
        styles: { fillColor: undefined, textColor: [255, 255, 255] }, // White background and text for the table
        theme: 'grid', // Use grid theme for the table
    });

    // Bullet Hole Object Name and Y Rotation
    const objectName = this.data?.bulletHoleAttributes?.bullet_hole_object_name_response ?? 'null'; // Handle missing object name
    const objectYRotation = this.data?.bulletHoleAttributes?.bullet_hole_object_y_rotation_response ?? 'null'; // Handle missing Y rotation

    doc.text('Bullet Hole Object Name: ' + objectName, 10, doc.autoTable.previous.finalY + 10);
    doc.text('Bullet Hole Object Y Rotation: ' + objectYRotation, 10, doc.autoTable.previous.finalY + 20);

    // Add Bullet Hole Image title
    const bulletHoleImageTitleY = doc.autoTable.previous.finalY + 30; // Y position for the image title
    const bulletHoleImgTitle = 'Bullet Hole Image';
    const bulletHoleImgWidth = doc.getTextWidth(bulletHoleImgTitle);
    const bulletHoleImgCenter = (doc.internal.pageSize.getWidth() - bulletHoleImgWidth) / 2;
    doc.text(bulletHoleImgTitle, bulletHoleImgCenter, bulletHoleImageTitleY);

    const bulletHoleImageBase64 = this.data?.bulletHoleAttributes?.bullet_hole_image_name_response; 
    const imageX = 10;
    const imageY = bulletHoleImageTitleY + 10; 

    if (bulletHoleImageBase64) {
        const img = new Image();
        img.src = bulletHoleImageBase64.startsWith('data:image/png;base64,')
            ? bulletHoleImageBase64
            : `data:image/png;base64,${bulletHoleImageBase64}`;
        img.onload = () => {
            const maxImageWidth = 70;
            const pageWidth = doc.internal.pageSize.getWidth(); 
            const imageWidth = img.width > maxImageWidth ? maxImageWidth : img.width;
            const imageHeight = (img.height / img.width) * imageWidth;
            const xPosition = (pageWidth - imageWidth) / 2;
            doc.addImage(img.src, 'PNG', xPosition, imageY, imageWidth, imageHeight);
            doc.save('bullet_trajectory_report.pdf');
        };

        img.onerror = () => {
            doc.text('Image is related to local storage.', imageX, imageY + 10);
            doc.save('bullet_trajectory_report.pdf');
        };
    } else {
        doc.text('Image is related to local storage.', imageX, imageY); 
        doc.save('bullet_trajectory_report.pdf'); 
    }
}




}