import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { EmptyExpr } from '@angular/compiler';

@Component({
  selector: 'app-add-scenario',
  templateUrl: './add-scenario.component.html',
  styleUrls: ['./add-scenario.component.css']
})
export class AddScenarioComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, 
    private router:Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private sdmDataService: SdmDataService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService ) {}

    public username:string ='';
    isLoading: boolean = false;
    spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

    ngOnInit(): void {
      this.isLoading = true;
      this.username = this.interactionService.getUserProfile().userName;
      this.isLoading = false;
    }
    languages = [
      { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
      { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
      { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
      { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
      { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
      { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
      { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
      { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
      { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
      { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
      { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
      { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
    ];

  language = this.interactionService.getLanguage();

  onClick(){
    this.dialogRef.close();
  
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }

  addScenario(){
    this.isLoading = true;
    this.username = this.interactionService.getUserProfile().userName;
    if (!this.interactionService.getBackendStatus()) {
      let testingChooseSuccess = true;
      setTimeout(() => {
        if (testingChooseSuccess === true) {
          this.openSnackBar(
            this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.SUCCESS'),
            'notif-success',
            4000
          );
          this.router.navigate(['home']);
        }
        else {
          this.openSnackBar(
            this.translate.instant('ACTIONS_BUTTONS.RENAME_ELEMENT.ERROR'),
            'notif-error',
            4000
          );
        }
        this.isLoading = false;
      }, 1000);
    }
    this.sdmDataService.addScenario(
          this.username,
          this.formGroup.get('name')!.value!,
          this.formGroup.get('description')!.value!,
          this.formGroup.get('type')!.value!,
          this.formGroup.get('category')!.value!,
    )
    .subscribe(
              (res: { statusDescription: string; statusCode: string; }) => {
                if (res.statusDescription === 'OK' || res.statusCode === '001') {
                  this.openSnackBar(
                    this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS'),
                    'notif-success',
                    4000
                  );
                  this.isLoading=false;
                  // location.reload();
                  this.dialogRef.close();
                }else {
                  this.openSnackBar(
                    this.translate.instant('SCENARIO_LIST.CREATE_SCENARIO.ERROR'),
                    'notif-error',
                    4000
                  );
                  this.dialogRef.close();
                }
              },
              (err: any) => {
                if(err.error.statusDescription === 'scenario_with_given_name_already_exists') {
                  this.openSnackBar(
                    this.translate.instant('SCENARIO_LIST.CREATE_SCENARIO.DUPLICATE_ERROR'),
                    'notif-warning',
                    4000
                  );
                  this.isLoading=false;
                }else{
                  this.isLoading = false;
                  this.dialogRef.close();
                  this.openSnackBar(
                    this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
                    'notif-warning',
                    4000
                  );
                }
                this.isLoading = false;
              }
            );
      }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  formGroup = this._formBuilder.group({
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^(?=.{1,255}$)[\w\-. ]+\w$/),
      ],
    ],
    description:[
      '',
      [
        Validators.required,
        Validators.minLength(2),
      ],
    ],
    type: ['', Validators.required],
    category: ['', Validators.required],
  });

  formErrors: { field: string; required: string; warningOne: string; warningTwo: string}[] = [
    {
      field: 'name',
      required: 'UPDATE_SCENARIO.NEW_NAME.REQUIRED',
      warningOne: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.MINLENGTH',
      warningTwo: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.INVALID_FORMAT'
    },
    {
      field: 'description',
      required: 'UPDATE_SCENARIO.DESCRIPTION.REQUIRED',
      warningOne: 'UPDATE_SCENARIO.DESCRIPTION.WARNINGS.MINLENGTH',
      warningTwo: ""
    },
    {
      field: 'type',
      required: 'UPDATE_SCENARIO.TYPE.REQUIRED',
      warningOne: "",
      warningTwo: ""
    },
    {
      field: 'role',
      required: 'UPDATE_SCENARIO.CATEGORY.REQUIRED',
      warningOne: "",
      warningTwo: ""
    },
    {
      field: 'category',
      required: 'UPDATE_SCENARIO.CATEGORY.REQUIRED',
      warningOne: "",
      warningTwo: ""
    },
  ];

  isFormValid() {
    return this.formGroup.valid;
  }
}
