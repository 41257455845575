<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="emotionalAssessment">
            <button mat-raised-button [routerLink]="['/structuredData/emotionalAssessment']" routerLinkActive="active">
                <mat-icon class="material-icons">insert_emoticon</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.EMOTIONAL_ASSESMNET' | translate}}</span></button>
        </div>
        <div class="gameEvents">
            <button mat-raised-button [routerLink]="['/structuredData/gameEvents']" routerLinkActive="active">
                <mat-icon class="material-icons">games</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.GAME_EVENTS' | translate}}</span></button>
        </div>
        <div class="npcResponse">
            <button mat-raised-button [routerLink]="['/structuredData/npcResponse']" routerLinkActive="active">
                <mat-icon class="material-icons">smart_toy</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.NPC_RESPONSE' | translate}}</span></button>
        </div>
        <div class="humanStance">
            <button mat-raised-button  [routerLink]="['/structuredData/humanStance']" routerLinkActive="active">
                <mat-icon class="material-icons">emoji_people</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.HUMAN_STANCE' | translate}}</span></button>
        </div>
        <div class="interrogationSummary">
            <button mat-raised-button  [routerLink]="['/structuredData/interrogationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">speaker_notes</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.INTERROGATION_SUMMARY' | translate}}</span></button>
        </div>
        <div class="negotiationSummary">
            <button mat-raised-button  [routerLink]="['/structuredData/negotiationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">forum</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.NEGOTIATION_SUMMARY' | translate}}</span></button>
        </div>
        <div class="assessmentResults">
            <button mat-raised-button  [routerLink]="['/structuredData/assessmentResults']" routerLinkActive="active">
                <mat-icon class="material-icons">insert_drive_file</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.ASSESSMENT_REUSLTS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/structuredData/lmsQuizRsults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/structuredData/vrScenarios']" routerLinkActive="active">
                <mat-icon class="material-icons">headset_mic</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.VR_SCENARIOS.TITLE' | translate}}</span></button>
        </div>
        <div class="reportGenerationInterrogationSummary">
            <button mat-raised-button  [routerLink]="['/structuredData/reportGenerationInterrogationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_INTERROGATION_SUMMARY' | translate}}</span></button>
        </div>
        <div class="reportGenerationNegotiationSummary">
            <button mat-raised-button  [routerLink]="['/structuredData/reportGenerationNegotiationSummary']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_NEGOTIATION_SUMMARY' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>