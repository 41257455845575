import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogoutComponent } from '../logout/logout.component';
import { InteractionService } from 'src/app/services/interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();
  username = '';
  sidebarVisible = true;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private interactionService: InteractionService,
    public translate: TranslateService,
    private userService: UserService
  ) {}

  openDialog() {
    this.dialog.open(LogoutComponent);
  }

  ngOnInit(): void {
    // if (!localStorage.getItem('admin_user')) {
    //   this.router.navigate(['']);
    // } else {
     this.userService.getLoggedInUserData().subscribe((res)=>{
        this.username = res.userName;

     },
     (err:any) =>{
      console.log("eroare")
     })
 
    // }
  }

  onClick() {
    this.router.navigate(['/home']);
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(code);
  }

  changeSidebarVisibility() {
    this.sidebarVisible === true
      ? (this.sidebarVisible = false)
      : (this.sidebarVisible = true);
  }

  goTo(prefix: string) {
    this.router.navigate([prefix]);
  }
}
