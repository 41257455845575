<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="ainResponse">
            <button mat-raised-button [routerLink]="['/structuredData/ainResponse']" routerLinkActive="active">
                <mat-icon class="material-icons">memory</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.AIN_RESPONSE' | translate}}</span></button>
        </div>
        <div class="apartmentScenario">
            <button mat-raised-button [routerLink]="['/structuredData/apartmentScenario']" routerLinkActive="active">
                <mat-icon class="material-icons">apartment</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.APARTMENT_SCENARIO' | translate}}</span></button>
        </div>
        <div class="gameEvents">
            <button mat-raised-button [routerLink]="['/structuredData/gameEvents']" routerLinkActive="active">
                <mat-icon class="material-icons">games</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.GAME_EVENTS' | translate}}</span></button>
        </div>
        <div class="imageryAcquisition">
            <button mat-raised-button [routerLink]="['/structuredData/imageryAcquisition']" routerLinkActive="active">
                <mat-icon class='material-icons'>data_exploration</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.IMAGERY_ACQUISITION' | translate}}</span></button>
        </div>
        <div class="npcResponse">
            <button mat-raised-button [routerLink]="['/structuredData/npcResponse']" routerLinkActive="active">
                <mat-icon class="material-icons">smart_toy</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.NPC_RESPONSE' | translate}}</span></button>
        </div>
        <div class="qualityReport">
            <button mat-raised-button [routerLink]="['/structuredData/qualityReport']" routerLinkActive="active">
                <mat-icon class="material-icons">summarize</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.QUALITY_REPORT' | translate}}</span></button>
        </div>
        <div class="bulletTrajectoryAnalysis">
            <button mat-raised-button [routerLink]="['/structuredData/bulletTrajectoryAnalysis']"
                routerLinkActive="active">
                <mat-icon class="material-icons">linear_scale</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.BULLET_TRAJECTORY_ANALYSIS' | translate}}</span></button>
        </div>
        <div class="bloodstainOriginPointAnalysis">
            <button mat-raised-button [routerLink]="['/structuredData/bloodstainOriginPointAnalysis']"
                routerLinkActive="active">
                <mat-icon class="material-icons">opacity</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.BLOODSTAIN_ORIGIN_POINT_ANALYSIS' | translate}}</span></button>
        </div>

        <div class="objectCentroids">
            <button mat-raised-button [routerLink]="['/structuredData/objectCentroids']" routerLinkActive="active">
                <mat-icon class="material-icons">center_focus_strong</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.OBJECT_CENTROIDS' | translate}}</span></button>
        </div>
        <div class="scenarioGeneration">
            <button mat-raised-button [routerLink]="['/structuredData/scenarioGenerationCsi']"
                routerLinkActive="active">
                <mat-icon class="material-icons">description</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/structuredData/vrScenarios']" routerLinkActive="active">
                <mat-icon class="material-icons">headset_mic</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.VR_SCENARIOS.TITLE' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/structuredData/lmsQuizRsults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/structuredData/reportCsiAndLmsResults']"
                routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_CSI_AND_LMS_RESULTS.TITLE' | translate}}</span></button>
        </div>
        <div class="reportGeneration">
            <button mat-raised-button [routerLink]="['/reportGenerationCsiFinalResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_CSI_FINAL_RESULTS' |
                    translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/reportGenerationBloodstainAnalysis']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_BLOODSTAIN_ANALYSIS' |
                    translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/reportGenerationBulletTrajectory']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_BULLET_TRAJECTORY' |
                    translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>