import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css']
})
export class ForgotPasswordDialogComponent implements OnInit {

  public currentEmail: string = '';
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  isLoading: boolean = false;
  formGroup = this._formBuilder.group({
    email: [
      '',
      [
        Validators.required,
      ],
    ],
  });
  formErrors: { field: string; required: string;}[] = [
    {
      field: 'email',
      required: 'LOGIN.FORGOT_PASSWORD.EMAIL_REQUIRED'
    },
  ];
  constructor(
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  onForgotPassword(){
    this.isLoading = true;
    let email = this.formGroup.get('email')!.value!;
    this.userService.resetPassword(email)
    .subscribe(
      (res: { statusDescription: string; statusCode: string; }) => {
        if (res.statusDescription === 'OK' || res.statusCode === '001') {
          this.dialogRef.close();
          this.openSnackBar(
            this.translate.instant('LOGIN.FORGOT_PASSWORD.EMAIL_SENT_WITH_SUCCESS'),
            'notif-success',
            4000
          );
          this.isLoading = false;
        }
      },
      // Otherwise display the error and deactivate the loading spinner
      (err: any) => {
        if(err.error.statusDescription === 'email_with_recover_code_could_not_be_sent'){
          this.openSnackBar(
            this.translate.instant('LOGIN.FORGOT_PASSWORD.SENT_ERROR'),
            'notif-warning',
            4000
          );
          this.isLoading = false;
        }else if(err.error.statusDescription === 'user_not_exist'){
          this.openSnackBar(
            this.translate.instant('LOGIN.FORGOT_PASSWORD.USER_NOT_EXISTS'),
            'notif-warning',
            4000
          );
          this.isLoading = false;
        }
      }
    );
    this.isLoading = false;
  }

  isFormValid() {
    return this.formGroup.valid;
  }
  onClick() {
    this.dialogRef.close();

  }
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
}
