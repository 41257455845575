import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InteractionService } from 'src/app/services/interaction.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';



@Component({
  selector: 'app-report-vizualization-car-deformation',
  templateUrl: './report-vizualization-car-deformation.component.html',
  styleUrls: ['./report-vizualization-car-deformation.component.css']
})
export class ReportVizualizationCarDeformationComponent implements OnInit {
  username: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data: any;
  backgroundImageBase64: string = '';
  logoBase64: string = '';

  constructor(
    private interactionService: InteractionService,
    public dialogRef: MatDialogRef<ReportVizualizationCarDeformationComponent>,
  ) { }

  ngOnInit(): void {
    this.data = this.interactionService.getJson();
    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }
  photoError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/default.jpg';
  }

  isBinaryStream(url: string): boolean {
    // Assuming a simple check to determine if the URL is a binary stream or an actual link
    // This can be customized based on the actual format of the binary stream or link
    return url.startsWith('data:') || url.startsWith('http');
  }


  closeInvoice() {
    this.dialogRef.close();
  }


  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); 
    // Title text
    const title = 'Report for ' + this.data.playerId;
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
    // Added logo (base64 image)
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); 
    // Add report title with decreased font size and bold
    doc.setFontSize(14); 
    doc.setFont('bold'); 
    doc.setTextColor(255, 255, 255); 
    doc.text(title, x, 40); 
    doc.setFont('normal'); 
    doc.setFontSize(9); 
    doc.setTextColor(255, 255, 255); 
    doc.text('Player ID: ' + this.data.playerId, 10, 50);
    doc.text('Timestamp: ' + this.data.timestamp, 10, 60);

    // Adding Car Deformations Attributes
    const carDeformationsTitle = 'Car Deformations Attributes';
    const carDeformationsWidth = doc.getTextWidth(carDeformationsTitle);
    const carDefCenter = (doc.internal.pageSize.getWidth() - carDeformationsWidth) / 2;
    doc.setFontSize(10); 
    doc.setTextColor(255, 255, 255); 
    doc.text(carDeformationsTitle, carDefCenter, 70); 
    doc.setFontSize(10); 
    doc.setTextColor(255, 255, 255); 
    doc.text('Deformation Lengths:', 10, 80);
    // Added Deformation Lengths Table
    const deformationLengths = this.data.carDeformationsAttributes.deformation_lengths_response;
    if (Array.isArray(deformationLengths) && deformationLengths.length > 0) {
      const deformationTableData = deformationLengths.map((length: any, index: number) => [
        index + 1,
        length,
      ]);
      // Defined table headers for Deformation Lengths
      const deformationTableHeaders = ['#', 'Length'];
      // Used autoTable to generate the table for Deformation Lengths
      (doc as any).autoTable({
        head: [deformationTableHeaders],
        body: deformationTableData,
        startY: 85,
        theme: 'grid', 
        styles: {
          fontSize: 6,
          textColor: [255, 255, 255],
          fillColor: null,
          halign: 'center',
        },
        headStyles: {
          fillColor: [60, 25, 138],
          textColor: [255, 255, 255], 
          fontSize: 6, 
          halign: 'center',
        },
        margin: { top: 10 },
      });
    } else {
      doc.text('No deformation lengths available.', 10, 90); // Indicate no data if empty
    }
    // Added "Deformed Car Image" text
    const carDeformationsPhotoTitle = 'Deformed Car Image';
    // Calculated the width of the title
    const carDeformationsPhotoWidth = doc.getTextWidth(carDeformationsPhotoTitle);
    // Calculated x position for center alignment
    const carDefPhotoCenter = (doc.internal.pageSize.getWidth() - carDeformationsPhotoWidth) / 2;
    doc.setFontSize(10); 
    doc.setTextColor(255, 255, 255); 
    doc.text(carDeformationsPhotoTitle, carDefPhotoCenter, doc.autoTable.previous.finalY + 10); // Position below the table
    // Checked if the deformed car image is available and displayed it accordingly
    const imageName = this.data.carDeformationsAttributes.deformed_car_image_name_response;
    if (this.isBinaryStream(imageName)) {
      const img = new Image();
      img.src = imageName; // Load the base64 image
      img.onload = () => {
        const imageWidth = 75; // Fixed width
        const pageWidth = doc.internal.pageSize.getWidth(); // Get the PDF page width
        // Dynamically calculated the height while maintaining aspect ratio
        const imageHeight = (img.height / img.width) * imageWidth;
        // Calculated the X position to center the image
        const xPosition = (pageWidth - imageWidth) / 2;
        // Added the deformed car image with calculated dimensions and center it
        doc.addImage(imageName, 'PNG', xPosition, doc.autoTable.previous.finalY + 15, imageWidth, imageHeight);
        doc.save('car_deformation_report.pdf');
      };
    } else {
      doc.text('Image is related to local storage.', 10, doc.autoTable.previous.finalY + 15);
    }
    doc.save('car_deformation_report.pdf');
  }

}