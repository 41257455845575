import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileDataService } from 'src/app/services/file-data/file-data.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-add-directory',
  templateUrl: './add-directory.component.html',
  styleUrls: ['./add-directory.component.css']
})
export class AddDirectoryComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private router: Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private fileDataService: FileDataService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService
   ) { 
    }
  public elementPath: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  currentPath!: string;
  hasPermissions: boolean = false;

  ngOnInit(): void {
    this.isLoading=true;
    this.elementPath = this.interactionService.getElementPath();
    this.isLoading=false;
  }

  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];

  language = this.interactionService.getLanguage();

  onClick() {
    this.dialogRef.close();

  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  formGroup = this._formBuilder.group({
    directoryName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^(?=.{1,255}$)[\w\-. ]+\w$/),
      ],
    ]
  });
  

  formErrors: { field: string; required: string; warningOne: string; warningTwo: string}[] = [
    {
      field: 'directoryName',
      required: 'UPDATE_SCENARIO.NEW_NAME.REQUIRED',
      warningOne: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.MINLENGTH',
      warningTwo: 'UPDATE_SCENARIO.NEW_NAME.WARNINGS.INVALID_FORMAT'
    }
  ];

  isFormValid() {
    return this.formGroup.valid;
  }

  addDirectory() {
    this.isLoading = true;
    let directoryName = this.formGroup.get('directoryName')!.value!
    let element = this.interactionService.getElementFromMenu();
    this.currentPath = this.pushToPath(this.elementPath, directoryName!);
    let gameSelected = this.interactionService.getSelectedGame();
    switch(gameSelected){
      case "carAccident":{
         this.createDirCarrAccidentByElementAndPath(element, this.currentPath);
         break;
      }
      case "interrogation":{
        this.createDirInterrogationElementAndPath(element, this.currentPath);
        break;
      }
      case "csi":{
        this.createDirCsiElementAndPath(element, this.currentPath);
        break;
      }
      case "terroristAttack":{
        this.createDirTerrAttackElementAndPath(element, this.currentPath);
        break;
      }

    }
  }

  createDirCarrAccidentByElementAndPath(elementName:string, path:string){
    this.interactionService.setElementFromMenu(elementName);
    let element = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    this.fileDataService.createDirForCarAccidentByElementAndPath(element, path)
        .subscribe(
          (res: {statusDescription: string; statusCode: string;}) => {
            if (res.statusDescription === 'successfully_created_directory' || res.statusCode === '026') {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS_NO_REFRESH'),
                'notif-success',
                4000
              );
              this.isLoading = false;
              this.dialogRef.close();
            }else {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.ERROR'),
                'notif-error',
                4000
              );
              this.isLoading = false;
            }
          },
          (err: any) => {
            if(err.error.statusDescription === 'duplicate_directory_name_Please_give_another_name'){
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.DUPLICATE_ERROR'),
                'notif-warning',
                4000
              );
              this.isLoading = false;
            }else{
              this.dialogRef.close();
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
            this.isLoading = false;
          }
        );
  }
  
  createDirInterrogationElementAndPath(elementName:string, path:string){
    this.interactionService.setElementFromMenu(elementName);
    let element = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    this.fileDataService.createDirForInterrogationByElementAndPath(element, path)
        .subscribe(
          (res: {statusDescription: string; statusCode: string;}) => {
            if (res.statusDescription === 'successfully_created_directory' || res.statusCode === '026') {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS_NO_REFRESH'),
                'notif-success',
                4000
              );
              this.isLoading = false;
              this.dialogRef.close();
            }else {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.ERROR'),
                'notif-error',
                4000
              );
              this.isLoading = false;
            }
          },
          (err: any) => {
            if(err.error.statusDescription === 'duplicate_directory_name_Please_give_another_name'){
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.DUPLICATE_ERROR'),
                'notif-warning',
                4000
              );
              this.isLoading = false;
            }else{
              this.dialogRef.close();
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
            this.isLoading = false;
          }
        );

  }

  createDirCsiElementAndPath(elementName:string, path:string){
    this.interactionService.setElementFromMenu(elementName);
    let element = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    this.fileDataService.createDirForCsiByElementAndPath(element, path)
        .subscribe(
          (res: {statusDescription: string; statusCode: string;}) => {
            if (res.statusDescription === 'successfully_created_directory' || res.statusCode === '026') {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS_NO_REFRESH'),
                'notif-success',
                4000
              );
              this.isLoading = false;
              this.dialogRef.close();
            
            }else {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.ERROR'),
                'notif-error',
                4000
              );
              this.isLoading = false;
            }
          },
          (err: any) => {
            if(err.error.statusDescription === 'duplicate_directory_name_Please_give_another_name'){
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.DUPLICATE_ERROR'),
                'notif-warning',
                4000
              );
              this.isLoading = false;
            }else{
              this.dialogRef.close();
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
            this.isLoading = false;
          }
        );

  }

  createDirTerrAttackElementAndPath(elementName:string, path:string){
    this.interactionService.setElementFromMenu(elementName);
    let element = this.interactionService.getElementFromMenu();
    path = this.currentPath;
    this.fileDataService.createDirForTerrAttackByElementAndPath(element, path)
        .subscribe(
          (res: {statusDescription: string; statusCode: string;}) => {
            if (res.statusDescription === 'successfully_created_directory' || res.statusCode === '026') {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.SUCCESS_NO_REFRESH'),
                'notif-success',
                4000
              );
              this.isLoading = false;
              this.dialogRef.close();
            }else {
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.ERROR'),
                'notif-error',
                4000
              );
              this.isLoading = false;
            }
          },
          (err: any) => {
            if(err.error.statusDescription === 'duplicate_directory_name_Please_give_another_name'){
              this.openSnackBar(
                this.translate.instant('ACTIONS_BUTTONS.CREATE_FOLDER.DUPLICATE_ERROR'),
                'notif-warning',
                4000
              );
              this.isLoading = false;
            }else{
              this.dialogRef.close();
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
            this.isLoading = false;
          }
        );

  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }
  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }
}
