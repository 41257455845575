<div
  [ngClass]="
    isLoading === true
      ? 'overflow-hidden h-screen flex flex-col'
      : 'h-screen flex flex-col'
  "
  (window:resize)="onResize($event)"
>
  <!-- Overlay loading spinner -->
  <div
    *ngIf="isLoading === true"
    class="fixed h-full w-full bg-white opacity-75 z-50"
  >
    <div
      class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4"
    >
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>

  <div class="uData">
    <app-header
    [ngClass]="isLoading === true ? 'fixed w-full' : 'fixed w-full z-50'"
  ></app-header>
  <app-sidenav-unstructured-data></app-sidenav-unstructured-data>
  <h3>{{'UNSTRUCTURED_DATA.TITLE' | translate}}</h3>
  <app-footer class="fixed bottom-0 w-full"></app-footer>
  </div>
</div>