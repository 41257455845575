<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
  <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>

<div class="wrapper">
  <div class="invoice">
    <button class="close-button" (click)="closeReport()">×</button>
    <!-- Download Button -->
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <div class="report-content">
      <h2>Quiz Report for {{ data.userData.first_name }} {{ data.userData.last_name }}</h2>

      <p><strong>User ID:</strong> {{ data.userData.id }}</p>
      <p><strong>Email:</strong> {{ data.userData.email }}</p>

      <h3>Course Information</h3>
      <p><strong>Course Name:</strong> {{ data.courseName }}</p>
      <p><strong>Quiz Name:</strong> {{ data.quizName }}</p>

      <h3>Quiz Details</h3>
      <p><strong>Time Open:</strong> {{ formatTimestamp(data.quizDetails.timeopen) }}</p>
      <p><strong>Time Close:</strong> {{ formatTimestamp(data.quizDetails.timeclose) }}</p>
      <p><strong>Time Limit:</strong> {{ data.quizDetails.timelimit }} minutes</p>
      <p><strong>Grade:</strong> {{ data.quizDetails.grade }}</p>

      <h3>Quiz Attempts Details</h3>
      <table class="attempts-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Quiz</th>
            <th>User ID</th>
            <th>Attempt</th>
            <th>Unique ID</th>
            <th>Layout</th>
            <th>Current Page</th>
            <th>Preview</th>
            <th>State</th>
            <th>Time Start</th>
            <th>Time Finish</th>
            <th>Time Modified</th>
            <th>Time Modified Offline</th>
            <th>Time Check State</th>
            <th>Sum Grades</th>
            <th>Graded Notification Sent Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attempt of data.quizAttempts">
            <td>{{ attempt.id }}</td>
            <td>{{ attempt.quiz }}</td>
            <td>{{ attempt.userid }}</td>
            <td>{{ attempt.attempt }}</td>
            <td>{{ attempt.uniqueid }}</td>
            <td>{{ attempt.layout }}</td>
            <td>{{ attempt.currentpage }}</td>
            <td>{{ attempt.preview }}</td>
            <td>{{ attempt.state }}</td>
            <td>{{ formatTimestamp(attempt.timestart) }}</td>
            <td>{{ formatTimestamp(attempt.timefinish) }}</td>
            <td>{{ formatTimestamp(attempt.timemodified) }}</td>
            <td>{{ formatTimestamp(attempt.timemodifiedoffline) }}</td>
            <td>{{ attempt.timecheckstate || 'N/A' }}</td>
            <td>{{ attempt.sumgrades }}</td>
            <td>{{ formatTimestamp(attempt.gradednotificationsenttime) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>