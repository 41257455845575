import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatList } from '@angular/material/list';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface SCENARIOS {
  identityManagementKey: string;
  name: string;
  description: string;
  type: string;
  category: string;
}

export interface NPC_RESPONSE {
  identityManagementKey: string;
  audio: string;
  playerId: string;
  sourceEntity: string;
  text: string;
  timestamp:string
}

export interface EMOTIONAL_ASSESSMENT {
  userId: string;
  interviewId: string;
  timestamp: string;
  organizerInstitution: string;
  participantFullName: string;
  angerGrade: string;
  calmGrade: string;
  trustGrade: string;
  disgustGrade: string;
  fearGrade: string;
  happinessGrade: string;
  sadnessGrade: string;
  surpriseGrade: string;
  interestGrade: string;
  accuracy: string
}

export interface APARTMENT_SCENARIO {
  user: string;
  timestamp: string;
  scenarioId:string;
  data:any;
}

export interface VICTIM_ON_CURB {
  user: string;
  timestamp: string;
  scenarioId:string;
  data: any;
}

export interface QUALITY_REPORT{
  user: string;
  timestamp: string;
  gsd: any;
  overlap: any;
  value: any;
}

export interface IMAGERY_ACQUISITION{
  traineeId: string;
  timestamp: string;
  properties:any;
}

export interface VICTIM_CROSSING {
  user: string;
  timestamp: string;
  scenarioId:string;
  data: any;
}

export interface TWO_CARS_CRASH {
  user: string;
  timestamp: string;
  scenarioId:string;
  data: any;
}

export interface CITY_ATTACK {
  user: string;
  timestamp: string;
  scenarioId:string;
  data: any;
}

export interface AIN_RESPONSE{
  identityManagementKey: string;
  audio: string;
  playerId: string;
  sourceEntity: string;
  text: string;
  timestamp:string
}

export interface GAME_EVENTS{
  identityManagementKey: string;
  action: string;
  playerId: string;
  sourceEntity: string;
  timestamp:string
}


export interface AVATAR_MOVES{
  gameplaySessionId: string;
  avatarTimestamp: string;
  data:any;
}


export interface CAMERA_SURVEILLANCE{
  gameplaySessionId: string;
  cameraTimestamp: string;
  data:any;
}


export interface GAME_ACTIONS{
  gameplaySessionId: string;
  actionTimestamp: string;
  data:any;
}


export interface NPC_MOVES{
  gameplaySessionId: string;
  npcTimestamp: string;
  data:any;
}

export interface REAL_TIME_RECORD_QUESTS{
  recordId: string;
  questType: string;
  questData:any;
}


export interface HUMAN_STANCE{
  playerId: string;
  timestamp: string;
  data:any
}

export interface INTERROGATION_SUMMARY{
  userId: string;
  interrogationId: string;
  data:any
}

export interface NEGOTIATION_SUMMARY{
  userId: string;
  interrogationId: string;
  data:any
}


export interface REPORT_GENERATION{
  username: any;
  datetime: any;
  data:any

}
export interface ANOMALY_DETECTION{
  sessionId: string;
  username: string;
  timestamp: string;
  data:any;
}
export interface LOCATION_PREDICTION{
  sessionId: string;
  username: string;
  timestamp: string;
  data:any;
}

export interface AI_RESULTS{
  sessionId: string;
  avatarId: string;
  timestamp: string;
  data:any;
}


export interface BLOODSTAIN_ORIGIN_POINT_ANALYSIS{
  playerId: string;
  timestamp: string;
  data:any;
}

export interface BULLET_TRAJECTORY_ANALYSIS{
  playerId: string;
  timestamp: string;
  data:any;
}

export interface CAR_DEFORMATION_ANALYSIS{
  playerId: string;
  timestamp: string;
  data:any;
}

export interface ASSESSMENT_REUSLTS{
  playerId: string;
  gameId: string;
  gameType: string;
  data:any;
}

export interface OBJECT_CENTROIDS{
  userId: string;
  timestamp: string;
  scene: string;
  data:any;
}

export interface VR_SCENARIOS{
  scenarioName: string;
  scenarioType: string;
}

// interface UserData {
//   id: number;
//   email: string;
//   first_name: string;
//   last_name: string;
// }

// interface QuizDetails {
//   timeopen: number;
//   timeclose: number;
//   timelimit: number;
//   grade: number;
// }

// interface QuizAttempt {
//   id: number;
//   quiz: number;
//   userid: number;
//   attempt: number;
//   uniqueid: number;
//   layout: string;
//   currentpage: number;
//   preview: number;
//   state: string;
//   timestart: number;
//   timefinish: number;
//   timemodified: number;
//   timemodifiedoffline: number;
//   timecheckstate: any;
//   sumgrades: number;
//   gradednotificationsenttime: number;
// }

// export interface LMS_QUIZ_RESULTS {
//   user_data: UserData;
//   course_name: string;
//   quiz_name: string;
//   quiz_details: QuizDetails;
//   quiz_attempts: QuizAttempt[];
// }


// export interface LMS_QUIZ_RESULTS{
//   userData: any;
//   firstName: string;
//   courseName: string;
//   quizName: string;
//   data:any;
// }

export interface LMS_QUIZ_RESULTS {
  userData: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  courseName: string;
  quizName: string;
  quizAttempts: any[];
}


export interface REPORT_CSI_AND_LMS_RESULTS{
  username: string;
  moduleName: string;
  datetime: string;
  reportCsiId: any;
  reportCsiLmsId: any;
}

const API_URL = environment.lawgameCommunication;
@Injectable({
  providedIn: 'root'
})
export class SdmDataService {

  constructor(
    private http: HttpClient
) { }


private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
    } else {
        console.error(
            `Backend retuned code ${error.status}, ` + `body was: ${error.error}`
        );
    }
    return throwError(error);
}

getScenarios(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/all')
      .pipe(catchError(SdmDataService.handleError));
}

getNpcResponse(playerId:string, timestamp:string, text:string, sourceEntity:string, audio:string, gameType:string): Observable<any> {
  const body = {
    playerId: playerId,
    timestamp:timestamp,
    text:text,
    sourceEntity:sourceEntity,
    audio:audio,
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findNpcResponse", body)
  .pipe(catchError(SdmDataService.handleError));
}

getEmotionalAssessment(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/allEmotionRecognitionRecording')
      .pipe(catchError(SdmDataService.handleError));
}

getQualityReports(gameType: string): Observable<any> {
  const body = {
    gameType: gameType
  }
  return this.http.post<any>(API_URL + "sdmc/getQAReportByGameType", body)
      .pipe(catchError(SdmDataService.handleError));
}

getAinResponse(playerId:string, timestamp:string, text:string, sourceEntity:string, audio:string, gameType:string): Observable<any> {
  const body = {
    playerId: playerId,
    timestamp:timestamp,
    text:text,
    sourceEntity:sourceEntity,
    audio:audio,
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findAinResponse", body)
  .pipe(catchError(SdmDataService.handleError));
}

getApartmentScenario(): Observable<any> {
  const body = {
    playerId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/filterApartmentScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

getCityAttackScenario(): Observable<any> {
  const body = {
    playerId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/filterCityAttackerScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}


getImageryAcquisition(gameType: string): Observable<any> {
  const body = {
    gameType: gameType,
    traineeId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/findImageryAcquisitionProperties", body)
  .pipe(catchError(SdmDataService.handleError));
}

getVictimCrossingScenario(): Observable<any> {
  const body = {
    playerId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/filterCarAccidentVictimCrossingScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

getVictimOnCurbScenario(): Observable<any> {
  const body = {
    playerId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/filterCarAccidentVictimOnCurbScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

getTwoCarsCrashScenario(): Observable<any> {
  const body = {
    playerId: null,
    timestamp: null
  }
  return this.http.post<any>(API_URL + "sdmc/filterCarAccidentTwoCarsCrashScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

getGameEvents(playerId:string, timestamp:string, sourceEntity:string, action:string, gameType:string){
  const body = {
    playerId: playerId,
    timestamp:timestamp,
    sourceEntity:sourceEntity,
    action:action,
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findGameEvent", body)
  .pipe(catchError(SdmDataService.handleError));
}


deleteAinResponse(playerId:String, timestamp:String, audio:String, sourceEntity:String, text:String, gameType:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp,
    audio: audio,
    sourceEntity: sourceEntity,
    text: text,
    gameType: gameType,
  }
  return this.http.post<any>(API_URL + "sdmc/deleteAinResponse", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteEmotionalAssessment(userId: string, interviewId: string, timestamp: string, organizerInstitution: string, participantFullName: string, angerGrade: string, calmGrade: string, trustGrade: string, disgustGrade: string, fearGrade: string, happinessGrade: string, sadnessGrade: string, surpriseGrade: string, interestGrade: string, accuracy: string): Observable<any>{
  const body = {
    userId: userId,
    interviewId: interviewId,
    timestamp: timestamp,
    organizerInstitution: organizerInstitution,
    participantFullName: participantFullName,
    angerGrade: angerGrade,
    calmGrade: calmGrade,
    trustGrade: trustGrade,
    disgustGrade: disgustGrade,
    fearGrade: fearGrade,
    happinessGrade: happinessGrade,
    sadnessGrade: sadnessGrade,
    surpriseGrade: surpriseGrade,
    interestGrade: interestGrade,
    accuracy: accuracy,
  }
  return this.http.post<any>(API_URL + "sdmc/deleteEmotionRecognitionRecording", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteNpcResponse(playerId:String, timestamp:String, audio:String, sourceEntity:String, text:String, gameType:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp,
    audio: audio,
    sourceEntity: sourceEntity,
    text: text,
    gameType: gameType,
  }
  return this.http.post<any>(API_URL + "sdmc/deleteNpcResponse", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteGameEvent(playerId:string, timestamp:string, action:string, sourceEntity:string, gameType:string): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp:timestamp,
    sourceEntity:sourceEntity,
    action:action,
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/deleteGameEvent", body)
  .pipe(catchError(SdmDataService.handleError));
}



deleteScenario(elementNameToDelete: string) : Observable<any>{
  const body = {
      name: elementNameToDelete,
  }
  return this.http.post<any>(API_URL + "sdmc/deleteScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteApartmentScenario(playerId:String, timestamp:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteApartmentScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteCityAttacker(playerId:String, timestamp:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCityAttackerScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteImageryAcquisition(traineeId:String, timestamp:String, gameType: String): Observable<any>{
  const body = {
    traineeId: traineeId,
    timestamp: timestamp,
    gameType: gameType
  }
  return this.http.post<any>(API_URL + "sdmc/deleteImageryAcquisitionProperties", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteVictimCrossing(playerId:String, timestamp:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCarAccidentVictimCrossingScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteVictimOnCurb(playerId:String, timestamp:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCarAccidentVictimOnCurbScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

deleteTwoCarsCrash(playerId:String, timestamp:String): Observable<any>{
  const body = {
    playerId: playerId,
    timestamp: timestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCarAccidentTwoCarsCrashScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteQualityAssReport(playerId:String, timestamp:String, gameType:String, gsd: any, overlap:any, value:any): Observable<any>{
  const body = {
    userId: playerId,
    timestamp: timestamp,
    gameType: gameType,
    gsd: gsd, 
    overlap: overlap, 
    value: value
  }
  return this.http.post<any>(API_URL + "sdmc/deleteQAReportJson", body)
  .pipe(catchError(SdmDataService.handleError));
}


renameElement(elementName: string, newName:string, description: string, type:string, category:string) : Observable<any>{
  const body = {
      currentName: elementName,
      newName: newName,
      description: description,
      type:type,
      category:category
  }
  return this.http.put<any>(API_URL + "sdmc/updateScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

addScenario(username: string, name:string, description: string, type:string, category:string) : Observable<any>{
  const body = {
      username: username,
      name: name,
      description: description,
      type:type,
      category:category
  }
  return this.http.post<any>(API_URL + "sdmc/createScenario", body)
  .pipe(catchError(SdmDataService.handleError));
}

addAinResponse(gameType:string, playerId:string, timestamp:string, text:string, sourceEntity:string, audio:string ){
  const body = {
    gameType: gameType,
    playerId: playerId,
    timestamp: timestamp,
    text:text,
    sourceEntity:sourceEntity,
    audio:audio
}
return this.http.post<any>(API_URL + "sdmc/saveAinResponse", body)
.pipe(catchError(SdmDataService.handleError));
}

addNpcResponse(gameType:string, playerId:string, timestamp:string, text:string, sourceEntity:string, audio:string ){
  const body = {
    gameType: gameType,
    playerId: playerId,
    timestamp: timestamp,
    text:text,
    sourceEntity:sourceEntity,
    audio:audio
}
return this.http.post<any>(API_URL + "sdmc/saveNpcResponse", body)
.pipe(catchError(SdmDataService.handleError));
}


addGameEvent(gameType:string, playerId:string, timestamp:string, action:string, sourceEntity:string ){
  const body = {
    gameType: gameType,
    playerId: playerId,
    timestamp: timestamp,
    action:action,
    sourceEntity:sourceEntity,
}
return this.http.post<any>(API_URL + "sdmc/saveGameEvent", body)
.pipe(catchError(SdmDataService.handleError));
}

addEmotionalAssessment(userId: string,  timestamp: string, interviewId: string, organizerInstitution: string, participantFullName: string, angerGrade: string, calmGrade: string, trustGrade: string, disgustGrade: string, fearGrade: string, happinessGrade: string, sadnessGrade: string, surpriseGrade: string, interestGrade: string, accuracy: string ){
  const body = {
  userId: userId,
  timestamp: timestamp,
  interviewId: interviewId,
  organizerInstitution: organizerInstitution,
  participantFullName: participantFullName,
  angerGrade: angerGrade,
  calmGrade: calmGrade,
  trustGrade: trustGrade,
  disgustGrade: disgustGrade,
  fearGrade: fearGrade,
  happinessGrade: happinessGrade,
  sadnessGrade: sadnessGrade,
  surpriseGrade: surpriseGrade,
  interestGrade: interestGrade,
  accuracy: accuracy,
}
return this.http.post<any>(API_URL + "sdmc/saveEmotionRecognitionRecording", body)
.pipe(catchError(SdmDataService.handleError));
}

addApartmentScenario(playerId: string,  timestamp: string, data:string){
  const body = {
  playerId: playerId,
  timestamp: timestamp,
  data: data
}
return this.http.post<any>(API_URL + "sdmc/createApartScenario", body)
.pipe(catchError(SdmDataService.handleError));
}

getAllAvatarMoves(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllAvatarMoves')
      .pipe(catchError(SdmDataService.handleError));
}

// deleteAvatarMoves(gameplaySessionId:String): Observable<any>{
//   const body = {
//     gameplaySessionId: gameplaySessionId
//   }
//   return this.http.post<any>(API_URL + "sdmc/deleteAvatarMovesByGameplaySessionId", body)
//   .pipe(catchError(SdmDataService.handleError));
// }

deleteAvatarMovesByAll(element:any): Observable<any>{
  const body = {
      gameplaySessionId: element.gameplaySessionId,
      avatarId: element.avatarId,
      xUnits: element.xUnits,
      yUnits: element.yUnits,
      zUnits: element.zUnits,
      userSpeedX: element.userSpeedX,
      userSpeedY: element.userSpeedY,
      userSpeedZ: element.userSpeedZ,
      userHeight: element.userHeight,
      objectRotationX: element.objectRotationX,
      objectRotationY: element.objectRotationY,
      objectRotationZ: element.objectRotationZ,
      avatarTimestamp: element.avatarTimestamp,
  }
  return this.http.post<any>(API_URL + "sdmc/deleteAvatarMoves", body)
  .pipe(catchError(SdmDataService.handleError));
}



getAllCameraSurveillance(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllCameraSurveillance')
      .pipe(catchError(SdmDataService.handleError));
}

// deleteCameraSurveillance(gameplaySessionId:String): Observable<any>{
//   const body = {
//     gameplaySessionId: gameplaySessionId
//   }
//   return this.http.post<any>(API_URL + "sdmc/deleteCameraSurveillanceByGameplaySessionId", body)
//   .pipe(catchError(SdmDataService.handleError));
// }

deleteCameraSurveillance(element:any): Observable<any>{
  const body = {
    gameplaySessionId: element.gameplaySessionId,
    xUnits: element.xUnits,
    yUnits: element.yUnits,
    zUnits: element.zUnits,
    cameraId: element.cameraId,
    cameraRotationX: element.cameraRotationX,
    cameraRotationY: element.cameraRotationY,
    cameraRotationZ: element.cameraRotationZ,
    cameraTimestamp: element.cameraTimestamp
  };
  return this.http.post<any>(API_URL + "sdmc/deleteCameraSurveillance", body)
  .pipe(catchError(SdmDataService.handleError));
}


getAllNPCMoves(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllNPCMoves')
      .pipe(catchError(SdmDataService.handleError));
}

deleteNPCMove(element:any): Observable<any>{
  const body = {
    gameplaySessionId: element.gameplaySessionId,
    npcId: element.npcId,
    xUnits: element.xUnits,
    yUnits: element.yUnits,
    zUnits: element.zUnits,
    npcUserSpeedX: element.npcUserSpeedX,
    npcUserSpeedY: element.npcUserSpeedY,
    npcUserSpeedZ: element.npcUserSpeedZ,
    objectRotationX: element.objectRotationX,
    objectRotationY: element.objectRotationY,
    objectRotationZ: element.objectRotationZ,
    npcUserHeight: element.npcUserHeight,
    npcTimestamp: element.npcTimestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteNpcMoves", body)
  .pipe(catchError(SdmDataService.handleError));
}

getAllGameActions(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllGameActions')
      .pipe(catchError(SdmDataService.handleError));
}

deleteGameAction(element:any): Observable<any>{
  const body = {
    gameplaySessionId: element.gameplaySessionId,
    avatarId: element.avatarId,
    actionId: element.actionId,
    objectId: element.objectId,
    actionTimestamp: element.actionTimestamp
  }
  return this.http.post<any>(API_URL + "sdmc/deleteGameAction", body)
  .pipe(catchError(SdmDataService.handleError));
}


getAllRealTimeRecordQuest(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllRealTimeRecordQuests')
      .pipe(catchError(SdmDataService.handleError));
}

deleteRealTimeRecordQuest(recordId:String): Observable<any>{
  const body = {
    recordId: recordId
  }
  return this.http.post<any>(API_URL + "sdmc/deleteRealTimeRecordQuestByRecordId", body)
  .pipe(catchError(SdmDataService.handleError));
}

findAllHumanStance(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllHumanStance')
      .pipe(catchError(SdmDataService.handleError));
}

findAllInterrogationSummary(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllInterrogationSummary')
      .pipe(catchError(SdmDataService.handleError));
}

findAllNegotiationSummary(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllNegotiationSummaryWithId')
      .pipe(catchError(SdmDataService.handleError));
}



onDeleteHumanStance(playerId:string, userId:string, interviewId:string, participantFullName:string, timestamp:string, motionIndicator:any, speedIndicator:any, kineticEnergyIndicator:any, jointAngles:any): Observable<any>{
  const body = {
    playerId: playerId, 
    userId: userId, 
    interviewId: interviewId, 
    participantFullName: participantFullName, 
    timestamp: timestamp, 
    motionIndicator: motionIndicator, 
    speedIndicator: speedIndicator, 
    kineticEnergyIndicator: kineticEnergyIndicator, 
    jointAngles: jointAngles
  }
  return this.http.post<any>(API_URL + "sdmc/deleteHumanStance", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteInterrogationSummary(userId:string, playerId:string, participantFullName:string,
  interrogationId:string, gameStartTimestamp:string, interrogationStartTimestamp:any, interrogationEndTimestamp:any, 
  confessionTimestamp:any, gameEndTimestamp:any, multipleQuestionRatio:any): Observable<any>{


  const body = {
    userId: userId, 
    playerId: playerId, 
    participantFullName: participantFullName, 
    interrogationId: interrogationId, 
    gameStartTimestamp: gameStartTimestamp, 
    interrogationStartTimestamp: interrogationStartTimestamp, 
    interrogationEndTimestamp: interrogationEndTimestamp, 
    confessionTimestamp: confessionTimestamp, 
    gameEndTimestamp: gameEndTimestamp,
    multipleQuestionRatio: multipleQuestionRatio
  }
  return this.http.post<any>(API_URL + "sdmc/deleteInterrogationSummary", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteNegotiationSummary(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteNegotiationSummaryById", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteCSIReportGeneration(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCSIReportById", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteBloodstainOriginPointAnalysis(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteBloodStainOriginPointAnalysisById", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteBulletTrajectoryAnalysis(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteBulletTrajectoryAnalysisById", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteCarDeformationsAnalysis(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteCarDeformationsAnalysisById", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteAnomalyDetection(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteAnomalyDetectionById", body)
  .pipe(catchError(SdmDataService.handleError));
}


onDeleteLocationPrediction(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteLocationPredictionById", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteAiResult(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteAIResultById", body)
  .pipe(catchError(SdmDataService.handleError));
}


findAllReportsForCarAccident(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllReportsForCarAccident')
      .pipe(catchError(SdmDataService.handleError));
}

findAllReportsForInterrogation(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllReportsForInterrogation')
      .pipe(catchError(SdmDataService.handleError));
}

findAllReportsForCsi(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllReportsForCsi')
      .pipe(catchError(SdmDataService.handleError));
}

findAllReportsForTerroristAttack(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllReportsForTerroristAttack')
      .pipe(catchError(SdmDataService.handleError));
}


getAllLocationPrediction(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllForLocationPrediction')
      .pipe(catchError(SdmDataService.handleError));
}


getAllAnomalyDetection(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllForAnomalyDetection')
      .pipe(catchError(SdmDataService.handleError));
}

getAllAIResults(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getAllAIResultsWithId')
      .pipe(catchError(SdmDataService.handleError));
}


getBloodstainOriginPointAnalysis(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllBloodStainOriginPointAnalysis')
      .pipe(catchError(SdmDataService.handleError));
}

getBulletTrajectoryAnalysis(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllBulletTrajectoryAnalysis')
      .pipe(catchError(SdmDataService.handleError));
}


getCarDeformationsAnalaysis(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllCarDeformationsAnalysis')
      .pipe(catchError(SdmDataService.handleError));
}

getAssessmentResults(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/findAllAssessmentResults')
      .pipe(catchError(SdmDataService.handleError));
}

getObjectCentroids(gameType:string): Observable<any> {
  const body = {
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findAllObjectCentroids", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteAssessmentResults(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteAssessmentResultsById", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteObjectCentroids(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteObjectCentroidsById", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteLmsQuizResults(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteQuizData", body)
  .pipe(catchError(SdmDataService.handleError));
}

generateScenarioCarAccident(scenarioId:string, scenarioType:string, playerId:string): Observable<any>{
  const body = {
    scenarioId: scenarioId,
    scenarioType: scenarioType,
    playerId: playerId
  }
  return this.http.post<any>(API_URL + "sdmc/generateScenarioCarAccident", body)
  .pipe(catchError(SdmDataService.handleError));
}

generateScenarioCSI(scenarioId:string, scenarioType:string, playerId:string): Observable<any>{
  const body = {
    scenarioId: scenarioId,
    scenarioType: scenarioType,
    playerId: playerId
  }
  return this.http.post<any>(API_URL + "sdmc/generateScenarioCSI", body)
  .pipe(catchError(SdmDataService.handleError));
}

generateScenarioTerrAttack(scenarioId:string, scenarioType:string, playerId:string): Observable<any>{
  const body = {
    scenarioId: scenarioId,
    scenarioType: scenarioType,
    playerId: playerId
  }
  return this.http.post<any>(API_URL + "sdmc/generateScenarioTerrAttack", body)
  .pipe(catchError(SdmDataService.handleError));
}

getLmsQuizResults(gameType:string){
  const body = {
    gameType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findLmsQuizResultsByGameType", body)
  .pipe(catchError(SdmDataService.handleError));
}

getLmsAndCsiResults(): Observable<any> {
  return this.http
      .get<any>(API_URL + 'sdmc/getLmsAndCsiResults')
      .pipe(catchError(SdmDataService.handleError));
}


findCsiAndLmsReportCombined(reportCsiId:any, reportCsiLmsId:any ){
  const body = {
    reportCsiId:reportCsiId,
    reportCsiLmsId:reportCsiLmsId
  }
  return this.http.post<any>(API_URL + "sdmc/findCsiAndLmsReportCombined", body)
  .pipe(catchError(SdmDataService.handleError));
}


getVrScenariosByGameType(gameType:any){
  const body = {
    scenarioType:gameType
  }
  return this.http.post<any>(API_URL + "sdmc/findScenariosVRByScenarioType", body)
  .pipe(catchError(SdmDataService.handleError));
}


getScenarioVRDetails(id:any){
  const body = {
    id:id
  }
  return this.http.post<any>(API_URL + "sdmc/getScenarioVRDetails", body)
  .pipe(catchError(SdmDataService.handleError));
}

onDeleteVRScenarios(id:any): Observable<any>{
  const body = {
    id: id
  }
  return this.http.post<any>(API_URL + "sdmc/deleteScenarioVrById", body)
  .pipe(catchError(SdmDataService.handleError));
}

}