import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { HUMAN_STANCE, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { NpcMovesComponent } from '../npc-moves/npc-moves.component';

@Component({
  selector: 'app-human-stance',
  templateUrl: './human-stance.component.html',
  styleUrls: ['./human-stance.component.css']
})
export class HumanStanceComponent implements OnInit {
  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: HUMAN_STANCE[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['playerId', 'timestamp', 'details', 'actions'];
  data: Map<string, object> = new Map;

  dataSource: MatTableDataSource<HUMAN_STANCE> = new MatTableDataSource();

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    if(this.gameAndDataPath === 'interrogation/structuredData'){
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
        { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/structuredData'},
        { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
        { name: 'STRUCTURED_DATA.HUMAN_STANCE', location: '/structuredData/humanStance' },
      ]);
      this.interactionService.setSelectedGame("interrogation");
    }else{
      this.router.navigate(['/home']);
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
      ]);
    }
    this.findAllHumanStance();
    this.isLoading = false;
  }

  findAllHumanStance(){
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.findAllHumanStance().subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  openDeleteDialog(playerId:any, userId:any, interviewId:any, 
    participantFullName:any, timestamp:any, motionIndicator:any, 
    speedIndicator:any, kineticEnergyIndicator:any, jointAngles:any) {

    this.isLoading = true;
    this.interactionService.setElementFromWhereToDelete('humanStance');
    this.interactionService.setPlayerId(playerId);
    this.interactionService.setUserId(userId);
    this.interactionService.setInterviewId(interviewId);
    this.interactionService.setParticipantFullName(participantFullName);
    this.interactionService.setTimestamp(timestamp);
    this.interactionService.setMotionIndicator(motionIndicator);
    this.interactionService.setSpeedIndicator(speedIndicator);
    this.interactionService.setKineticEnergyIndicator(kineticEnergyIndicator);
    this.interactionService.setJointAngles(jointAngles);
  
    this.dialog
    .open(DeleteScenarioComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe((val: HumanStanceComponent) => {
      this.findAllHumanStance();
    });
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

openSnackBar(notificationMessage: string, type: string, duration: number) {
  this._snackBar.open(notificationMessage, 'X', {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: type,
    duration: duration,
  });
}

}
