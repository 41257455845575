import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { REPORT_CSI_AND_LMS_RESULTS, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { ReportCsiAndLmsResultsComponent } from '../report-csi-and-lms-results/report-csi-and-lms-results.component';
import { ReportVizualizationCsiAndLmsResultsComponent } from '../report-vizualization-csi-and-lms-results/report-vizualization-csi-and-lms-results.component';

@Component({
  selector: 'app-report-csi-and-lms-results-pilot',
  templateUrl: './report-csi-and-lms-results-pilot.component.html',
  styleUrls: ['./report-csi-and-lms-results-pilot.component.css']
})
export class ReportCsiAndLmsResultsPilotComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: REPORT_CSI_AND_LMS_RESULTS[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['username', 'moduleName', 'datetime', 'actions'];
  dataSource: MatTableDataSource<REPORT_CSI_AND_LMS_RESULTS> = new MatTableDataSource();
  playerId: string = '';
  timestamp: string = '';
  data: Map<string, object> = new Map;
  gameType: string = '';


  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res) => {
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any) => {
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
          { name: 'HOME.GAMES_TITLES.CSI', location: '/homePilot' },
          { name: 'STRUCTURED_DATA.REPORTS_CSI', location: '/reportsCsi' },
          { name: 'STRUCTURED_DATA.REPORT_CSI_AND_LMS_RESULTS.TITLE', location: '/reportCsiAndLmsResults' },
        ]);
    this.gameType = this.interactionService.getSelectedGame();
    this.getLmsAndCsiResults();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
   // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
  

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  getLmsAndCsiResults() {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.getLmsAndCsiResults().subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  // openDeleteDialog(id: any) {
  //   this.isLoading = true;
  //   this.interactionService.setElementFromWhereToDelete('lmsQuizData');
  //   this.interactionService.setRecordId(id);
  //   this.dialog
  //     .open(DeleteScenarioComponent, {
  //       data: {},
  //     })
  //     .afterClosed()
  //     .subscribe((val: LmsQuizResultsComponent) => {
  //       this.getLmsAndCsiResults();
  //     });
  //   this.isLoading = false;
  // }

  // generateReportDialog(reportCsiId:any, reportCsiLmsId:any) {
  //   this.isLoading = true;
  //   this.interactionService.setJson(json);
  //   this.dialog
  //     .open(ReportVizualizationLmsQuizResultsComponent, {
  //       data: {},
  //     })
  //     .afterClosed()
  //     .subscribe((val: LmsQuizResultsComponent) => {
  //       // this.getAllRealTimeRecordQuest();
  //     });
  //   this.isLoading = false;
  // }



  generateReportDialog(reportCsiId:any, reportCsiLmsId:any) {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.findCsiAndLmsReportCombined(reportCsiId, reportCsiLmsId).subscribe(
        (res) => {
          this.dataSource.data = res;
          this.interactionService.setJson(res);
          this.dialog
          .open(ReportVizualizationCsiAndLmsResultsComponent, {
            data: {},
          })
          .afterClosed()
          .subscribe((val: ReportCsiAndLmsResultsComponent) => {
            // this.getAllRealTimeRecordQuest();
          });
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }
}
