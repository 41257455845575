<div class="sidebar">
    <div class="buttonsWrapper">
        <div class="scenarioGeneration">
            <button mat-raised-button  [routerLink]="['/carAccidentScenarioGeneration']" routerLinkActive="active">
                <mat-icon class="material-icons">description</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
        </div>
        
        <div class="reportGeneration">
            <button mat-raised-button  [routerLink]="['/reportCarDeformation']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.CAR_DEFORMATION_ANALYSIS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportLmsQuizResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.LMS_QUIZ_RESULTS' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>
    