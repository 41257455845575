<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="wrapper">
    <div class="invoice">
      <button class="close-button" (click)="closeInvoice()">×</button>
      <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
        <mat-icon>download</mat-icon>
      </button>
      <h2>Report for {{ data.playerId }}</h2>
      <p><strong>Player ID:</strong> {{ data.playerId }}</p>
      <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
      
      <h3>Bullet Hole Attributes</h3>
      
      <p><strong>Bullet Hole Image:</strong></p>

      <ng-container *ngIf="isBinaryStream(data.bulletHoleAttributes.bullet_hole_image_name_response); else localImage">
        <img [src]="data.bulletHoleAttributes.bullet_hole_image_name_response" alt="Bullet Hole Image" width="200">
      </ng-container>
      <ng-template #localImage>
        <p>Image is related to local storage.</p>
      </ng-template>
      
      <p><strong>Directionality:</strong> {{ data.bulletHoleAttributes.directionality }}</p>
      <p><strong>Azimuthian Impact Angle:</strong> {{ data.bulletHoleAttributes.azimuthian_impact_angle }}</p>
      <p><strong>Elevation Impact Angle:</strong> {{ data.bulletHoleAttributes.elevation_impact_angle }}</p>
      
      <p><strong>Bullet Hole Object Position:</strong></p>
      <table>
        <tbody>
          <tr *ngFor="let value of data.bulletHoleAttributes.bullet_hole_object_position_response; let i = index">
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
  
      <p><strong>Bullet Hole Object Name:</strong> {{ data.bulletHoleAttributes.bullet_hole_object_name_response }}</p>
      <p><strong>Bullet Hole Object Y Rotation:</strong> {{ data.bulletHoleAttributes.bullet_hole_object_y_rotation_response }}</p>
    </div>
  </div>
  
  
  
  