<div *ngIf="isLoading === true" class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
  <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>
<div class="home">
  <app-header class="fixed w-full z-50"></app-header>
  <h3>{{'HOME.STEP_ONE' | translate}}</h3>
  <div class="games-container">
    <div class="car-accident-card">
      <button class="game-buttons" [ngClass]="{'active': isBtnActive === 'carAccident'}"
        (click)="toggleBtn('carAccident')">
        <img src="../../../assets/games-pictures/car-accident.png" alt="car-accident-picture"
          style="height: 100px; width: 100%;">
        <p class="game-button-title">{{'HOME.GAMES_TITLES.CAR_ACCIDENT' | translate}}</p>
      </button>
    </div>
    <div class="interrogation-card">
      <button class="game-buttons" [ngClass]="{'active': isBtnActive === 'interrogation'}"
        (click)="toggleBtn('interrogation')">
        <img src="../../../assets/games-pictures/intergogation.png" alt="interrogation"
          style="height: 100px; width: 100%;">
        <p class="game-button-title">{{'HOME.GAMES_TITLES.INTERROGATION' | translate}}</p>
      </button>
    </div>
    <div class="csi-card">
      <button class="game-buttons" [ngClass]="{'active': isBtnActive === 'csi'}" (click)="toggleBtn('csi')">
        <img src="../../../assets/games-pictures/crime-scene-investigation.png" alt="crime-scene-investigation"
          style="height: 100px; width: 100%;">
        <p class="game-button-title">{{'HOME.GAMES_TITLES.CSI' | translate}}</p>
      </button>
    </div>
    <div class="terrorist-attack-card">
      <button class="game-buttons" [ngClass]="{'active': isBtnActive === 'terroristAttack'}"
        (click)="toggleBtn('terroristAttack')">
        <img src="../../../assets/games-pictures/terrorist-attack.png" alt="terrorist-attack"
          style="height: 100px; width: 100%;">
        <p class="game-button-title">{{'HOME.GAMES_TITLES.TERRORIST_ATTACK' | translate}}</p>
      </button>
    </div>
  </div>
  <div class="data-type-buttons-container">
    <h3>{{'HOME.STEP_TWO' | translate}}</h3>
    <div class="buttons-wrapper">
      <!-- <button mat-raised-button (click)="onDataStructured()" [disabled]="!isBtnActive">{{'HOME.STRUCTURED_DATA' | translate}}</button>
      <button mat-raised-button (click)="onDataUnstructured()"  [disabled]="!isBtnActive">{{'HOME.UNSTRUCTURED_DATA' | translate}}</button> -->
      <button mat-raised-button [ngClass]="{'active': dataType === 'structuredData'}"
        (click)="toggleDataType('structuredData')" [disabled]="!isBtnActive">{{'HOME.STRUCTURED_DATA' |
        translate}}</button>
      <button mat-raised-button [ngClass]="{'active': dataType === 'unstructuredData'}"
        (click)="toggleDataType('unstructuredData')" [disabled]="!isBtnActive">{{'HOME.UNSTRUCTURED_DATA' |
        translate}}</button>
    </div>
  </div>
  <app-footer class="fixed bottom-0 w-full"></app-footer>
</div>