<div class="invoice-container">
  <div class="invoice" #invoice>
    <button class="close-button" (click)="closeInvoice()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2>Anomaly Detection Report</h2>
    <p><strong>Session ID:</strong> {{ data.sessionId }}</p>
    <p><strong>Username:</strong> {{ data.username }}</p>
    <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
    <p><strong>Avatar ID:</strong> {{ data.avatarId }}</p>
    <p><strong>Avatar Action:</strong> {{ data.avatarAction }}</p>
    <p><strong>Avatar Prediction:</strong> {{ data.avatarPrediction }}</p>
    <p><strong>Avatar Explanation:</strong> {{ data.avatarExplanation }}</p>
  </div>
</div>
