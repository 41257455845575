import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDataService, GeneralFileType } from 'src/app/services/file-data/file-data.service';
import { Observable} from 'rxjs';
import { base64StringToBlob } from 'blob-util';
import * as saveAs from 'file-saver';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { EditElementComponent } from '../edit-element/edit-element.component';
import { DeleteElementComponent } from '../delete-element/delete-element.component';
import { AddDirectoryComponent } from '../add-directory/add-directory.component';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css'],
    animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ModelsComponent implements OnInit {
  @ViewChild('paginator')
  paginator!: MatPaginator;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private fileDataService: FileDataService,
    public dialog: MatDialog,
    private userService: UserService
  ) { 

  }
   userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  path?: string;
  generalFileTypeList: GeneralFileType[] = [];
  fileElements!: Observable<GeneralFileType[]>;
  currentPath!: string;
  canNavigateUp: boolean = true;
  public elementPath: string = '';
  currentFile?: File;
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  selectedFiles?: FileList;
  currentDirectoryName: string = '';
  progress = 0;
  message = '';
  searchText ='';
  dataSource: MatTableDataSource<GeneralFileType> = new MatTableDataSource();
  displayColumns: string[] = ['name', 'type', 'actions'];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  fileUploadLabel?:string ='';
  ELEMENT_DATA: GeneralFileType[] = [];
  
  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    this.fileUploadLabel = "";
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
    }, (err: any)=>{
      console.log("error" + err.error);
    });
    let path = this.interactionService.getModelsPath();
    this.interactionService.setElementFromMenu("models");
    switch(path){
      case "carAccident/unstructuredData/models": {
        let elementFromMenu = this.interactionService.getElementFromMenu();
        this.listFilesCarAccidentByElementAndPath(elementFromMenu, '/');
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/unstructuredData'},
          { name: 'HOME.UNSTRUCTURED_DATA', location: '/unstructuredData' },
          { name: 'UNSTRUCTURED_DATA.MODELS', location: '/models'}
        ]);
        break;
      }
      case "interrogation/unstructuredData/models": {
        let elementFromMenu = this.interactionService.getElementFromMenu();
        this.listFilesInterrogationByElementAndPath(elementFromMenu, '/');
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/unstructuredData'},
          { name: 'HOME.UNSTRUCTURED_DATA', location: '/unstructuredData' },
          { name: 'UNSTRUCTURED_DATA.MODELS', location: '/models'}
        ]);
        break;
      }
      case "csi/unstructuredData/models": {
        let elementFromMenu = this.interactionService.getElementFromMenu();
        this.listFilesCsiByElementAndPath(elementFromMenu, '/');
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CSI', location:'/unstructuredData'},
          { name: 'HOME.UNSTRUCTURED_DATA', location: '/unstructuredData' },
          { name: 'UNSTRUCTURED_DATA.MODELS', location: '/models'}
        ]);
        break;
      }
      case "terroristAttack/unstructuredData/models": {
        let elementFromMenu = this.interactionService.getElementFromMenu();
        this.listFilesTerrAttackByElementAndPath(elementFromMenu, '/');
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location:'/unstructuredData'},
          { name: 'HOME.UNSTRUCTURED_DATA', location: '/unstructuredData' },
          { name: 'UNSTRUCTURED_DATA.MODELS', location: '/models'}
        ]);
        break;
      }   
      //AFTER REFREH THIS WILL APEAR - TODO: FIX IT 
      default:{
        this.router.navigate(['/home']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
        ]);
        break;
      }
    }
    this.currentDirectoryName = this.interactionService.getcurrentDirectoryName();
    this.elementPath = this.interactionService.getElementPath();
    this.canNavigateUp = false;
    this.isLoading = false;
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    if( event.target.files.length == 0){
      this.selectedFiles = undefined;
    }
    this.fileUploadLabel = this.selectedFiles?.[0].name;
  }

  upload() {
    this.isLoading = true;
    this.progress = 0;
    let gameSelected = this.interactionService.getSelectedGame();
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        let elementPath = this.currentPath;
        this.currentFile = file;
        switch(gameSelected){
          case "carAccident":{
            this.uploadFileForCarAccident(this.currentFile, elementPath);
            break;
          }
          case "interrogation":{
            this.uploadFileForInterrogation(this.currentFile, elementPath);
            break;
          }
          case "csi":{
            this.uploadFileForCsi(this.currentFile, elementPath);
            break;
          }
          case "terroristAttack":{
            this.uploadFileForTerroristAttack(this.currentFile, elementPath);
            break;
          }
        }
        this.fileUploadLabel = "";
      }
    }  
  }
  uploadFileForCarAccident(file:File, path:string ){
      if (file) {
        let element = this.interactionService.getElementFromMenu();
        path = this.currentPath;
        this.currentFile = file;
        this.message = this.fileDataService.uploadFileForCarAccident(element, path, this.currentFile);
        this.currentFile = undefined;
        this.openSnackBar(
          this.translate.instant("ROOT.UPLOAD_FILE.SUCCESS"),
          'notif-success',
          4000
        );
        this.isLoading = false;
      
      } else {
        this.openSnackBar(
          this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          'notif-warning',
          4000
        );
        this.isLoading = false;
      }
    this.selectedFiles = undefined;
    this.isLoading = false;

  }

  uploadFileForInterrogation(file:File, path:string ){
      if (file) {
        let element = this.interactionService.getElementFromMenu();
        path = this.currentPath;
        this.currentFile = file;
        this.message = this.fileDataService.uploadFileForInterrogation(element, path, this.currentFile);
        this.currentFile = undefined;
        this.openSnackBar(
          this.translate.instant("ROOT.UPLOAD_FILE.SUCCESS"),
          'notif-success',
          4000
        );
        this.isLoading = false;
      
      } else {
        this.openSnackBar(
          this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          'notif-warning',
          4000
        );
        this.isLoading = false;
      }
    this.selectedFiles = undefined;
    this.isLoading = false;

  }

  uploadFileForCsi(file:File, path:string ){
      if (file) {
        let element = this.interactionService.getElementFromMenu();
        path = this.currentPath;
        this.currentFile = file;
        this.message = this.fileDataService.uploadFileForCsi(element, path, this.currentFile);
        this.currentFile = undefined;
        this.openSnackBar(
          this.translate.instant("ROOT.UPLOAD_FILE.SUCCESS"),
          'notif-success',
          4000
        );
        this.isLoading = false;
      
      } else {
        this.openSnackBar(
          this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          'notif-warning',
          4000
        );
        this.isLoading = false;
      }
    this.selectedFiles = undefined;
    this.isLoading = false;

  }

  uploadFileForTerroristAttack(file:File, path:string ){
      if (file) {
        let element = this.interactionService.getElementFromMenu();
        path = this.currentPath;
        this.currentFile = file;
        this.message = this.fileDataService.uploadFileForTerroristAttack(element, path, this.currentFile);
        this.currentFile = undefined;
        this.openSnackBar(
          this.translate.instant("ROOT.UPLOAD_FILE.SUCCESS"),
          'notif-success',
          4000
        );
        this.isLoading = false;
      
      } else {
        this.openSnackBar(
          this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          'notif-warning',
          4000
        );
        this.isLoading = false;
      }
    this.selectedFiles = undefined;
    this.isLoading = false;

  }
  downloadFile(event: any) {
    this.isLoading = true;
    let filename = event;
    let gameSelected = this.interactionService.getSelectedGame();
    switch(gameSelected){
      case "carAccident":{
        let element = this.interactionService.getElementFromMenu();
        this.downloadFileForCarAccident(element, filename);
        break;
      }
      case "interrogation":{
        let element = this.interactionService.getElementFromMenu();
        this.downloadFileForInterrogation(element, filename);
        break;
      }
      case "csi":{
        let element = this.interactionService.getElementFromMenu();
        this.downloadFileForCsi(element, filename);
        break;
      }
      case "terroristAttack":{
        let element = this.interactionService.getElementFromMenu();
        this.downloadFileForTerroristAttack(element, filename);
        break;
      }
    }
  }

  downloadFileForCarAccident(element:string, path:string){
  let filename = path;
    if (filename) {
      let elementPath = this.currentPath;
      let element = elementPath + filename;
      let elementGame = this.interactionService.getElementFromMenu();
      this.fileDataService.downloadFileForCarAccident(elementGame, element).subscribe
        (response => {
          if (response.filename, response.contentType, response.byteArray) {
            var urlCreator = window.URL || window.webkitURL;
            let filename = response.filename;
            let contentType = response.contentType;
            let fileBinaryStream = response.byteArray;

            let blob = base64StringToBlob(fileBinaryStream, contentType);
            saveAs(blob, filename);
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD.SUCCESS"),
              'notif-success',
              4000
            );
            
            this.isLoading = false;
          } else {
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
            this.isLoading = false;
          }
        },
          (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        );
    }
  }
  downloadFileForInterrogation(element:string, path:string){
    let filename = path;
    if (filename) {
      let elementPath = this.currentPath;
      let element = elementPath + filename;
      let elementGame = this.interactionService.getElementFromMenu();
      this.fileDataService.downloadFileForInterrogation(elementGame, element).subscribe
        (response => {
          if (response.filename, response.contentType, response.byteArray) {
            var urlCreator = window.URL || window.webkitURL;
            let filename = response.filename;
            let contentType = response.contentType;
            let fileBinaryStream = response.byteArray;

            let blob = base64StringToBlob(fileBinaryStream, contentType);
            saveAs(blob, filename);
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD.SUCCESS"),
              'notif-success',
              4000
            );
            
            this.isLoading = false;
          } else {
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
            this.isLoading = false;
          }
        },
          (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        );
    }
  }
  downloadFileForCsi(element:string, path:string){
    let filename = path;
    if (filename) {
      let elementPath = this.currentPath;
      let element = elementPath + filename;
      let elementGame = this.interactionService.getElementFromMenu();
      this.fileDataService.downloadFileForCsi(elementGame, element).subscribe
        (response => {
          if (response.filename, response.contentType, response.byteArray) {
            var urlCreator = window.URL || window.webkitURL;
            let filename = response.filename;
            let contentType = response.contentType;
            let fileBinaryStream = response.byteArray;

            let blob = base64StringToBlob(fileBinaryStream, contentType);
            saveAs(blob, filename);
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD.SUCCESS"),
              'notif-success',
              4000
            );
            
            this.isLoading = false;
          } else {
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
            this.isLoading = false;
          }
        },
          (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        );
    }
  }
  downloadFileForTerroristAttack(element:string, path:string){
    let filename = path;
    if (filename) {
      let elementPath = this.currentPath;
      let element = elementPath + filename;
      let elementGame = this.interactionService.getElementFromMenu();
      this.fileDataService.downloadFileForTerroristAttack(elementGame, element).subscribe
        (response => {
          if (response.filename, response.contentType, response.byteArray) {
            var urlCreator = window.URL || window.webkitURL;
            let filename = response.filename;
            let contentType = response.contentType;
            let fileBinaryStream = response.byteArray;

            let blob = base64StringToBlob(fileBinaryStream, contentType);
            saveAs(blob, filename);
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD.SUCCESS"),
              'notif-success',
              4000
            );
            
            this.isLoading = false;
          } else {
            this.openSnackBar(
              this.translate.instant("ACTIONS_BUTTONS.DOWNLOAD_ERROR_MESSAGE"),
              'notif-error',
              4000
            );
            this.isLoading = false;
          }
        },
          (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        );
    }
  }
  openDirectory(event: any){
    this.isLoading = true;
    let dirName = event;
    let gameSelected = this.interactionService.getSelectedGame();
      switch(gameSelected){
        case "carAccident":{
          let element = this.interactionService.getElementFromMenu();
          this.listFilesCarAccidentByElementAndPath(element, dirName);
          break;
        }
        case "interrogation":{
          let element = this.interactionService.getElementFromMenu();
          this.listFilesInterrogationByElementAndPath(element, dirName);
          break;
        }
        case "csi":{
          let element = this.interactionService.getElementFromMenu();
          this.listFilesCsiByElementAndPath(element, dirName);
          break;
        }
        case "terroristAttack":{
          let element = this.interactionService.getElementFromMenu();
          this.listFilesTerrAttackByElementAndPath(element, dirName);
          break;
        }
      }
  }



  listFilesCarAccidentByElementAndPath(element: string, path: string) {
    // console.log("A ajuns in models.ts - la listFiles Car Accident");
    this.isLoading = true;
    element = this.interactionService.getElementFromMenu();
    let dirName = path;
    if(dirName == '/'){
      this.currentPath = '/';
    }else{
      this.currentPath = this.pushToPath(this.currentPath, dirName!);
    }
    this.interactionService.setcurrentDirectoryName(this.currentPath);
    if(this.currentPath ===  '/'){
      this.canNavigateUp = false;
    }else{
      this.canNavigateUp = true;
    }
    if (dirName || dirName == "") {
      this.fileDataService.listFilesCarAccidentByElementAndPath(element, this.currentPath).subscribe(event => {
        this.path = this.currentPath;
        this.generalFileTypeList = event;
        this.dataSource.data = event;
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = event.count;
        this.isLoading = false;
      },
        (err: any) => {
          // console.log("in eroarea pe raspuns de la models.ts");
          // this.openSnackBar(
          //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          //   'notif-warning',
          //   4000
          // );
          // this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }
  listFilesCsiByElementAndPath(element: string, path: string) {
    // console.log("A ajuns in models.ts - la listFiles Csi");
    this.isLoading = true;
    element = this.interactionService.getElementFromMenu();
    let dirName = path;
    if(dirName == '/'){
      this.currentPath = '/';
    }else{
      this.currentPath = this.pushToPath(this.currentPath, dirName!);
    }
    this.interactionService.setcurrentDirectoryName(this.currentPath);
    if(this.currentPath ===  '/'){
      this.canNavigateUp = false;
    }else{
      this.canNavigateUp = true;
    }
    if (dirName || dirName == "") {
      this.fileDataService.listFilesCsiByElementAndPath(element, this.currentPath).subscribe(event => {
        this.path = this.currentPath;
        this.generalFileTypeList = event;
        this.dataSource.data = event;
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = event.count;
        this.isLoading = false;
      },
        (err: any) => {
          // console.log("in eroarea pe raspuns de la models.ts");
          // this.openSnackBar(
          //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          //   'notif-warning',
          //   4000
          // );
          // this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }
  listFilesInterrogationByElementAndPath(element: string, path: string) {
    // console.log("A ajuns in models.ts - la listFiles Interrogation");
    this.isLoading = true;
    element = this.interactionService.getElementFromMenu();
    let dirName = path;
    if(dirName == '/'){
      this.currentPath = '/';
    }else{
      this.currentPath = this.pushToPath(this.currentPath, dirName!);
    }
    this.interactionService.setcurrentDirectoryName(this.currentPath);
    if(this.currentPath ===  '/'){
      this.canNavigateUp = false;
    }else{
      this.canNavigateUp = true;
    }
    if (dirName || dirName == "") {
      this.fileDataService.listFilesInterrogationByElementAndPath(element, this.currentPath).subscribe(event => {
        this.path = this.currentPath;
        this.generalFileTypeList = event;
        this.dataSource.data = event;
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = event.count;
        this.isLoading = false;
      },
        (err: any) => {
          // console.log("in eroarea pe raspuns de la models.ts");
          // this.openSnackBar(
          //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          //   'notif-warning',
          //   4000
          // );
          // this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }
  listFilesTerrAttackByElementAndPath(element: string, path: string) {
    // console.log("A ajuns in models.ts - la listFiles Terr Attack");
    this.isLoading = true;
    element = this.interactionService.getElementFromMenu();
    let dirName = path;
    if(dirName == '/'){
      this.currentPath = '/';
    }else{
      this.currentPath = this.pushToPath(this.currentPath, dirName!);
    }
    this.interactionService.setcurrentDirectoryName(this.currentPath);
    if(this.currentPath ===  '/'){
      this.canNavigateUp = false;
    }else{
      this.canNavigateUp = true;
    }
    if (dirName || dirName == "") {
      this.fileDataService.listFilesTerrAttackByElementAndPath(element, this.currentPath).subscribe(event => {
        this.path = this.currentPath;
        this.generalFileTypeList = event;
        this.dataSource.data = event;
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = event.count;
        this.isLoading = false;
      },
        (err: any) => {
          // console.log("in eroarea pe raspuns de la models.ts");
          // this.openSnackBar(
          //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
          //   'notif-warning',
          //   4000
          // );
          // this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }

  refreshDirectory(event: any) {
    this.isLoading = true;
    let dirName = event;
    let element = this.interactionService.getElementFromMenu();
    this.interactionService.setcurrentDirectoryName(this.currentPath);
    if (dirName || dirName == "") {
      let gameSelected = this.interactionService.getSelectedGame();
      switch(gameSelected){
        case "carAccident":{
          this.fileDataService.listFilesCarAccidentByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "interrogation":{
          this.fileDataService.listFilesInterrogationByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "csi":{
          this.fileDataService.listFilesCsiByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "terroristAttack":{
          this.fileDataService.listFilesTerrAttackByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
      }
    }
    this.isLoading = false;
  }

  navigateUp() {
    this.isLoading = true;
    let element = this.interactionService.getElementFromMenu();
      this.currentPath = this.popFromPath(this.currentPath);
      this.interactionService.setcurrentDirectoryName(this.currentPath);
      if(this.currentPath === '/'){
        this.canNavigateUp = false
      }else{
        this.canNavigateUp = true;
      }
      let gameSelected = this.interactionService.getSelectedGame();
      switch(gameSelected){
        case "carAccident":{
          this.fileDataService.listFilesCarAccidentByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "interrogation":{
          this.fileDataService.listFilesInterrogationByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "csi":{
          this.fileDataService.listFilesCsiByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
        case "terroristAttack":{
          this.fileDataService.listFilesTerrAttackByElementAndPath(element, this.currentPath).subscribe(event => {
            this.path = this.currentPath;
            this.generalFileTypeList = event;
            this.dataSource.data = event;
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = event.count;
            this.isLoading = false;
          },
            (err: any) => {
              // this.openSnackBar(
              //   this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              //   'notif-warning',
              //   4000
              // );
              this.isLoading = false;
            }
          );
          break;
        }
      }
    this.isLoading = false;
  }


  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  isIterable(input: any) {
    if (input === null || input === undefined) {
      return false
    }

    return typeof input[Symbol.iterator] === 'function'
  }

  openNewFolderDialog() {
    this.isLoading = true;
    let elementPath = this.currentPath;
    this.interactionService.setElementPath(elementPath);
    this.interactionService.getElementFromMenu();
    this.dialog
    .open(AddDirectoryComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe(() => {
      this.refreshDirectory(elementPath);
    });
    this.isLoading = false;
}

  openRenameDialog(elementToRename: any, isDirectory: any) {
    this.isLoading = true;
    let elementName = elementToRename;
    let isDir = isDirectory;
    let elementPath = this.currentPath;
    this.interactionService.setElementPath(elementPath);
    this.interactionService.setRenamedElementName(elementName);
    this.interactionService.setElementType(isDir);
    this.dialog
    .open(EditElementComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe(() => {
      this.refreshDirectory(elementPath);
    });
    this.isLoading = false;
}

  openDeleteDialog(elementNameToDelete: any, isDirectory: any) {
    this.isLoading = true;
    let elementName = elementNameToDelete;
    let isdirectory = isDirectory;
    let elementPath = this.currentPath;
    this.interactionService.setElementPath(elementPath);
    this.interactionService.setdeletedElementName(elementName);
    this.interactionService.setElementType(isdirectory);
    this.dialog
    .open(DeleteElementComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe(() => {
      this.refreshDirectory(elementPath);
    });
    this.isLoading = false;
}

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}
}
