<div class="sidebar">
    <h3>{{'ROOT.MENU' | translate | uppercase}}</h3>
    <div class="buttonsWrapper">
        <div class="cityAttack">
            <button mat-raised-button [routerLink]="['/structuredData/cityAttack']" routerLinkActive="active">
                <mat-icon class="material-icons">location_city</mat-icon>
                <span class="ml-3">{{'STRUCTURED_DATA.CITY_ATTACK' | translate}}</span></button>
        </div>
    <div class="avatarMoves">
        <button mat-raised-button [routerLink]="['/structuredData/avatarMoves']" routerLinkActive="active">
            <mat-icon class="material-icons">person_outline</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.AVATAR_MOVES' | translate}}</span></button>
    </div>

    <div class="cameraSurveillance">
        <button mat-raised-button [routerLink]="['/structuredData/cameraSurveillance']" routerLinkActive="active">
            <mat-icon class="material-icons">camera_alt</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.CAMERA_SURVEILLANCE' | translate}}</span></button>
    </div>

    <div class="gameActions">
        <button mat-raised-button [routerLink]="['/structuredData/gameActions']" routerLinkActive="active">
            <mat-icon class="material-icons">videogame_asset</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.GAME_ACTIONS' | translate}}</span></button>
    </div>

    <div class="npcMoves">
        <button mat-raised-button [routerLink]="['/structuredData/npcMoves']" routerLinkActive="active">
            <mat-icon class="material-icons">face</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.NPC_MOVES' | translate}}</span></button>
    </div>

    <div class="realTimeRecordQuests">
        <button mat-raised-button [routerLink]="['/structuredData/realTimeRecordQuests']" routerLinkActive="active">
            <mat-icon class="material-icons">record_voice_over</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.REAL_TIME_RECORD_QUESTS' | translate}}</span></button>
    </div>

    <div class="locationPrediction">
        <button mat-raised-button [routerLink]="['/structuredData/locationPrediction']" routerLinkActive="active">
            <mat-icon class="material-icons"> location_on</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.LOCATION_PREDICTION' | translate}}</span></button>
    </div>

    <div class="anomalyDetection">
        <button mat-raised-button [routerLink]="['/structuredData/anomalyDetection']" routerLinkActive="active">
            <mat-icon class="material-icons">report_problem</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.ANOMALY_DETECTION' | translate}}</span></button>
    </div>

    <div class="aiResultInsertion">
        <button mat-raised-button [routerLink]="['/structuredData/aiResultInsertion']" routerLinkActive="active">
            <mat-icon class="material-icons">insert_chart_outlined</mat-icon>
            <span class="ml-3">{{'STRUCTURED_DATA.AI_RESULT_INSERTION' | translate}}</span></button>
    </div>

    <div class="scenarioGeneration">
        <button mat-raised-button  [routerLink]="['/structuredData/scenarioGenerationTerrAttack']" routerLinkActive="active">
            <mat-icon class="material-icons">description</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
    </div>
    <div>
        <button mat-raised-button  [routerLink]="['/structuredData/lmsQuizRsults']" routerLinkActive="active">
            <mat-icon class="material-icons">assessment</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS' | translate}}</span></button>
    </div>
    <div>
        <button mat-raised-button  [routerLink]="['/structuredData/vrScenarios']" routerLinkActive="active">
            <mat-icon class="material-icons">headset_mic</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.VR_SCENARIOS.TITLE' | translate}}</span></button>
    </div>
    <div class="reportGenerationLocationPrediction">
        <button mat-raised-button  [routerLink]="['/structuredData/reportGenerationLocationPrediction']" routerLinkActive="active">
            <mat-icon class="material-icons">assessment</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LOCATION_PREDICTION' | translate}}</span></button>
    </div>
    <div class="reportGenerationAnomalyDetection">
        <button mat-raised-button  [routerLink]="['/structuredData/reportGenerationAnomalyDetection']" routerLinkActive="active">
            <mat-icon class="material-icons">assessment</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_ANOMALY_DETECTION' | translate}}</span></button>
    </div>
    <div class="reportGenerationAiResults">
        <button mat-raised-button  [routerLink]="['/structuredData/reportGenerationAiResults']" routerLinkActive="active">
            <mat-icon class="material-icons">assessment</mat-icon>
            <span class="ml-5">{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_AI_RESULTS' | translate}}</span></button>
    </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>