import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { EMOTIONAL_ASSESSMENT, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { AddEmotionalAssessmentComponent } from '../add-emotional-assessment/add-emotional-assessment.component';

@Component({
  selector: 'app-emotional-assessment',
  templateUrl: './emotional-assessment.component.html',
  styleUrls: ['./emotional-assessment.component.css']
})
export class EmotionalAssessmentComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: EMOTIONAL_ASSESSMENT[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['userId', 'interviewId', 'organizerInstitution', 'participantFullName','details', 'actions'];
  dataSource: MatTableDataSource<EMOTIONAL_ASSESSMENT> = new MatTableDataSource();
  userId: string ='';
  interviewId: string ='';
  timestamp: string ='';
  organizerInstitution: string ='';
  participantFullName: string ='';
  angerGrade: string ='';
  calmGrade: string ='';
  trustGrade: string ='';
  disgustGrade: string ='';
  fearGrade: string ='';
  happinessGrade: string ='';
  sadnessGrade: string ='';
  surpriseGrade: string ='';
  interestGrade: string ='';
  accuracy: string ='';

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    if(this.gameAndDataPath === 'interrogation/structuredData'){
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
        { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/structuredData'},
        { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
        { name: 'STRUCTURED_DATA.EMOTIONAL_ASSESSMENT', location: '/structuredData/emotionalAssessment' },
      ]);
      this.interactionService.setSelectedGame("interrogation");
    }else{
      this.router.navigate(['/home']);
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
      ]);
    }
    this.getEmotionalAssessment();
    this.isLoading = false;
  }
  
  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

openSnackBar(notificationMessage: string, type: string, duration: number) {
  this._snackBar.open(notificationMessage, 'X', {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: type,
    duration: duration,
  });
}

getEmotionalAssessment(){
  this.isLoading = true;
  let backend: boolean = true;
  //Fetch to backend
  if (backend) {
    this.sdmDataService.getEmotionalAssessment().subscribe(
      (res) => {
        this.dataSource.data = res;
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = res.count;
        this.isLoading = false;
      },
      (error: any) => {
        if (error.error.statusDescription == "user_have_no_permission") {
          this.openSnackBar(
            this.translate.instant("LOGIN.UNAUTHORIZED"),
            'notif-error',
            4000
          );
        }
      }
    );
  }
  this.isLoading = false;
}

addEmotionalAssessment(){
  this.isLoading = true;
  this.dialog
    .open(AddEmotionalAssessmentComponent, {
      data: {},
    })
    .afterClosed()
    .subscribe((val: EmotionalAssessmentComponent) => {
      this.getEmotionalAssessment();
    });
    this.isLoading = false;
}


openDeleteDialog(userId:any, timestamp:any, interviewId:any, organizerInstitution:any, participantFullName:any, angerGrade:any, calmGrade:any, trustGrade:any, disgustGrade:any, fearGrade:any, happinessGrade:any, sadnessGrade:any, surpriseGrade:any, interestGrade:any, accuracy:any){
  this.isLoading = true;
  this.interactionService.setElementFromWhereToDelete('emotional');
  this.interactionService.setUserId(userId);
  this.interactionService.setTimestamp(timestamp);
  this.interactionService.setInterviewId(interviewId);
  this.interactionService.setOrganizerInstitution(organizerInstitution);
  this.interactionService.setParticipantFullName(participantFullName);
  this.interactionService.setAngerGrade(angerGrade);
  this.interactionService.setCalmGrade(calmGrade);
  this.interactionService.setTrustGrade(trustGrade);
  this.interactionService.setDisgustGrade(disgustGrade);
  this.interactionService.setFearGrade(fearGrade);
  this.interactionService.setHappinessGrade(happinessGrade);
  this.interactionService.setSadnessGrade(sadnessGrade);
  this.interactionService.setSurpriseGrade(surpriseGrade);
  this.interactionService.setInterestGrade(interestGrade);
  this.interactionService.setAccuracy(accuracy);
  this.dialog
  .open(DeleteScenarioComponent, {
    data: {},
  })
  .afterClosed()
  .subscribe((val: EmotionalAssessmentComponent) => {
    this.getEmotionalAssessment();
  });
  this.isLoading = false;
}
}

