import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { ASSESSMENT_REUSLTS, OBJECT_CENTROIDS, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { AssessmentResultsComponent } from '../assessment-results/assessment-results.component';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';

@Component({
  selector: 'app-object-centroids',
  templateUrl: './object-centroids.component.html',
  styleUrls: ['./object-centroids.component.css']
})
export class ObjectCentroidsComponent implements OnInit {
  
  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: OBJECT_CENTROIDS[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['userId', 'timestamp', 'scene', 'details', 'actions'];
  dataSource: MatTableDataSource<OBJECT_CENTROIDS> = new MatTableDataSource();
  playerId: string ='';
  timestamp: string ='';
  data: any;
  gameType:string ='';


  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    switch(this.gameAndDataPath){
      case 'csi/structuredData': {
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
        { name: 'HOME.GAMES_TITLES.CSI', location:'/structuredData'},
        { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
        { name: 'STRUCTURED_DATA.OBJECT_CENTROIDS', location: '/structuredData/objectCentroids' },
      ]);
      this.interactionService.setSelectedGame("csi");
      break;
    } case 'carAccident/structuredData':{
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/home' },
        { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/structuredData'},
        { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
        { name: 'STRUCTURED_DATA.OBJECT_CENTROIDS', location: '/structuredData/objectCentroids' },
      ]);
      this.interactionService.setSelectedGame("car_accident");
      break;
     }
        //AFTER REFREH THIS WILL APEAR 
        default:{
          this.router.navigate(['/home']);
          this.interactionService.setNavLocation([
            { name: 'HEADER.HOME', location: '/home' },
          ]);
          break;
        }
    }
    this.gameType = this.interactionService.getSelectedGame();
    this.getObjectCentroids(this.gameType);
    this.isLoading = false;
  }
  
  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

openSnackBar(notificationMessage: string, type: string, duration: number) {
  this._snackBar.open(notificationMessage, 'X', {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    panelClass: type,
    duration: duration,
  });
}

getObjectCentroids(gameType: any){
  this.isLoading = true;
  let backend: boolean = true;
  //Fetch to backend
  if (backend) {
    this.sdmDataService.getObjectCentroids(gameType).subscribe(
      (res) => {
        this.dataSource.data = res;
        console.log(this.dataSource.data);
        this.paginator!.pageIndex = this.currentPage;
        this.paginator!.length = res.count;
        this.isLoading = false;
      },
      (error: any) => {
        if (error.error.statusDescription == "user_have_no_permission") {
          this.openSnackBar(
            this.translate.instant("LOGIN.UNAUTHORIZED"),
            'notif-error',
            4000
          );
        }
      }
    );
  }
  this.isLoading = false;
}

openDeleteDialog(id:any){
  this.isLoading = true;
  this.interactionService.setElementFromWhereToDelete('objectCentroids');
  this.interactionService.setRecordId(id);
  this.dialog
  .open(DeleteScenarioComponent, {
    data: {},
  })
  .afterClosed()
  .subscribe((val: ObjectCentroidsComponent) => {
    this.getObjectCentroids(this.gameType);
  });
  this.isLoading = false;
}
}