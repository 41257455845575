

<div
  *ngIf="isLoading === true"
  class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%]  bg-white opacity-75 z-50">
<div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
  <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
</div>
</div>


<div class="h-full flex-auto flex bg-white">

  <div class="uData">
    <app-header
    [ngClass]="isLoading === true ? 'fixed w-full' : 'fixed w-full z-50'"
  ></app-header></div>

  <!-- <app-header class="fixed w-full z-50"></app-header> -->
  <div class="w-full h-full items-center flex-auto 3xl:mt-[208px] 4k:mt-[256px]" style="background-image: linear-gradient(45deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(135deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(0deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(90deg, rgb(3, 20, 35), rgb(76, 9, 223)) !important;">
    <div class="self-center justify-center h-full lg:flex lg:flex-col 3xl:text-3xl 4k:text-5xl">
      <div class="flex-col w-auto py-[6%] mt-[1%]">
        <div class="w-full justify-center text-center text-base py-4">
                  <div class="profile-header">
              <button class="back-button" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
                <div class="back_text">{{"PROFILE.BACK_ARROW"|translate}}</div>
              </button>
            </div>
            <h2 class="page-title">
              {{"PROFILE.MY_PROFILE"|translate}}
            </h2>
          <!-- Rest of your content -->
          
            <img class="mx-auto w-[5%] mt-[-0.5%]" src="assets/icons/profile.png" alt="{{ 'HEADER.LOGO_ALT' | translate }}" />
            <p>{{profileData.userName}}</p>
          <!-- <p class="font-italic"> {{profileData.email}}</p> -->
          <hr class="h-px w-[30%] mx-auto bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
        <div class="w-full justify-center text-center text-base py-2" >
         
            <app-user-info-card></app-user-info-card>
         
          <!-- <app-change-password></app-change-password> -->
        </div>
      </div>
    </div>
  </div>
  
</div>

<app-footer class="fixed bottom-0 w-full"></app-footer>







