<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="setBackgrund">
    <div class="h-screen flex flex-col">
      <app-header class="fixed w-full z-50"></app-header>
    <div class="wrapper-pilot">
        <mat-form-field appearance="standard">
          <input matInput (keyup)="applyFilter($event)" placeholder="{{'SEARCH_BOX.PLACEHOLDER' | translate}}" #input>
        </mat-form-field>
        <div id="title">
          <h3>{{'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_CSI_FINAL_RESULTS' | translate}}</h3>
          <h5 *ngIf="userProfile?.permission && userProfile?.permission !== 'WRITE'">* {{"READ_PERMISSION" | translate}}</h5>
        </div>
        <div class="functionalities">
          <button (click)="getAllReports()" matTooltip="{{'TOOLTIP.REFRESH' | translate}}"
            matTooltipPosition="above"><mat-icon class="self-center mr-6" class="actionIcon">refresh</mat-icon></button>
              <hr>
        </div>
        <div class="npc-moves-scenario-list">
          <table mat-table [dataSource]="dataSource" id="npc-moves-scenario-table">
            <mat-spinner *ngIf="isLoading" [diameter]="spinnerSize" class="mx-auto"></mat-spinner>
            <ng-container class="pt-[10%] text-3xl" matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>
                  USERNAME
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.username }}
              </td>
            </ng-container>
  
            <ng-container class="pt-[10%] text-3xl" matColumnDef="datetime">
              <th mat-header-cell *matHeaderCellDef>
               DATETIME
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.datetime }}
              </td>
            </ng-container>
        
            <ng-container class="pt-[10%] text-3xl" matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                {{"SCENARIO_LIST.ACTIONS" | translate | uppercase}}
              </th>
              <td mat-cell *matCellDef="let element" >
                <div class="flex justify-around" >
                  <button mat-button matTooltip="{{'TOOLTIP.GENERATE_REPORT' | translate}}" matTooltipPosition="above"
                  (click)="generateReportDialog(element)"> Generate Report
                </button>
  
                  <button *ngIf = "userProfile.permission === 'WRITE'"[disabled]="userProfile.permission !== 'WRITE'" mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                    (click)="openDeleteDialog(element.id)">
                    <mat-icon class="self-center mr-6" class="actionIcon">delete</mat-icon>
                  </button>
                  <span class="disabled-wrapper">
                    <button mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                    (click)="openDeleteDialog(element.id)" *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'">
                    <mat-icon class="self-center mr-6" class="actionIcon">delete</mat-icon>
                  </button>
                  </span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">{{"SEARCH_BOX.NO_MATCH" | translate}}</td>
            </tr>
          </table>
          <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
      <app-csi-reports-sidenav></app-csi-reports-sidenav>
      <app-footer class="fixed bottom-0 w-full"></app-footer>
    </div>
  </div>