import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { LOCATION_PREDICTION, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { LocationPredictionComponent } from '../location-prediction/location-prediction.component';

import { ReportVizualizationLocationPredictionComponent } from '../report-vizualization-location-prediction/report-vizualization-location-prediction.component';

@Component({
  selector: 'app-report-location-prediction',
  templateUrl: './report-location-prediction.component.html',
  styleUrls: ['./report-location-prediction.component.css']
})
export class ReportLocationPredictionComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: LOCATION_PREDICTION[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['sessionId', 'username', 'timestamp', 'actions'];
  dataSource: MatTableDataSource<LOCATION_PREDICTION> = new MatTableDataSource();
  data: Map<string, object> = new Map;
  public role: string = '';
  gameType: string = '';

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.gameType = this.interactionService.getSelectedGame();
    this.userService.getLoggedInUserData().subscribe((res) => {
      this.userProfile = res;
      this.role = res.role;
      this.interactionService.setUserProfile(res);
    }, (err: any) => {
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    if (this.gameType === 'terroristAttack') {
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
        { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location: '/homePilot' },
        { name: 'STRUCTURED_DATA.REPORTS_TERRORIST_ATTACK', location: '/reportsTerroristAttack' },
        { name: 'STRUCTURED_DATA.LOCATION_PREDICTION', location: '/reportLocationPrediction' },
      ]);
      this.interactionService.setSelectedGame("terroristAttack");
    } else {
      this.router.navigate(['/homePilot']);
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
      ]);
    }
    this.getAllLocationPrediction();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  getAllLocationPrediction() {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.getAllLocationPrediction().subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  openDeleteDialog(id: any) {
    this.isLoading = true;
    this.interactionService.setElementFromWhereToDelete('locationPrediction');
    this.interactionService.setRecordId(id);
    this.dialog
      .open(DeleteScenarioComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: LocationPredictionComponent) => {
        this.getAllLocationPrediction();
      });
    this.isLoading = false;
  }

  generateReportDialog(json: any) {
    this.isLoading = true;
    this.interactionService.setJson(json);
    this.dialog
      .open(ReportVizualizationLocationPredictionComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: ReportLocationPredictionComponent) => {
        // this.getAllRealTimeRecordQuest();
      });
    this.isLoading = false;
  }
}