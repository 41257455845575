import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-report-vizualization-interrogation-summary',
  templateUrl: './report-vizualization-interrogation-summary.component.html',
  styleUrls: ['./report-vizualization-interrogation-summary.component.css']
})
export class ReportVizualizationInterrogationSummaryComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';

  constructor(
    public dialogRef: MatDialogRef<ReportVizualizationInterrogationSummaryComponent>, 
    private interactionService: InteractionService
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
    console.log(this.data);
    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }

  closeInvoice() {
    this.dialogRef.close();
  }
  formatTimestamp(timestamp: string | null | undefined): string {
    // Return 'null' if the timestamp is empty or null
    if (!timestamp) {
        return 'null';
    }
    // Format the timestamp if it exists
    return new Date(timestamp).toLocaleString();
}

  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;
    // Step 1: Add the background image using the base64 string
    doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, 210, 297); // Full page background

    // Title text
    const title = 'Interrogation Summary Report';

    // Calculate the width of the title
    const titleWidth = doc.getTextWidth(title);

    // Calculate x position for center alignment
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

     // Add logo (base64 image)
  
     doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed

    // Add report title with decreased font size and bold
    doc.setFontSize(14); // Smaller font size for title
    doc.setFont('bold'); // Set font to bold
    doc.setTextColor(255, 255, 255); // Set title color to white for better contrast
    doc.text(title, x, 40); // Adjust y position to accommodate logo

    // Reset to normal font and set text color
    doc.setFont('normal'); // Reset to normal font
    doc.setFontSize(9); // Adjust font size for user info
    doc.setTextColor(255, 255, 255); // Set text color to white for user info
    doc.text('User ID: ' + this.data.userId, 10, 50);
    doc.text('Player ID: ' + this.data.playerId, 10, 60);
    doc.text('Participant Full Name: ' + this.data.participantFullName, 10, 70);
    doc.text('Interrogation ID: ' + this.data.interrogationId, 10, 80);
    doc.text('Game Start Timestamp: ' + this.formatTimestamp(this.data.gameStartTimestamp), 10, 90);
    doc.text('Interrogation Start Timestamp: ' + this.formatTimestamp(this.data.interrogationStartTimestamp), 10, 100);
    doc.text('Interrogation End Timestamp: ' + this.formatTimestamp(this.data.interrogationEndTimestamp), 10, 110);
    doc.text('Confession Timestamp: ' + this.formatTimestamp(this.data.confessionTimestamp), 10, 120);
    doc.text('Game End Timestamp: ' + this.formatTimestamp(this.data.gameEndTimestamp), 10, 130);
    doc.text('Multiple Question Ratio: ' + this.data.multipleQuestionRatio, 10, 140);

    // Save the generated PDF
    doc.save('interrogation_summary_invoice.pdf');
}

}
