<div class="invoice-container">
<div class="invoice" #invoice>
    <button class="close-button" (click)="closeInvoice()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2>Location Prediction Report</h2>
    <p><strong>Session ID:</strong> {{ data.sessionId }}</p>
    <p><strong>Username:</strong> {{ data.username }}</p>
    <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
    <p><strong>Avatar ID:</strong> {{ data.avatarId }}</p>
    <p><strong>Avatar Positon X:</strong> {{ data.avatarPositionX }}</p>
    <p><strong>Avatar Positon Y:</strong> {{ data.avatarPositionY }}</p>
    <p><strong>Avatar Positon Z:</strong> {{ data.avatarPositionZ }}</p>
  </div>
</div>