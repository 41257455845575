import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { InteractionService } from 'src/app/services/interaction.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  constructor(public dialogRef: MatDialogRef<LogoutComponent>,private router:Router, private interactionService: InteractionService ) { }

  ngOnInit(): void {
  }
  onClick(){
    this.dialogRef.close();

  }
  onLogout(){
    this.interactionService.resetVariables();
    this.dialogRef.close();
    this.isLoading = true;
    this.router.navigateByUrl("/login");
  }

}
