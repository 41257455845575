import { Component } from '@angular/core';

@Component({
  selector: 'app-lawgame-app',
  templateUrl: './lawgame-app.component.html',
  styleUrls: ['./lawgame-app.component.css']
})
export class LawgameAppComponent {

}
