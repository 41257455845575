<div class="invoice-container">
 <div class="invoice" #invoice>
    <button class="close-button" #closeButton (click)="closeInvoice()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2>AI Result Report</h2>
    <p><strong>Session ID:</strong> {{ data.sessionId }}</p>
    <p><strong>Avatar ID:</strong> {{ data.avatarId }}</p>
    <p><strong>Timestamp:</strong> {{ data.timestamp }}</p>
    <p><strong>Action ID:</strong> {{ data.actionId }}</p>
    <p><strong>Prediction:</strong> {{ data.prediction }}</p>
    <p><strong>Explanation:</strong> {{ data.explanation }}</p>
  </div>
</div>
