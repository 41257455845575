import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-reports-interrogation',
  templateUrl: './reports-interrogation.component.html',
  styleUrls: ['./reports-interrogation.component.css']
})
export class ReportsInterrogationComponent implements OnInit {
  
  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService
  ) {}

    selectedPath: string = "";

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.checkToken().subscribe((res) =>{
      console.log("response: "+ res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.selectedPath = this.interactionService.getSelectedGame();
    this.interactionService.setNavLocation([
      { name: 'HEADER.HOME', location: '/homePilot' },
      { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/homePilot'},
      { name: 'STRUCTURED_DATA.REPORTS_INTERRGATION', location: '/reportsInterrogation' },
    ]);
    this.isLoading = false;
  }
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
}