import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { InteractionService } from 'src/app/services/interaction.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-report-visualization',
  templateUrl: './report-visualization.component.html',
  styleUrls: ['./report-visualization.component.css']
})
export class ReportVisualizationComponent implements OnInit {
  username:string='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  data:any;
  backgroundImageBase64:string='';
  logoBase64:string='';
  constructor(
    public dialogRef: MatDialogRef<ReportVisualizationComponent>, 
    private interactionService: InteractionService
  ) { }

  ngOnInit(): void {
    this.data=this.interactionService.getJson();
    this.backgroundImageBase64 = this.interactionService.getBackgroundImageBase64();
    this.logoBase64 = this.interactionService.getLogoBase64();
  }
  photoError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/default.jpg';
  }

  
  closeInvoice() {
    this.dialogRef.close();
  }

  downloadPDF(): void {
    //const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const doc = new jsPDF() as any;
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm

    // Function to add background image to the current page
    const addBackgroundImage = () => {
        doc.addImage(this.backgroundImageBase64, 'PNG', 0, 0, pageWidth, pageHeight);
    };

    // Add the background image for the first page BEFORE adding content
    addBackgroundImage();

    // Title text
    const title = 'CSI final report for ' + this.data.username;

    // Calculate the width of the title
    const titleWidth = doc.getTextWidth(title);

    // Calculate x position for center alignment
    const x = (doc.internal.pageSize.getWidth() - titleWidth) / 2;

    // Add logo (base64 image)
    doc.addImage(this.logoBase64, 'PNG', 10, 10, 60, 20); // Adjust the x, y, width, and height as needed

    // Add report title with decreased font size and bold
    doc.setFontSize(12); // Smaller font size for title
    doc.setFont('bold'); // Set font to bold
    doc.setTextColor(255, 255, 255); // Set title color to white for better contrast
    doc.text(title, x, 40); // Adjust y position to accommodate logo

    // Reset to normal font and set text color
    doc.setFont('normal'); // Reset to normal font
    doc.setFontSize(10); // Adjust font size for user info
    doc.setTextColor(255, 255, 255); // Set text color to white for user info

    let positionY = 50;

    // Add user info (Username, Date, Module Name, etc.)
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255); 
    doc.text('Username: ' + this.data.username, 10, positionY);
    positionY += 10;
    doc.text('Date: ' + this.data.datetime, 10, positionY);
    positionY += 10;
    doc.text('Module Name: ' + this.data.moduleName, 10, positionY);
    positionY += 10;
    doc.text('Scenario URL: ' + this.data.scenarioUrl, 10, positionY);
    positionY += 10;
    doc.text('Scenario Max Points: ' + this.data.scenarioMaxPoints, 10, positionY);
    positionY += 10;
    doc.text('Final Score: ' + this.data.finalScore, 10, positionY);
    positionY += 10;
    doc.text('Trainer Name: ' + this.data.trainerName, 10, positionY);
    positionY += 10;
    doc.text('Evaluator Name: ' + this.data.evaluatorName, 10, positionY);
    positionY += 10; // Add some spacing before the next section

    const procedureTitle = 'Procedure';
    const procedureTitleWidth = doc.getTextWidth(procedureTitle);
    const procedureCentral = (doc.internal.pageSize.getWidth() - procedureTitleWidth) / 2;

    doc.setTextColor(255, 255, 255);
    doc.setFontSize(10);
    doc.text(procedureTitle, procedureCentral, positionY);
    positionY += 10;
    doc.setFontSize(10);
    doc.text('Allocated Points: ' + this.data.procedure.AllocatedPoints, 10, positionY);
    positionY += 10;
    doc.text('Score: ' + this.data.procedure.Score, 10, positionY);
    positionY += 10;

    // Procedure Actions Table
    autoTable(doc, {
        head: [['Action', 'Max Points', 'Points']],
        body: this.data.procedure.Actions.map((action: any) => [
            action.Action, 
            action.MaxPoints.toString(), 
            action.Points.toString()
        ]),
        startY: positionY,
        styles: {
          fontSize: 7, // Smaller font size for table content
          textColor: [255, 255, 255], // Set table text color to white
          fillColor: undefined, // Set to null to match the background
          halign: 'center', // Center align the cell text
        },
        headStyles: {
          fillColor: [60, 25, 138], // Header background color
          textColor: [255, 255, 255], // Set header text color to white
          fontSize: 7, // Smaller font size for headers
          halign: 'center', // Center align the header text
        },
        theme: 'grid',
        didDrawPage: (data:any) => {
            // Add the background image BEFORE content on every new page
            if (data.pageNumber > 1) {
                addBackgroundImage();
            }
        },
    });
    positionY = doc.autoTable.previous.finalY + 10; // Update positionY after table

    // Check if positionY is greater than the page height, if so, add a new page
    if (positionY > pageHeight - 40) {
        doc.addPage(); // Add new page
        addBackgroundImage(); // Add the background to the new page
        positionY = 20; // Reset positionY for the new page
    }

    // Evidence Gathering Section
    const evidenceGatheringTitle = 'Evidence Gathering';
    const evidenceGatheringWidth = doc.getTextWidth(evidenceGatheringTitle);
    const evidenceGatheringCentral = (doc.internal.pageSize.getWidth() - evidenceGatheringWidth) / 2;
    doc.setFontSize(10);
    doc.text(evidenceGatheringTitle, evidenceGatheringCentral, positionY);
    positionY += 10;
    doc.setFontSize(10);
    doc.text('Allocated Points: ' + this.data.evidenceGathering.AllocatedPoints, 10, positionY);
    positionY += 10;
    doc.text('Score: ' + this.data.evidenceGathering.Score, 10, positionY);
    positionY += 10;

    // Evidence Gathering Actions Table
    autoTable(doc, {
        head: [
            ['Action', 'Collected', 'Photo', 'Identified', 'Marked', 'Penalty', 'Max Points', 'Points']
        ],
        body: this.data.evidenceGathering.Actions.map((action: any) => [
            action.Action,
            action.Collected.toString(),
            action.Photo.toString(),
            action.Identified.toString(),
            action.Marked.toString(),
            action.Penalty.toString(),
            action.MaxPoints.toString(),
            action.Points.toString()
        ]),
        startY: positionY,
        theme: 'grid',
        styles: {
          fontSize: 7, // Smaller font size for table content
          textColor: [255, 255, 255], // Set table text color to white
          fillColor: undefined, // Set to null to match the background
          halign: 'center', // Center align the cell text
        },
        headStyles: {
          fillColor: [60, 25, 138], // Header background color
          textColor: [255, 255, 255], // Set header text color to white
          fontSize: 7, // Smaller font size for headers
          halign: 'center', // Center align the header text
        },
        didDrawPage: (data:any) => {
            // Add the background image BEFORE content on every new page
            if (data.pageNumber > 1) {
                addBackgroundImage();
            }
        },
    });
    positionY = doc.autoTable.previous.finalY + 10; // Update positionY after table

    // Check if positionY is greater than the page height, if so, add a new page
    if (positionY > pageHeight - 40) {
        doc.addPage(); // Add new page
        addBackgroundImage(); // Add the background to the new page
        positionY = 20; // Reset positionY for the new page
    }

   // Photos Section
const photosTitle = 'Photos';
const photosWidth = doc.getTextWidth(photosTitle);
const photosCentral = (doc.internal.pageSize.getWidth() - photosWidth) / 2;
doc.setFontSize(10);
doc.text(photosTitle, photosCentral, positionY);
positionY += 10;

// Check if there are any photos available
if (this.data.photos && this.data.photos.length > 0) {
    // Create an array of promises to load all images
    const imagePromises = this.data.photos.map((photo: any) => {
        return new Promise<void>((resolve) => {
            const img = new Image();
            img.src = photo.URL; // Load the image URL (base64)

            img.onload = () => {
                const photoDescription = photo.Description || '';
                doc.text(photoDescription, 10, positionY);
                positionY += 10;

                const maxImageWidth = 60; // Set a maximum width for the image
                const pageWidth = doc.internal.pageSize.getWidth(); // Get the PDF page width

                // Dynamically calculate the height while maintaining aspect ratio
                const imageWidth = img.width > maxImageWidth ? maxImageWidth : img.width;
                const imageHeight = (img.height / img.width) * imageWidth;

                // Calculate X position to center the image
                const xPosition = (pageWidth - imageWidth) / 2;

                // Add the image to the PDF
                doc.addImage(photo.URL, 'PNG', xPosition, positionY, imageWidth, imageHeight);
                positionY += imageHeight + 10; // Move the Y position for the next image

                // Check if we need a new page
                if (positionY > pageHeight - 40) {
                    doc.addPage(); // Add a new page
                    addBackgroundImage(); // Add the background to the new page BEFORE content
                    positionY = 20; // Reset positionY for the new page
                }

                resolve(); // Resolve the promise once the image is loaded
            };
        });
    });

    // Wait for all images to load before continuing with PDF generation
    Promise.all(imagePromises).then(() => {
        // Save the document after all images are loaded
        doc.save('csi_final_report.pdf');
    });
} else {
    // If no photos are available, display a "No image available" message
    doc.setFontSize(10);
    doc.text('No image available', 10, positionY);
    positionY += 10;

    // Save the document when there are no photos
    doc.save('csi_final_report.pdf');
}
  }


}  