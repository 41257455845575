import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, catchError, Observable, observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/token/token.service';

const API_URL = environment.lawgameCommunication;

export interface GeneralFileType {
    parent: string;
    id: string;
    type: number;
    hardLinkCount: number;
    size: number;
    rawListing: string;
    user: string,
    group: string,
    name: string,
    link: string,
    directory: boolean,
    file: boolean,
    timestamp: string,
    valid: boolean,
    symbolicLink: boolean,
    unknown: boolean
}

@Injectable({
    providedIn: 'root',
})
export class FileDataService {
    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
    private authService: AuthService
    ) { }

    private static handleError(error: HttpErrorResponse): any {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend retuned code ${error.status}, ` + `body was: ${error.error}`
            );
        }
        return throwError(error);
    }

    getScenarios(): Observable<any> {
        return this.http
            .get<any>(API_URL + 'sdmc/all')
            .pipe(catchError(FileDataService.handleError));
    }

    getParent(fullPath:string): any{
        const body = {
            path: fullPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/getParent', body)
            .pipe(catchError(FileDataService.handleError));
    }
    listFiles(): Observable<any> {
        return this.http
            .get<any>(API_URL + 'udmc/list')
            .pipe(catchError(FileDataService.handleError));
    }

    listByPath(currentPath:string): Observable<any> {
        const body = {
            path: currentPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/listByPath', body)
            .pipe(catchError(FileDataService.handleError));
    }


    listFilesCarAccidentByElementAndPath(element: string, currentPath:string): Observable<any> {
        const body = {
            elementGame: element,
            path: currentPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/listCarAccidentFiles', body)
            .pipe(catchError(FileDataService.handleError));
    }

    listFilesCsiByElementAndPath(element: string, currentPath:string): Observable<any> {
        const body = {
            elementGame: element,
            path: currentPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/listCsiFiles', body)
            .pipe(catchError(FileDataService.handleError));
    }
    listFilesInterrogationByElementAndPath(element: string, currentPath:string): Observable<any> {
        const body = {
            elementGame: element,
            path: currentPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/listInterrogationFiles', body)
            .pipe(catchError(FileDataService.handleError));
    }
    
    listFilesTerrAttackByElementAndPath(element: string, currentPath:string): Observable<any> {
        const body = {
            elementGame: element,
            path: currentPath
        }
        return this.http
            .post<any>(API_URL + 'udmc/listTerrAttackFiles', body)
            .pipe(catchError(FileDataService.handleError));
    }
    pushFileToStorage(currentPath: string, file: File): any {
        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();


        const data: FormData = new FormData();
        data.append('file', file, file.name);
        data.append('path', currentPath);
        const body = {
            file: file,
            path: currentPath
        }
        let headers = new Headers();
        //headers.append('Content-Type', 'multipart/form-data');
        //headers.set('Accept', 'application/json');

        //const newRequest = new HttpRequest('POST', API_URL+"unstructured/uploadFiles", data, {
        //headers: new HttpHeaders({ "Content-Type": " multipart/form-data; boundary=YourBoundaryOfChoiceHere" }),
        // headers: new HttpHeaders(
        //     { "Content-Type": "multipart/form-data",
        //     'Accept': 'application/json'

        //     },

        //     ),


        //reportProgress: true,
        // responseType: 'json'
        //});
        const xhr = new XMLHttpRequest()
        //TODO: catch also the refresh token
        xhr.onreadystatechange = (e) => {
            if (xhr.status === 200) {
                console.log('SUCCESS', xhr.responseText);
                return 'SUCCESS' + xhr.responseText;
            } else {
                console.warn('request_error');
                return 'request_error';
            }
        };
        xhr.open('POST', API_URL + "udmc/uploadFilesNew", true);
        if(token) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token );

        }
        xhr.send(data)
        return 'status ok ' + xhr.responseText;
        //return this.http.request(newRequest);
    }


    uploadFileForCarAccident(elementGame:string, currentPath: string, file: File): any {
        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();


        const data: FormData = new FormData();
        data.append('file', file, file.name);
        data.append('path', currentPath);
        data.append('elementGame', elementGame);
        const body = {
            elementGame:elementGame,
            file: file,
            path: currentPath
        }
        let headers = new Headers();
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
            if (xhr.status === 200) {

                console.log('SUCCESS', xhr.responseText);
                return 'SUCCESS' + xhr.responseText;
            } else {
                console.warn('request_error');
                return 'request_error';
            }
        };
        xhr.open('POST', API_URL + "udmc/uploadFileCarAccident", true);
        if(token) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token );

        }
        xhr.send(data)
        return 'status ok ' + xhr.responseText;
    }

    uploadFileForInterrogation(elementGame:string, currentPath: string, file: File): any {
        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();


        const data: FormData = new FormData();
        data.append('file', file, file.name);
        data.append('path', currentPath);
        data.append('elementGame', elementGame);
        const body = {
            elementGame:elementGame,
            file: file,
            path: currentPath
        }
        let headers = new Headers();
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
            if (xhr.status === 200) {

                console.log('SUCCESS', xhr.responseText);
                return 'SUCCESS' + xhr.responseText;
            } else {
                console.warn('request_error');
                return 'request_error';
            }
        };
        xhr.open('POST', API_URL + "udmc/uploadFileInterrogation", true);
        if(token) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token );

        }
        xhr.send(data)
        return 'status ok ' + xhr.responseText;
    }

    uploadFileForCsi(elementGame:string, currentPath: string, file: File): any {
        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();


        const data: FormData = new FormData();
        data.append('file', file, file.name);
        data.append('path', currentPath);
        data.append('elementGame', elementGame);
        const body = {
            elementGame:elementGame,
            file: file,
            path: currentPath
        }
        let headers = new Headers();
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
            if (xhr.status === 200) {

                console.log('SUCCESS', xhr.responseText);
                return 'SUCCESS' + xhr.responseText;
            } else {
                console.warn('request_error');
                return 'request_error';
            }
        };
        xhr.open('POST', API_URL + "udmc/uploadFileCsi", true);
        if(token) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token );

        }
        xhr.send(data)
        return 'status ok ' + xhr.responseText;
    }

    uploadFileForTerroristAttack(elementGame:string, currentPath: string, file: File): any {
        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();


        const data: FormData = new FormData();
        data.append('file', file, file.name);
        data.append('path', currentPath);
        data.append('elementGame', elementGame);
        const body = {
            elementGame:elementGame,
            file: file,
            path: currentPath
        }
        let headers = new Headers();
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = (e) => {
            if (xhr.status === 200) {

                console.log('SUCCESS', xhr.responseText);
                return 'SUCCESS' + xhr.responseText;
            } else {
                console.warn('request_error');
                return 'request_error';
            }
        };
        xhr.open('POST', API_URL + "udmc/uploadFileTerrAttack", true);
        if(token) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token );

        }
        xhr.send(data)
        return 'status ok ' + xhr.responseText;
    }


    

    downloadFile(currentPath: string): Observable<any> {
        const body = {
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/downloadFiles", body);
    }
    downloadFileForCarAccident(elementGame:string, currentPath: string): Observable<any> {
        const body = {
            elementGame: elementGame,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/downloadFilesCarAccident", body);
    }

    downloadFileForInterrogation(elementGame:string, currentPath: string): Observable<any> {
        const body = {
            elementGame: elementGame,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/downloadFilesInterrogation", body);
    }

    downloadFileForCsi(elementGame:string, currentPath: string): Observable<any> {
        const body = {
            elementGame: elementGame,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/downloadFilesCsi", body);
    }

    downloadFileForTerroristAttack(elementGame:string, currentPath: string): Observable<any> {
        const body = {
            elementGame: elementGame,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/downloadFilesTerrAttack", body);
    }


    deleteFromServer(currentPath: string, isDirectory:string ) : Observable<any>{
        const body = {
            path: currentPath,
            isDirectory: isDirectory
        }
        return this.http.post<any>(API_URL + "udmc/delete", body)
        .pipe(catchError(FileDataService.handleError));
    }

    
    deleteForCarAccidentByElementAndPath(elementGame: string, currentPath: string, isDirectory:string ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            isDirectory: isDirectory
        }
        return this.http.post<any>(API_URL + "udmc/deleteForCarAccident", body)
        .pipe(catchError(FileDataService.handleError));
    }
    
    deleteForInterrogationElementAndPath(elementGame:string, currentPath: string, isDirectory:string ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            isDirectory: isDirectory
        }
        return this.http.post<any>(API_URL + "udmc/deleteForInterrogation", body)
        .pipe(catchError(FileDataService.handleError));
    }
    
    deleteForCsiElementAndPath(elementGame:string, currentPath: string, isDirectory:string ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            isDirectory: isDirectory
        }
        return this.http.post<any>(API_URL + "udmc/deleteForCsi", body)
        .pipe(catchError(FileDataService.handleError));
    }
    
    deleteForTerrAttackElementAndPath(elementGame: string, currentPath: string, isDirectory:string ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            isDirectory: isDirectory
        }
        return this.http.post<any>(API_URL + "udmc/deleteForTerrAttack", body)
        .pipe(catchError(FileDataService.handleError));
    }

    renameForCarrAccidentByElementAndPath(elementGame: string, currentPath: string, newName:string, isDirectory:boolean ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            newName: newName,
            isDirectory: isDirectory        }
        return this.http.put<any>(API_URL + "udmc/renameForCarAccident", body)
        .pipe(catchError(FileDataService.handleError));
    }

    renameForInterrogationElementAndPath(elementGame: string, currentPath: string, newName:string, isDirectory:boolean ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            newName: newName,
            isDirectory: isDirectory        }
        return this.http.put<any>(API_URL + "udmc/renameForInterrogation", body)
        .pipe(catchError(FileDataService.handleError));
    }
    renameForCsiElementAndPath(elementGame: string, currentPath: string, newName:string, isDirectory:boolean ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            newName: newName,
            isDirectory: isDirectory        }
        return this.http.put<any>(API_URL + "udmc/renameForCsi", body)
        .pipe(catchError(FileDataService.handleError));
    }
    renameForTerrAttackElementAndPath(elementGame: string, currentPath: string, newName:string, isDirectory:boolean ) : Observable<any>{
        const body = {
            elementGame: elementGame,
            path: currentPath,
            newName: newName,
            isDirectory: isDirectory        }
        return this.http.put<any>(API_URL + "udmc/renameForTerroristAttack", body)
        .pipe(catchError(FileDataService.handleError));
    }
      createDirForCarAccidentByElementAndPath(elementName:string, currentPath: string) : Observable<any>{
        const body = {
            elementGame: elementName,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/createDirCarAccident", body)
        .pipe(catchError(FileDataService.handleError));
      }  
      createDirForCsiByElementAndPath(elementName:string, currentPath: string) : Observable<any>{
        const body = {
            elementGame: elementName,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/createDirCsi", body)
        .pipe(catchError(FileDataService.handleError));
      
      }  
      createDirForInterrogationByElementAndPath(elementName:string, currentPath: string) : Observable<any>{
        const body = {
            elementGame: elementName,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/createDirInterrogation", body)
        .pipe(catchError(FileDataService.handleError));
      
      }  
      createDirForTerrAttackByElementAndPath(elementName:string, currentPath: string) : Observable<any>{
        const body = {
            elementGame: elementName,
            path: currentPath
        }
        return this.http.post<any>(API_URL + "udmc/createDirTerrAttack", body)
        .pipe(catchError(FileDataService.handleError));
      
      }  
}



