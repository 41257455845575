import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { VICTIM_ON_CURB, SdmDataService, LMS_QUIZ_RESULTS } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { VictimOnCurbScenarioComponent } from '../victim-on-curb-scenario/victim-on-curb-scenario.component';
import { ReportVizualizationLmsQuizResultsComponent } from '../report-vizualization-lms-quiz-results/report-vizualization-lms-quiz-results.component';

@Component({
  selector: 'app-lms-quiz-results',
  templateUrl: './lms-quiz-results.component.html',
  styleUrls: ['./lms-quiz-results.component.css']
})
export class LmsQuizResultsComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: LMS_QUIZ_RESULTS[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['firstName', 'courseName', 'quizName', 'actions'];
  dataSource: MatTableDataSource<LMS_QUIZ_RESULTS> = new MatTableDataSource();
  playerId: string = '';
  timestamp: string = '';
  data: Map<string, object> = new Map;
  gameType: string = '';


  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res) => {
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any) => {
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    switch (this.gameAndDataPath) {
      case 'csi/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CSI', location: '/structuredData' },
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/structuredData/lmsQuizRsults' },
        ]);
        this.interactionService.setSelectedGame("csi");
        break;
      } case 'interrogation/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.INTERROGATION', location: '/structuredData' },
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/structuredData/lmsQuizRsults' },
        ]);
        this.interactionService.setSelectedGame("interrogation");
        break;
      } case 'carAccident/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location: '/structuredData' },
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/structuredData/lmsQuizRsults' },
        ]);
        this.interactionService.setSelectedGame("carAccident");
        break;
      } case 'terroristAttack/structuredData': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location: '/structuredData' },
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/structuredData/lmsQuizRsults' },
        ]);
        this.interactionService.setSelectedGame("terroristAttack");
        break;
      }
      //AFTER REFREH THIS WILL APEAR 
      default: {
        this.router.navigate(['/home']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
        ]);
        break;
      }
    }
    this.gameType = this.interactionService.getSelectedGame();
    this.getLmsQuizResults(this.gameType);

    // this.dataSource.filterPredicate = (data: LMS_QUIZ_RESULTS, filter: string) => {
    //   const filterStr = filter.trim().toLowerCase();
    //   const dataStr = (data.userData?.firstName || '').toLowerCase() + ' ' +
    //                    (data.courseName || '').toLowerCase() + ' ' +
    //                    (data.quizName || '').toLowerCase();
    //   console.log('Filter:', filterStr);
    //   console.log('Data:', dataStr);
    //   return dataStr.includes(filterStr);
    // };

    this.dataSource.filterPredicate = (data: LMS_QUIZ_RESULTS, filter: string) => {
      const lowerCaseFilter = filter.trim().toLowerCase();
      
      // Access nested properties like userData.first_name and other fields to filter
      const firstNameMatches = data.userData?.first_name?.toLowerCase().includes(lowerCaseFilter);
      const courseNameMatches = data.courseName?.toLowerCase().includes(lowerCaseFilter);
      const quizNameMatches = data.quizName?.toLowerCase().includes(lowerCaseFilter);
      
      // Add more conditions for other columns you want to filter on
      return firstNameMatches || courseNameMatches || quizNameMatches;
    };

    this.isLoading = false;
  }

  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
   // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  //   this.dataSource.filter = filterValue;
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
  

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  getLmsQuizResults(gameType: any) {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.getLmsQuizResults(gameType).subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  openDeleteDialog(id: any) {
    this.isLoading = true;
    this.interactionService.setElementFromWhereToDelete('lmsQuizData');
    this.interactionService.setRecordId(id);
    this.dialog
      .open(DeleteScenarioComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: LmsQuizResultsComponent) => {
        this.getLmsQuizResults(this.gameType);
      });
    this.isLoading = false;
  }

  generateReportDialog(json: any) {
    this.isLoading = true;
    this.interactionService.setJson(json);
    this.dialog
      .open(ReportVizualizationLmsQuizResultsComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: LmsQuizResultsComponent) => {
        // this.getAllRealTimeRecordQuest();
      });
    this.isLoading = false;
  }
}