import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSort } from '@angular/material/sort';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SCENARIOS, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { EditScenarioComponent } from '../edit-scenario/edit-scenario.component';
import { AddScenarioComponent } from '../add-scenario/add-scenario.component';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.css']
})

export class ScenariosComponent implements OnInit {

  name: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: SCENARIOS[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['name', 'description', 'category', 'type', 'actions'];
  dataSource: MatTableDataSource<SCENARIOS> = new MatTableDataSource();
  csiGameActive ='';

  @ViewChild('paginator')
  paginator!: MatPaginator;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private authService: AuthService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private http: HttpClient,
    public dialog: MatDialog,
    private userService: UserService

  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  languages = [
    { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
    { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
    { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
    { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
    { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
    { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
  ];
  language = this.interactionService.getLanguage();

  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    let path = this.interactionService.getStructuredDataOnePath();
    switch(path){
      case "carAccident/structuredData/scenario": {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.SCENARIOS', location: '/scenarios' },
        ]);
        break;
      }
      case "interrogation/structuredData/scenario":{
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.INTERROGATION', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.SCENARIOS', location: '/scenarios'}
        ]);
        break;
      }
      case "csi/structuredData/scenario":{
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.CSI', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.SCENARIOS', location: '/scenarios' }
        ]);
        break;
      }
      case "terroristAttack/structuredData/scenario":{
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/home' },
          { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location:'/structuredData'},
          { name: 'HOME.STRUCTURED_DATA', location: '/structuredData' },
          { name: 'STRUCTURED_DATA.SCENARIOS', location: '/scenarios' }
        ]);
        break;
      }
    }
    this.csiGameActive = this.interactionService.getStructuredDataOnePath();
    if(this.csiGameActive === "csi/structuredData/scenario"){
      this.getScenarios();
    }
    this.isLoading = false;

  }

  openDeleteDialog(name: any) {
    this.isLoading = true;
    let elementName = name;
    this.interactionService.setdeletedElementName(elementName);
    this.dialog.open(DeleteScenarioComponent);
    this.isLoading = false;
  }

  openRenameDialog(elementName: any, elementDescription: any, elementCategory: any, elementType: any) {
    this.isLoading = true;
    let currentName = elementName;
    this.interactionService.setCurrentDescription(elementDescription);
    this.interactionService.setCurrentCategory(elementCategory);
    this.interactionService.setCurrentType(elementType);
    this.interactionService.setElementName(currentName);
    this.dialog.open(EditScenarioComponent);
    this.isLoading = false;
  }

  getScenarios() {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.getScenarios().subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;

        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          } else {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant('INTERCEPTOR.SESSION_EXPIRED'),
              'notif-warning',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  goTo(prefix: string, scenario: SCENARIOS) {
    this.interactionService.setSelectedScenario(scenario);
    this.router.navigate([prefix]);
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }
  onScreenResize() {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }

  addScenario() {
    this.isLoading = true;
    this.dialog.open(AddScenarioComponent);
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
