import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { UserService } from 'src/app/services/user/user.service';

export interface NAMES {
  name: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public isBtnActive: string = '';
  public dataType: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  
  constructor(
    private router: Router,
    private interactionService: InteractionService,
    private userService: UserService,
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res) => {
      this.userProfile = res;
    }, (err: any) => {
      console.log("error" + err.error);
    })
    this.interactionService.setNavLocation([
      { name: 'HEADER.HOME', location: '/home' },
    ]);
    this.isLoading = false;
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  toggleBtn(name: string) {
    this.isBtnActive = name
    this.interactionService.setSelectedGame(this.isBtnActive);
  }

  toggleDataType(name: string) {
    this.dataType = name
    this.interactionService.setSelectedDataType(this.dataType);
    let game = this.interactionService.getSelectedGame();
    let dataType = this.interactionService.getSelectedDataType();
    let path = game + '/' + dataType;
    this.interactionService.setGameAndDataTypePath(path);
    switch (path) {
      case "carAccident/structuredData": {
        this.router.navigate(['structuredData']);
        break;
      }
      case "interrogation/structuredData": {
        this.router.navigate(['structuredData']);
        break;
      }
      case "csi/structuredData": {
        this.router.navigate(['structuredData']);
        break;
      }
      case "terroristAttack/structuredData": {
        this.router.navigate(['structuredData']);
        break;
      }
      case "carAccident/unstructuredData": {
        this.router.navigate(['unstructuredData']);
        break;
      }
      case "interrogation/unstructuredData": {
        this.router.navigate(['unstructuredData']);
        break;
      }
      case "csi/unstructuredData": {
        this.router.navigate(['unstructuredData']);
        break;
      }
      case "terroristAttack/unstructuredData": {
        this.router.navigate(['unstructuredData']);
        break;
      }
    }
  }

}
