import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { LMS_QUIZ_RESULTS, SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteScenarioComponent } from '../delete-scenario/delete-scenario.component';
import { LmsQuizResultsComponent } from '../lms-quiz-results/lms-quiz-results.component';
import { ReportVizualizationLmsQuizResultsComponent } from '../report-vizualization-lms-quiz-results/report-vizualization-lms-quiz-results.component';

@Component({
  selector: 'app-report-lms-quiz-results',
  templateUrl: './report-lms-quiz-results.component.html',
  styleUrls: ['./report-lms-quiz-results.component.css']
})
export class ReportLmsQuizResultsComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  page: number = 0;
  identifier: string = '';
  ELEMENT_DATA: LMS_QUIZ_RESULTS[] = [];
  totalRows = 100;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayColumns: string[] = ['firstName', 'courseName', 'quizName', 'actions'];
  dataSource: MatTableDataSource<LMS_QUIZ_RESULTS> = new MatTableDataSource();
  playerId: string = '';
  timestamp: string = '';
  data: Map<string, object> = new Map;
  gameType: string = '';


  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.gameType = this.interactionService.getSelectedGame();
    this.userService.getLoggedInUserData().subscribe((res) => {
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any) => {
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    switch (this.gameType) {
      case 'csi': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
          { name: 'HOME.GAMES_TITLES.CSI', location: '/homePilot' },
          { name: 'STRUCTURED_DATA.REPORTS_CSI', location: '/reportsCsi' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/reportLmsQuizResults' },
        ]);
        this.interactionService.setSelectedGame("csi");
        break;
      } case 'interrogation': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
          { name: 'HOME.GAMES_TITLES.INTERROGATION', location: '/homePilot' },
          { name: 'STRUCTURED_DATA.REPORTS_INTERRGATION', location: '/reportsInterrogation' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/reportLmsQuizResults' },
        ]);
        this.interactionService.setSelectedGame("interrogation");
        break;
      } case 'carAccident': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
          { name: 'HOME.GAMES_TITLES.CAR_ACCIDENT', location:'/homePilot'},
          { name: 'STRUCTURED_DATA.REPORTS_CAR_ACCIDENT', location: '/reportsCarAccident'},
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/reportLmsQuizResults' },
        ]);
        this.interactionService.setSelectedGame("carAccident");
        break;
      } case 'terroristAttack': {
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
          { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location: '/homePilot' },
          { name: 'STRUCTURED_DATA.REPORTS_TERRORIST_ATTACK', location: '/reportsTerroristAttack' },
          { name: 'STRUCTURED_DATA.REPORT_GENERATION.REPORT_GENERATION_LMS', location: '/reportLmsQuizResults' },
        ]);
        this.interactionService.setSelectedGame("terroristAttack");
        break;
      }
      //AFTER REFREH THIS WILL APEAR 
      default: {
        this.router.navigate(['/homePilot']);
        this.interactionService.setNavLocation([
          { name: 'HEADER.HOME', location: '/homePilot' },
        ]);
        break;
      }
    }
    this.gameType = this.interactionService.getSelectedGame();
    this.getLmsQuizResults(this.gameType);
    this.isLoading = false;
  }

  ngAfterViewInit() {
    // setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  getLmsQuizResults(gameType: any) {
    this.isLoading = true;
    let backend: boolean = true;
    //Fetch to backend
    if (backend) {
      this.sdmDataService.getLmsQuizResults(gameType).subscribe(
        (res) => {
          this.dataSource.data = res;
          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;
          this.isLoading = false;
        },
        (error: any) => {
          if (error.error.statusDescription == "user_have_no_permission") {
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          }
        }
      );
    }
    this.isLoading = false;
  }

  openDeleteDialog(id: any) {
    this.isLoading = true;
    this.interactionService.setElementFromWhereToDelete('lmsQuizData');
    this.interactionService.setRecordId(id);
    this.dialog
      .open(DeleteScenarioComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: LmsQuizResultsComponent) => {
        this.getLmsQuizResults(this.gameType);
      });
    this.isLoading = false;
  }

  generateReportDialog(json: any) {
    this.isLoading = true;
    this.interactionService.setJson(json);
    this.dialog
      .open(ReportVizualizationLmsQuizResultsComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((val: ReportLmsQuizResultsComponent) => {
        // this.getAllRealTimeRecordQuest();
      });
    this.isLoading = false;
  }
}