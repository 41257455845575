<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
  <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>
<div class="setBackgrund">
  <div class="h-screen flex flex-col">
    <app-header class="fixed w-full z-50"></app-header>
    <div class="wrapper">
      <mat-form-field appearance="standard">
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'SEARCH_BOX.PLACEHOLDER' | translate}}" #input>
      </mat-form-field>
      <div id="title">
        <h3>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.TITLE" | translate}}</h3>
        <h5 *ngIf="userProfile?.permission && userProfile?.permission !== 'WRITE'">* {{"READ_PERMISSION" | translate}}</h5>
      </div>
      <div class="functionalities">
        <button (click)="getEmotionalAssessment()" matTooltip="{{'TOOLTIP.REFRESH' | translate}}"
          matTooltipPosition="above"><mat-icon class="self-center mr-6" class="actionIcon">refresh</mat-icon></button>
          <button (click)="addEmotionalAssessment()"*ngIf = "userProfile.permission === 'WRITE'" [disabled]="userProfile.permission !== 'WRITE'" matTooltip="{{'TOOLTIP.ADD_SCENARIO' | translate}}"
          matTooltipPosition="above"><mat-icon class="self-center mr-6"
            class="actionIcon">add_circle_outline</mat-icon></button>
          <span class="disabled-wrapper">
            <button (click)="addEmotionalAssessment()" matTooltip="{{'TOOLTIP.ADD_SCENARIO' | translate}}"
               matTooltipPosition="above" *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'"><mat-icon class="self-center mr-6"
               class="actionIcon">add_circle_outline</mat-icon>
            </button>
          </span>
            <hr>
      </div>
      <div class="emotion-list">
        <table mat-table [dataSource]="dataSource" id="emotion-table">
          <mat-spinner *ngIf="isLoading" [diameter]="spinnerSize" class="mx-auto"></mat-spinner>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef>
              {{"INTERROGATION.EMOTIONAL_ASSESSMENT.USER_ID.TITLE" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.userId }}
            </td>
          </ng-container>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="interviewId">
            <th mat-header-cell *matHeaderCellDef>
              {{"INTERROGATION.EMOTIONAL_ASSESSMENT.INTERVIEW_ID.TITLE" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.interviewId }}
            </td>
          </ng-container>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="organizerInstitution">
            <th mat-header-cell *matHeaderCellDef>
              {{"INTERROGATION.EMOTIONAL_ASSESSMENT.ORGANIZER_INSTITUTION.TITLE" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.organizerInstitution }}
            </td>
          </ng-container>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="participantFullName">
            <th mat-header-cell *matHeaderCellDef>
              {{"INTERROGATION.EMOTIONAL_ASSESSMENT.PARTICIPANT_FULL_NAME.TITLE" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.participantFullName }}
            </td>
          </ng-container>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>
              {{"INTERROGATION.EMOTIONAL_ASSESSMENT.DETAILS.TITLE" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon class="self-center mr-6"
            class="actionIcon">view_list</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <body style="color: white; padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px;" >
                  
                  <!-- <div class="details-row">
                  <div class="details-column">
                    <div style="background-color: #3c1991; background-size: contain;">Timestamp</div>
                    <div style="background-color: #3c1991;">Anger Grade</div>
                    <div style="background-color: #3c1991;">Calm Grade</div>
                    <div style="background-color: #3c1991;">Trust Grade</div>
                    <div style="background-color: #3c1991;">Disgust Grade</div>
                    <div style="background-color: #3c1991;">Fear Grade</div>
                    <div style="background-color: #3c1991;">Happiness Grade</div>
                    <div style="background-color: #3c1991;">Sadness Grade</div>
                    <div style="background-color: #3c1991;">Surprise Grade</div>
                    <div style="background-color: #3c1991;">Interest Grade</div>
                    <div style="background-color: #3c1991;">Accuracy</div>
                  </div>
                  <div class="details-column">
                    <div>{{ element.timestamp }}</div>
                  <div>{{ element.angerGrade }}</div>
                  <div>{{ element.calmGrade }}</div>
                  <div>{{ element.trustGrade }}</div>
                  <div>{{ element.disgustGrade }}</div>
                  <div>{{ element.fearGrade }}</div>
                  <div>{{ element.happinessGrade }}</div>
                  <div>{{ element.sadnessGrade }}</div>
                  <div>{{ element.surpriseGrade }}</div>
                  <div>{{ element.interestGrade }}</div>
                  <div>{{ element.accuracy }}</div>
                  </div> -->

                  <div>{{"GAME_EVENTS.TIMESTAMP.TITLE" | translate}}:  {{ element.timestamp }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.ANGER_GRADE.TITLE" | translate}}:  {{ element.angerGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.CALM_GRADE.TITLE" | translate}}:  {{ element.calmGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.TRUST_GRADE.TITLE" | translate}}:  {{ element.trustGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.DISGUST_GRADE.TITLE" | translate}}:  {{ element.disgustGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.FEAR_GRADE.TITLE" | translate}}:  {{ element.fearGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.HAPPINESS_GRADE.TITLE" | translate}}:  {{ element.happinessGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.SADNESS_GRADE.TITLE" | translate}}:  {{ element.sadnessGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.SURPRISE_GRADE.TITLE" | translate}}:  {{ element.surpriseGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.INTEREST_GRADE.TITLE" | translate}}:  {{ element.interestGrade }}</div>
                  <div>{{"INTERROGATION.EMOTIONAL_ASSESSMENT.ACCURACY.TITLE" | translate}}:  {{ element.accuracy }}</div>
                <!-- </div> -->
                </body>
              

              </mat-menu>
            </td>
          </ng-container>
          <ng-container class="pt-[10%] text-3xl" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{"SCENARIO_LIST.ACTIONS" | translate | uppercase}}
            </th>
            <td mat-cell *matCellDef="let element" >
              <div class="flex justify-around" >
                
                <button *ngIf = "userProfile.permission === 'WRITE'"[disabled]="userProfile.permission !== 'WRITE'" mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                  (click)="openDeleteDialog(element.userId, element.timestamp, element.interviewId, element.organizerInstitution, element.participantFullName, element.angerGrade, element.calmGrade, element.trustGrade, element.disgustGrade, element.fearGrade, element.happinessGrade, element.sadnessGrade, element.surpriseGrade, element.interestGrade, element.accuracy)">
                  <mat-icon class="self-center mr-6" class="actionIcon">delete</mat-icon>
                </button>
                <span class="disabled-wrapper">
                  <button mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
                  (click)="openDeleteDialog(element.userId, element.timestamp, element.interviewId, element.organizerInstitution, element.participantFullName, element.angerGrade, element.calmGrade, element.trustGrade, element.disgustGrade, element.fearGrade, element.happinessGrade, element.sadnessGrade, element.surpriseGrade, element.interestGrade, element.accuracy)" *ngIf = "userProfile.permission !== 'WRITE'" class="button-disabled" [disabled]="userProfile.permission !== 'WRITE'">
                  <mat-icon class="self-center mr-6" class="actionIcon">delete</mat-icon>
                </button>
                </span>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">{{"SEARCH_BOX.NO_MATCH" | translate}}</td>
          </tr>
        </table>
        <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
    <app-interrogation-structured-sidenav  *ngIf="gameAndDataPath ==='interrogation/structuredData'"></app-interrogation-structured-sidenav>
    <app-footer class="fixed bottom-0 w-full"></app-footer>
  </div>
</div>