import { Injectable } from '@angular/core';
import { USER_PROFILE } from './profile/profile.service';

export interface USER {
  username: string;
  organisation: string;
}

export interface SCENARIOS {
  identityManagementKey: string;
  name: string;
  description: string;
  type: string;
  category: string;
}


@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private userLanguage: string = '';
  private userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  private localStorageId = "user_language"
  private localStorageUserId = "data_management_";

  private isBackendOver = true;
  private deletedElementName = '';
  private renamedElementName = '';
  private isDirectory = true;
  private selectedDirName = '';
  private elementName = '';
  private elementPath = '';
  private currentDirectoryName = '';
  private currentDescription = '';
  private currentCategory = '';
  private currentType = '';
  private selectedScenario: SCENARIOS | undefined = undefined;
  private selectedGame = '';
  private selectedDataType = '';
  private gameAndDataTypePath = '';
  private scenariosPath = '';
  private gameStatisticsPath = '';
  private isBtnActive: boolean = false;
  private recordingsPath ='';
  private texturePath ='';
  private modelsPath = '';
  private configPath = '';
  private snapshotsPath = '';
  private elementFromMenu = '';
  private playerId ='';
  private gameplaySessionId ='';
  private recordId ='';
  private timestamp ='';
  private text='';
  private sourceEntity ='';
  private audio ='';
  private action ='';
  private elementFromWhereToDelete = '';
  private gsd: any;
  private overlap: any;
  private value: any;
  private userId = '';
  private interviewId = '';
  private organizerInstitution = '';
  private participantFullName = '';
  private angerGrade = '';
  private calmGrade = '';
  private trustGrade = '';
  private disgustGrade = '';
  private fearGrade = '';
  private happinessGrade = '';
  private sadnessGrade = '';
  private surpriseGrade = '';
  private interestGrade = '';
  private accuracy = '';
  private motionIndicator: any;
  private speedIndicator: any;
  private kineticEnergyIndicator: any;
  private jointAngles:any;
  private json:any;
  private gameStartTimestamp = '';
  private interrogationStartTimestamp = '';
  private interrogationEndTimestamp = '';
  private confessionTimestamp = '';
  private gameEndTimestamp = '';
  private multipleQuestionRatio = '';
  private backgroundImageBase64 ='';
  private logoBase64='';

  public getBackgroundImageBase64() {
    return this.backgroundImageBase64;
  }

  public setBackgroundImageBase64(backgroundImageBase64: string) {
    this.backgroundImageBase64 = backgroundImageBase64;
  }

  public getLogoBase64() {
    return this.logoBase64;
  }

  public setLogoBase64(logoBase64: string) {
    this.logoBase64 = logoBase64;
  }

  public getInterrogationStartTimestamp() {
    return this.interrogationStartTimestamp;
  }

  public setInterrogationStartTimestamp(interrogationStartTimestamp: string) {
    this.interrogationStartTimestamp = interrogationStartTimestamp;
  }

  public getInterrogationEndTimestamp() {
    return this.interrogationEndTimestamp;
  }

  public setInterrogationEndTimestamp(interrogationEndTimestamp: string) {
    this.interrogationEndTimestamp = interrogationEndTimestamp;
  }

  public getConfessionTimestamp() {
    return this.confessionTimestamp;
  }

  public setConfessionTimestamp(confessionTimestamp: string) {
    this.confessionTimestamp = confessionTimestamp;
  }

  public getGameEndTimestamp() {
    return this.gameEndTimestamp;
  }

  public setGameEndTimestamp(gameEndTimestamp: string) {
    this.gameEndTimestamp = gameEndTimestamp;
  }

  public getMultipleQuestionRatio() {
    return this.multipleQuestionRatio;
  }

  public setMultipleQuestionRatio(multipleQuestionRatio: string) {
    this.multipleQuestionRatio = multipleQuestionRatio;
  }
  
  public getGameStartTimestamp() {
    return this.gameStartTimestamp;
  }

  public setGameStartTimestamp(gameStartTimestamp: string) {
    this.gameStartTimestamp = gameStartTimestamp;
  }

  public getJson() {
    return this.json;
  }

  public setJson(json: any) {
    this.json = json;
  }

  public getMotionIndicator() {
    return this.motionIndicator;
  }

  public setMotionIndicator(motionIndicator: any) {
    this.motionIndicator = motionIndicator;
  }

  public getSpeedIndicator() {
    return this.speedIndicator;
  }

  public setSpeedIndicator(speedIndicator: any) {
    this.speedIndicator = speedIndicator;
  }

  public getKineticEnergyIndicator() {
    return this.kineticEnergyIndicator;
  }

  public setKineticEnergyIndicator(kineticEnergyIndicator: any) {
    this.kineticEnergyIndicator = kineticEnergyIndicator;
  }

  public getJointAngles() {
    return this.jointAngles;
  }

  public setJointAngles(jointAngles: any) {
    this.jointAngles = jointAngles;
  }
  
  public getUserId() {
    return this.userId;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public getInterviewId() {
    return this.interviewId;
  }

  public setInterviewId(interviewId: string) {
    this.interviewId = interviewId;
  }

  public getOrganizerInstitution() {
    return this.organizerInstitution;
  }

  public setOrganizerInstitution(organizerInstitution: string) {
    this.organizerInstitution = organizerInstitution;
  }

  public getParticipantFullName() {
    return this.participantFullName;
  }

  public setParticipantFullName(participantFullName: string) {
    this.participantFullName = participantFullName;
  }

  public getAngerGrade() {
    return this.angerGrade;
  }

  public setAngerGrade(angerGrade: string) {
    this.angerGrade = angerGrade;
  }

  public getCalmGrade() {
    return this.calmGrade;
  }

  public setCalmGrade(calmGrade: string) {
    this.calmGrade = calmGrade;
  }

  public getTrustGrade() {
    return this.trustGrade;
  }

  public setTrustGrade(trustGrade: string) {
    this.trustGrade = trustGrade;
  }

  public getDisgustGrade() {
    return this.disgustGrade;
  }

  public setDisgustGrade(disgustGrade: string) {
    this.disgustGrade = disgustGrade;
  }

  public getFearGrade() {
    return this.fearGrade;
  }

  public setFearGrade(fearGrade: string) {
    this.fearGrade = fearGrade;
  }

  public getHappinessGrade() {
    return this.happinessGrade;
  }

  public setHappinessGrade(happinessGrade: string) {
    this.happinessGrade = happinessGrade;
  }

  public getSadnessGrade() {
    return this.sadnessGrade;
  }

  public setSadnessGrade(sadnessGrade: string) {
    this.sadnessGrade = sadnessGrade;
  }

  public getSurpriseGrade() {
    return this.surpriseGrade;
  }

  public setSurpriseGrade(surpriseGrade: string) {
    this.surpriseGrade = surpriseGrade;
  }

  public getInterestGrade() {
    return this.interestGrade;
  }

  public setInterestGrade(interestGrade: string) {
    this.interestGrade = interestGrade;
  }

  public getAccuracy() {
    return this.accuracy;
  }

  public setAccuracy(accuracy: string) {
    this.accuracy = accuracy;
  }

  ///

  private languageFlag = localStorage.getItem('language')
    ? localStorage.getItem('language')!.toString()
    : 'EN'

  private language = {
    code: localStorage.getItem('language')
      ? localStorage.getItem('language')!.toString()
      : 'EN',
    language: localStorage.getItem('language'),
    flag: 'assets/flags/' + this.languageFlag + '.png'
  };

  public resetVariables() {
    this.username = '';
    localStorage.clear();
  }

  private navLocation: {
    name: string;
    location: string;
  }[] = [];

  private folderExplorerNavBar: {
    name: string;
    location: string;
  }[] = [];

  private username = '';
  private identityManagementKey = '';

  public getLanguage() {
    return this.language;
  }

  public setLanguage(language: {
    code: string;
    language: string;
    flag: string;
  }) {
    this.language = language;
  }

  public setSelectedScenario(scenario: SCENARIOS) {
    this.selectedScenario = scenario;
  }

  public getSelectedScenario() {
    return this.selectedScenario;
  }


  public getUsername() {
    return this.username;
  }

  public setUsername(username: string) {
    this.username = username;
  }

  public getidentityManagementKey() {
    return this.identityManagementKey;
  }

  public setIdentityManagementKey(identityManagementKey: string) {
    this.identityManagementKey = identityManagementKey;
  }
  public getNavLocation() {
    return this.navLocation;
  }

  public setNavLocation(
    locations: {
      name: string;
      location: string;
    }[]
  ) {
    this.navLocation = locations;
  }


  public getFolderExplorerNavBar() {
    return this.folderExplorerNavBar;
  }

  public setFolderExplorerNavBar(
    locations: {
      name: string;
      location: string;
    }[]
  ) {
    this.folderExplorerNavBar = locations;
  }


  public getBackendStatus() {
    return this.isBackendOver;
  }

  public getUser(): string {
    return this.userLanguage;
  }

  public setUser(userLanguage: string) {
    localStorage.setItem(this.localStorageId, userLanguage);
    this.userLanguage = userLanguage;
  }

  public setUserProfile(profile: USER_PROFILE) {
    this.userProfile = profile;
  }

  public updateUserProfile(firstName: string, lastName: string) {
    this.userProfile.firstName = firstName;
    this.userProfile.lastName = lastName;
    this.setUserProfile(this.userProfile);
  }
  public getUserProfile(): USER_PROFILE {
    return this.userProfile;
  }

  public getdeletedElementName() {
    return this.deletedElementName;
  }

  public setdeletedElementName(elementName: string) {
    this.deletedElementName = elementName;
  }

  public getRenamedElementName() {
    return this.renamedElementName;
  }
  public setRenamedElementName(elementName: string) {
    this.renamedElementName = elementName;
  }
  public getElementPath() {
    return this.elementPath;
  }

  public setElementPath(elementPath: string) {
    this.elementPath = elementPath;
  }

  public getElementType() {
    return this.isDirectory;
  }

  public setElementType(isDirectory: true) {
    this.isDirectory = isDirectory;
  }

  public getSelectedDirName() {
    return this.selectedDirName;
  }

  public setSelectedDirName(dirName: string) {
    this.selectedDirName = dirName;
  }

  public getcurrentDirectoryName() {
    return this.currentDirectoryName;
  }

  public setcurrentDirectoryName(currentDirectoryName: string) {
    this.currentDirectoryName = currentDirectoryName;
  }

  public getSelectedGame() {
    return this.selectedGame;
  }

  public setSelectedGame(gameName: string) {
    this.selectedGame = gameName;
  }


  public getSelectedDataType() {
    return this.selectedDataType;
  }

  public setSelectedDataType(dataType: string) {
    this.selectedDataType = dataType;
  }

  public getGameAndDataTypePath() {
    return this.gameAndDataTypePath;
  }

  public setGameAndDataTypePath(gameAndDataTypePath: string) {
    this.gameAndDataTypePath = gameAndDataTypePath;
  }

  public getStructuredDataOnePath() {
    return this.scenariosPath;
  }

  public setScenariosPath(scenariosPath: string) {
    this.scenariosPath = scenariosPath;
  }

  public getstructuredDataTwoPath() {
    return this.gameStatisticsPath;
  }

  public setGameStatisticsPath(gameStatisticsPath: string) {
    this.gameStatisticsPath = gameStatisticsPath;
  }

  public getIsBtnActive() {
    return this.isBtnActive;
  }

  public setIsBtnActive(isBtnActive: boolean) {
    this.isBtnActive = isBtnActive;
  }

  public getRecordingsPath() {
    return this.recordingsPath;
  }

  public setRecordingsPath(recordingsPath: string) {
    this.recordingsPath = recordingsPath;
  }
  public getTexturePath() {
    return this.texturePath;
  }

  public setTexturePath(texturePath: string) {
    this.texturePath = texturePath;
  }
  public getConfigPath() {
    return this.configPath;
  }

  public setConfigPath(configPath: string) {
    this.configPath = configPath;
  }
  public getModelsPath() {
    return this.modelsPath;
  }

  public setModelsPath(modelsPath: string) {
    this.modelsPath = modelsPath;
  }
  public getSnapshotsPath() {
    return this.snapshotsPath;
  }

  public setSnapshotsPath(snapshotsPath: string) {
    this.snapshotsPath = snapshotsPath;
  }

  public getPlayerId() {
    return this.playerId;
  }

  public setPlayerId(playerId: string) {
    this.playerId = playerId;
  }

  public getGameplaySessionId() {
    return this.gameplaySessionId;
  }

  public setGameplaySessionId(gameplaySessionId: string) {
    this.gameplaySessionId = gameplaySessionId;
  }


  public getRecordId() {
    return this.recordId;
  }

  public setRecordId(recordId: string) {
    this.recordId = recordId;
  }

  public getTimestamp() {
    return this.timestamp;
  }

  public setGSD(gsd: any) {
    this.gsd = gsd;
  }

  public getGSD() {
    return this.gsd;
  }

  public setValue(value: any) {
    this.value = value;
  }

  public getValue() {
    return this.value;
  }

  public setOverlap(overlap: any) {
    this.overlap = overlap;
  }

  public getOverlap() {
    return this.overlap;
  }

  public setTimestamp(timestamp: string) {
    this.timestamp = timestamp;
  }

  public getText() {
    return this.text;
  }

  public setText(text: string) {
    this.text = text;
  }

  public getSourceEntity() {
    return this.sourceEntity;
  }

  public setSourceEntity(sourceEntity: string) {
    this.sourceEntity = sourceEntity;
  }

  public getAudio() {
    return this.audio;
  }

  public setAudio(audio: string) {
    this.audio = audio;
  }

  public getElementName() {
    return this.elementName;
  }

  public setElementName(elementName: string) {
    this.elementName = elementName;
  }

  public getElementFromMenu() {
    return this.elementFromMenu;
  }

  public setElementFromMenu(elementFromMenu: string) {
    this.elementFromMenu = elementFromMenu;
  }


  public getCurrentDescription() {
    return this.currentDescription;
  }

  public setCurrentDescription(currentDescription: string) {
    this.currentDescription = currentDescription;
  }
  public getCurrentCategory() {
    return this.currentCategory;
  }

  public setCurrentCategory(currentCategory: string) {
    this.currentCategory = currentCategory;
  }
  public getCurrentType() {
    return this.currentType;
  }

  public setCurrentType(currentType: string) {
    this.currentType = currentType;
  }

  public getElementFromWhereToDelete() {
    return this.elementFromWhereToDelete;
  }

  public setElementFromWhereToDelete(elementFromWhereToDelete: string) {
    this.elementFromWhereToDelete = elementFromWhereToDelete;
  }

  public getAction() {
    return this.action;
  }

  public setAction(action: string) {
    this.action = action;
  }

  
  
}
