<div class="sidebar">
    <div class="buttonsWrapper">
        <div>
            <button mat-raised-button  [routerLink]="['/reportBloodstainAnalysis']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORTS_BLODDSTAIN_ANAYSIS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportBulletTrajectory']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORTS_BULLET_TRAJECTORY' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportCsiFinalResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORTS_CSI_FINAL_RESULTS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/csiScenarioGeneration']" routerLinkActive="active">
                <mat-icon class="material-icons">description</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button  [routerLink]="['/reportLmsQuizResults']" routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.LMS_QUIZ_RESULTS' | translate}}</span></button>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/reportCsiAndLmsResults']"
                routerLinkActive="active">
                <mat-icon class="material-icons">assessment</mat-icon>
                <span class="ml-5">{{'STRUCTURED_DATA.REPORT_CSI_AND_LMS_RESULTS.TITLE' | translate}}</span></button>
        </div>
    </div>
    <app-footer class="fixed bottom-0 w-full"></app-footer>