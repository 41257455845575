import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { Router } from '@angular/router';
import { throwError, of } from 'rxjs';
import { catchError, map, tap, switchMap, concatMap, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/token/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshingAccessToken: Boolean = false;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,

  ) { }

  expired_refresh_token: Boolean = false;
  refreshTokenError: String = "";
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    const refreshToken = this.tokenService.getRefreshToken();
   
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    }
  
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    
  
    return next.handle(request).pipe(
      catchError((error: any) => {
     
        if (error.status == 401) {
          this.refreshTokenError = error.error.error_description;
          if (error.error.error === 'invalid_token' && !this.refreshingAccessToken) {
           if (this.tokenService.getRefreshToken()) {
            return this.refreshAccessToken(this.tokenService.getRefreshToken()).pipe(
              switchMap(() => next.handle(request.clone({
                setHeaders: {
                  Authorization: 'Bearer ' +  this.tokenService.getToken(),
                  
                }}))
            ));
            }
            this.authService.logout();
            this.expired_refresh_token = true;
            this.router.navigate(['/']);
            this.openSnackBar(
              'Your session has expired, please login again',
              'notif-warning',
              4000
            );
            
          }
              
          if(this.refreshTokenError != null && this.refreshTokenError !== undefined && this.refreshTokenError === "invalid_grant"){
            this.authService.logout();
            this.expired_refresh_token = true;
            this.router.navigate(['/']);
            this.openSnackBar(
              'Your session has expired, please login again',
              'notif-warning',
              4000
            );
            // location.reload();
            
           } else {
            this.authService.logout();
            this.expired_refresh_token = true;
            this.router.navigate(['/']);
          
            this.openSnackBar(
              'Your session has expired, please login again',
              'notif-warning',
              4000
            );
            // location.reload();
        
          }
        }
        if(error.status == '400'){
          if(error.error.error === 'invalid_grant'){
            this.authService.logout();
            this.expired_refresh_token = true;
            this.router.navigate(['/']);
       
            this.openSnackBar(
              'Your session has expired, please login again',
              'notif-warning',
              4000
            );
            // location.reload();
          }
        }
        return next.handle(request);
      })
    );
  }
  
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }


  refreshAccessToken(refreshToken: string) {
    this.refreshingAccessToken = true;
    return this.authService
    .refreshToken({ refresh_token: refreshToken }).pipe(
      tap(() => {
        this.refreshingAccessToken = false;
      }))
    }

  }
  