import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { SdmDataService } from 'src/app/services/sdm-data/sdm-data.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-scenario-generation-terr-attack-pilot',
  templateUrl: './scenario-generation-terr-attack-pilot.component.html',
  styleUrls: ['./scenario-generation-terr-attack-pilot.component.css']
})
export class ScenarioGenerationTerrAttackPilotComponent implements OnInit {

  @ViewChild('paginator')
  paginator!: MatPaginator;

  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  public gameAndDataPath = this.interactionService.getGameAndDataTypePath();
  public userPermission = '';
  gameType: string = '';

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private sdmDataService: SdmDataService,
    private interactionService: InteractionService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
      this.userProfile = res;
      this.interactionService.setUserProfile(res);
    }, (err: any)=>{
      console.log("error" + err.error);
    })
    this.interactionService.getIsBtnActive();
    this.gameType = this.interactionService.getSelectedGame();
    if(this.gameType === 'terroristAttack'){
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
        { name: 'HOME.GAMES_TITLES.TERRORIST_ATTACK', location:'/homePilot'},
        { name: 'STRUCTURED_DATA.REPORTS_TERRORIST_ATTACK', location: '/reportsTerroristAttack'},
        { name: 'STRUCTURED_DATA.SCENARIO_GENERATION.TITLE', location: '/terrAttackScenarioGeneration' },
      ]);
      this.interactionService.setSelectedGame("terroristAttack");
    }else{
      this.router.navigate(['/homePilot']);
      this.interactionService.setNavLocation([
        { name: 'HEADER.HOME', location: '/homePilot' },
      ]);
    }
    this.isLoading = false;
  }

  formGroup = this._formBuilder.group({
    scenarioId: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z0-9\s]*$/)
      ],
    ],
    playerId: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z0-9\s]*$/)
      ],
    ],
    scenarioType: ['city']
  });


  formErrors: { field: string; required: string; others: string }[] = [
    {
      field: 'scenarioId',
      required: 'STRUCTURED_DATA.SCENARIO_GENERATION.SCENARIO_ID_REQUIRED',
      others: 'STRUCTURED_DATA.SCENARIO_GENERATION.MINLENGTH',
    },
    {
      field: 'playerId',
      required: 'STRUCTURED_DATA.SCENARIO_GENERATION.PLAYER_ID_REQUIRED',
      others: 'STRUCTURED_DATA.SCENARIO_GENERATION.MINLENGTH_PLAYER_ID',
    },
  ];


  onSubmit() {
    this.isLoading = true;
    let backend: boolean = true;
  
    if (backend) {
      this.sdmDataService.generateScenarioTerrAttack(
        this.formGroup.get('scenarioId')!.value!,
        this.formGroup.get('scenarioType')!.value!,
        this.formGroup.get('playerId')!.value!
      )
      .subscribe(
        (res) => {
          if (res.statusDescription === 'OK' || res.status === true) {
            this.openSnackBar(
              this.translate.instant('STRUCTURED_DATA.SCENARIO_GENERATION.SCENARIO_GENERATED_SUCCESS'),
              'notif-success',
              4000
            );
            this.formGroup.reset(); // Clear form inputs
            //this.router.navigate(['structuredData/scenarioGenerationCarAccident']);
          }
          this.isLoading = false;
        },
        (err: any) => {

          if (err.error.statusDescription === 'error_generating_scenario_Process_exceeded_timeout') {
            this.openSnackBar(
              this.translate.instant('STRUCTURED_DATA.SCENARIO_GENERATION.ERROR_GENERATING_SCENARIO'),
              'notif-warning',
              4000
            );
            this.formGroup.reset();
            this.isLoading = false;
          } else {
            this.openSnackBar(
              this.translate.instant("INTERCEPTOR.SESSION_EXPIRED"),
              'notif-warning',
              4000
            );
          }
          this.isLoading = false;
        }
      );
    }
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration
    
    });
  }

  isFormValid() {
    return this.formGroup.valid;
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

}