<div class="invoice-container">
  <div class="invoice" #invoice>
    <button class="close-button" (click)="closeInvoice()">×</button>
    <button mat-icon-button matTooltip="Download Report as PDF" (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>
    <h2>Interrogation Summary Report</h2>
    <p><strong>User Id:</strong> {{ data.userId}}</p>
    <p><strong>Player Id:</strong> {{ data.playerId}}</p>
    <p><strong>Participant Full Name:</strong> {{ data.participantFullName}}</p>
    <p><strong>Interrogation Id:</strong> {{ data.interrogationId}}</p>
    <p><strong>Game Start Timestamp:</strong> {{ data.gameStartTimestamp ? data.gameStartTimestamp : 'null' }}</p>
    <p><strong>Interrogation Start Timestamp:</strong> {{ data.interrogationStartTimestamp }}</p>
    <p><strong>Interrogation End Timestamp:</strong> {{ data.interrogationEndTimestamp }}</p>
    <p><strong>Confession Timestamp:</strong> {{ data.confessionTimestamp }}</p>
    <p><strong>Game End Timestamp:</strong> {{ data.gameEndTimestamp }}</p>
    <p><strong>Multiple Question Ratio:</strong> {{ data.multipleQuestionRatio }}</p>
  </div>
</div>