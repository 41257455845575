<div
*ngIf="isLoading === true"
class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
<div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
  <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
</div>
</div>

<div class="w-[98%] flex mx-auto justify-center">
  <div class="lg:flex lg:flex-col 3xl:text-xl xl:flex 4k:text-xl ">
    <div class="text-center justify-center">
   
      <mat-card>
        <mat-card-header>
          <!-- <mat-card-title class="header-title">Change password</mat-card-title> -->
         
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="changePasswordFormGroup" class="mt-[10%]" (keyup.enter)="onChangePassword()">
              <mat-form-field appearance="outline" class="w-full text-[#ffc601]">
                <mat-label>{{"PROFILE.PASSWORD_REQUEST.OLD_PASSWORD" | translate}}</mat-label>
                <input
                  id="oldPassword"
                  type="password"
                  matInput
                  minlength="2"
                  formControlName="oldPassword"
                />
              </mat-form-field>
          
              <mat-form-field appearance="outline" class="w-full text-[#ffc601]">
                <mat-label>{{"PROFILE.PASSWORD_REQUEST.NEW_PASSWORD" | translate}}</mat-label>
                <input
                  id="newPassword"
                  type="password"
                  matInput
                  minlength="2"
                  formControlName="newPassword"
                />
              
              </mat-form-field>
          
              <mat-form-field appearance="outline" class="w-full text-[#ffc601]">
                <mat-label>{{"PROFILE.PASSWORD_REQUEST.CONFIRM_PASSWORD" | translate}}</mat-label>
                <input
                  id="confirmPassword"
                  type="password"
                  matInput
                  minlength="2"
                  formControlName="confirmPassword"
                />
              </mat-form-field>
            </form>
        </mat-card-content>
      </mat-card>
      
      <mat-card-actions class=" mb-[5%] ">
        <button 
        mat-raised-button
        class="w-auto text-black h-10 bg-[#ffc601] "
        [disabled]="!isFormValid()"
        (click)="onChangePassword()"
        >
        {{"PROFILE.PASSWORD_REQUEST.UPDATE" | translate}}
        </button>
        <div class="text-[10px] text-[#ffc601]" *ngIf="oldPassword!.touched && oldPassword!.invalid">
          <div *ngIf="oldPassword!.errors?.['required']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.OLD_PASSWORD.REQUIRED" | translate}}</div>
          <div *ngIf="oldPassword!.errors?.['shouldBeOldPassword']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.OLD_PASSWORD.VALID" | translate}}</div>
        </div>
        <div class="text-[10px] text-[#ffc601]" *ngIf="newPassword!.touched && newPassword!.invalid">
          <div *ngIf="newPassword!.errors?.['required']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.NEW_PASSWORD.REQUIRED" | translate}}</div>
         
          <div class="text-[10px] text-[#ffc601]" *ngIf="newPassword!.errors?.['pattern']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.NEW_PASSWORD.VALID" | translate}}</div>
          <div  class="text-[10px] text-[#ffc601]"  *ngIf="newPassword!.errors?.['newPasswordSameAsOldPassword']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.VALIDATOR.NEW_AS_OLD" | translate}}</div>
        </div>
       
        <div class="text-[10px] text-[#ffc601]" *ngIf="confirmPassword!.touched && confirmPassword!.invalid">
          <div *ngIf="confirmPassword!.errors?.['required']">*{{"PROFILE.PASSWORD_REQUEST.ERRORS.CONFIRM_PASSWORD.REQUIRED" | translate}}</div>
        </div>
        <div class="text-[10px] text-[#ffc601]" *ngIf="confirmPassword!.valid && changePasswordFormGroup.invalid && changePasswordFormGroup.errors?.['passwordsDontMatch']">
          *{{"PROFILE.PASSWORD_REQUEST.ERRORS.VALIDATOR.NEW_AND_CONFIRM" | translate}}
        </div>

        <div class="text-[10px] text-[#ffc601]" *ngIf="confirmPassword!.valid && changePasswordFormGroup.invalid && changePasswordFormGroup.errors?.['newPasswordSameAsOldPassword']">
          *{{"PROFILE.PASSWORD_REQUEST.ERRORS.VALIDATOR.NEW_AS_OLD" | translate}}
        </div>
      </mat-card-actions>

  </div>

  
</div>


