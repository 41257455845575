import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-interrogation-reports-sidenav',
  templateUrl: './interrogation-reports-sidenav.component.html',
  styleUrls: ['./interrogation-reports-sidenav.component.css']
})
export class InterrogationReportsSidenavComponent implements OnInit {
  
  constructor(
    private userService: UserService,
  ) {}

  ngOnInit(): void {
  }
}
