<!-- Overlay loading spinner -->
<div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
  <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>
<div class="root">
  <app-header [ngClass]="isLoading === true ? 'fixed w-full' : 'fixed w-full z-50'"></app-header>
  <app-sidenav-unstructured-data></app-sidenav-unstructured-data>
  <h3>{{"ROOT.TITLE" | translate }}</h3>
  <div class="search_wrapper">
    <mat-form-field appearance="standard">
      <input matInput (keyup)="applyFilter($event)" placeholder="{{'SEARCH_BOX.PLACEHOLDER' | translate}}" #input>
    </mat-form-field>
    <h5 *ngIf="userProfile?.permission && userProfile?.permission !== 'WRITE'">* {{"READ_PERMISSION" | translate}}</h5>
  </div>
  <div class="functionalities">
      <mat-toolbar class="folder-explorer">
        <button *ngIf="canNavigateUp === true" (click)="navigateUp()"><mat-icon
            class="relative top-1">arrow_back</mat-icon></button>
        <span>{{path}}</span>
      </mat-toolbar>
      <div id="btn-wrapper">
        <button mat-button (click)="refreshDirectory(currentDirectoryName)" matTooltip="{{'TOOLTIP.REFRESH' | translate}}"
          matTooltipPosition="above"><mat-icon>refresh</mat-icon></button>
        <button mat-button matTooltip="{{'TOOLTIP.CREATE_FOLDER' | translate}}" matTooltipPosition="above"
          (click)="openNewFolderDialog()" [disabled]="userProfile.permission !== 'WRITE'"> <mat-icon class="actionIcon">create_new_folder</mat-icon>
        </button>
          <div class="file-upload">
            <input id="myFile" name="myFile" type="file" style="display:none" (change)="selectFile($event)">
            <button class="file-upload__button" onclick="document.getElementById('myFile').click()" [disabled]="userProfile.permission !== 'WRITE'">{{'ROOT.UPLOAD_FILE.BUTTON' | translate}}</button>
            <span class="file-upload__label">{{fileUploadLabel}}</span>
            <button mat-button matTooltip="{{'TOOLTIP.UPLOAD_FILE' | translate}}" matTooltipPosition="above"
            (click)="upload()" id="upload-btn" [disabled]="userProfile.permission !== 'WRITE' || !selectedFiles"><mat-icon>upload</mat-icon></button>
          </div>
      </div>
  </div>
  <div *ngIf="currentFile" class="progress my-3">
    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
      {{ progress }}%
    </div>
  </div>
  <div class="files-wrapper">
    <table mat-table [dataSource]="dataSource" id="filesAndDirectories">
      <mat-spinner *ngIf="isLoading" [diameter]="spinnerSize" class="mx-auto"></mat-spinner>
      <ng-container class="pt-[10%] text-3xl" matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          {{"ROOT.TABLE.NAME"| translate | uppercase}}
        </th>
        <td mat-cell *matCellDef="let q">
          {{q?.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          {{'ROOT.TABLE.TYPE'| translate | uppercase}}
        </th>
        <td mat-cell *matCellDef="let q">
          {{q?.directory == true ? 'directory' : 'file'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{"ROOT.TABLE.ACTIONS"| translate | uppercase}}
        </th>
        <td mat-cell *matCellDef="let q">
          <div class="actionsButtons">
            <button *ngIf="q?.directory == true" mat-button matTooltip="{{'TOOLTIP.OPEN_FOLDER' | translate}}"
              matTooltipPosition="above" (click)="openDirectory(q?.name)">
              <mat-icon class="actionIcon">folder_open</mat-icon>
            </button>
            <button *ngIf="q?.file == true" mat-button matTooltip="{{'TOOLTIP.DOWNLOAD' | translate}}"
              matTooltipPosition="above" (click)="downloadFile(q?.name)">
              <mat-icon class="actionIcon">download</mat-icon>
            </button>
            <button mat-button matTooltip="{{'TOOLTIP.RENAME' | translate}}" matTooltipPosition="above"
              (click)="openRenameDialog(q?.name,  q?.directory)"  [disabled]="userProfile.permission !== 'WRITE'">
              <mat-icon class="actionIcon">edit</mat-icon>
            </button>
            <button mat-button matTooltip="{{'TOOLTIP.DELETE' | translate}}" matTooltipPosition="above"
              (click)="openDeleteDialog(q?.name, q?.directory)"  [disabled]="userProfile.permission !== 'WRITE'">
              <mat-icon class="actionIcon">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{"SEARCH_BOX.NO_MATCH" | translate}}</td>
      </tr>
    </table>
    <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize">
    </mat-paginator>
  </div>
  <app-footer class="fixed bottom-0 w-full"></app-footer>
</div>