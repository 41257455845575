import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {MatListModule} from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingSpinnerComponent } from './shared-components/loading-spinner/loading-spinner.component';
import { LocationNavbarComponent } from 'src/app/shared-components/location-navbar/location-navbar.component';
import { LawgameAppComponent } from './components/lawgame-app/lawgame-app.component';
import { AppMaterialModule } from './app-material/app-materials';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkColumnDef } from '@angular/cdk/table';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { HomeComponent } from './components/home/home.component';
import { StructuredDataComponent } from './components/structured-data/structured-data.component';
import { UnstructuredDataComponent } from './components/unstructured-data/unstructured-data.component';
import { FormsModule } from '@angular/forms';
import { NavbarComponent } from './shared-components/navbar/navbar.component';
import { SidenavStructuredDataComponent } from './shared-components/sidenav-structured-data/sidenav-structured-data.component';
import { SidenavUnstructuredDataComponent } from './shared-components/sidenav-unstructured-data/sidenav-unstructured-data.component';
import { GameStatisticsComponent } from './components/game-statistics/game-statistics.component';
import { ScenariosComponent } from './components/scenarios/scenarios.component';
import { NullWithDefaultPipe } from './null-with-default.pipe';
import { ModelsComponent } from './components/models/models.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { FileDataService } from './services/file-data/file-data.service';
import { DeleteElementComponent } from './components/delete-element/delete-element.component';
import { EditElementComponent } from './components/edit-element/edit-element.component';
import { DeleteScenarioComponent } from './components/delete-scenario/delete-scenario.component';

import {MatToolbarModule} from '@angular/material/toolbar';
import { EditScenarioComponent } from './components/edit-scenario/edit-scenario.component'; 
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddScenarioComponent } from './components/add-scenario/add-scenario.component';
import { AddDirectoryComponent } from './components/add-directory/add-directory.component';
import { FolderExplorerNavbarComponent } from './shared-components/folder-explorer-navbar/folder-explorer-navbar.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { RecordingsComponent } from './components/recordings/recordings.component';
import { SnapshotsComponent } from './components/snapshots/snapshots.component';
import { TexturesComponent } from './components/textures/textures.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ApartmentScenarioComponent } from './components/apartment-scenario/apartment-scenario.component';
import { CsiStructuredSidenavComponent } from './shared-components/csi-structured-sidenav/csi-structured-sidenav.component';
import { AinResponseComponent } from './components/ain-response/ain-response.component';
import { NpcResponseComponent } from './components/npc-response/npc-response.component';
import { GameEventsComponent } from './components/game-events/game-events.component';
import { ImageryAcquisitionComponent } from './components/imagery-acquisition/imagery-acquisition.component';
import { QualityReportComponent } from './components/quality-report/quality-report.component';
import { InterrogationStructuredSidenavComponent } from './shared-components/interrogation-structured-sidenav/interrogation-structured-sidenav.component';
import { TerrAttackStructuredSidenavComponent } from './shared-components/terr-attack-structured-sidenav/terr-attack-structured-sidenav.component';
import { CarAccidentStructuredSidenavComponent } from './shared-components/car-accident-structured-sidenav/car-accident-structured-sidenav.component';
import { TwoCarsCrashScenarioComponent } from './components/two-cars-crash-scenario/two-cars-crash-scenario.component';
import { VictimCrossingScenarioComponent } from './components/victim-crossing-scenario/victim-crossing-scenario.component';
import { VictimOnCurbScenarioComponent } from './components/victim-on-curb-scenario/victim-on-curb-scenario.component';
import { CityAttackScenarioComponent } from './components/city-attack-scenario/city-attack-scenario.component';
import { EmotionalAssessmentComponent } from './components/emotional-assessment/emotional-assessment.component';
import { AddAinResponseComponent } from './components/add-ain-response/add-ain-response.component';
import { AddGameEventComponent } from './components/add-game-event/add-game-event.component';
import { AddNpcResponseComponent } from './components/add-npc-response/add-npc-response.component';
import { AddEmotionalAssessmentComponent } from './components/add-emotional-assessment/add-emotional-assessment.component';
import { AddGameScenarioComponent } from './components/add-game-scenario/add-game-scenario.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { AvatarMovesComponent } from './components/avatar-moves/avatar-moves.component';
import { CameraSurveillanceComponent } from './components/camera-surveillance/camera-surveillance.component';
import { GameActionsComponent } from './components/game-actions/game-actions.component';
import { NpcMovesComponent } from './components/npc-moves/npc-moves.component';
import { RealTimeRecordQuestsComponent } from './components/real-time-record-quests/real-time-record-quests.component';
import { HumanStanceComponent } from './components/human-stance/human-stance.component';
import { ReportVisualizationComponent } from './components/report-visualization/report-visualization.component';
import { InterrogationSummaryComponent } from './components/interrogation-summary/interrogation-summary.component';
import { NegotiationSummaryComponent } from './components/negotiation-summary/negotiation-summary.component';
import { OmitFieldPipe } from './omit-field.pipe';
import { LocationPredictionComponent } from './components/location-prediction/location-prediction.component';
import { AnomalyDetectionComponent } from './components/anomaly-detection/anomaly-detection.component';
import { AiResultsInsertionComponent } from './components/ai-results-insertion/ai-results-insertion.component';
import { ReportGenerationInterrogationSummaryComponent } from './components/report-generation-interrogation-summary/report-generation-interrogation-summary.component';
import { ReportGenerationNegotiationSummaryComponent } from './components/report-generation-negotiation-summary/report-generation-negotiation-summary.component';
import { ReportGenerationLocationPredictionComponent } from './components/report-generation-location-prediction/report-generation-location-prediction.component';
import { ReportGenerationAnomalyDetectionComponent } from './components/report-generation-anomaly-detection/report-generation-anomaly-detection.component';
import { ReportGenerationAiResultsComponent } from './components/report-generation-ai-results/report-generation-ai-results.component';
import { ReportVizualizationAiResultsComponent } from './components/report-vizualization-ai-results/report-vizualization-ai-results.component';
import { ReportVizualizationAnomalyDetectionComponent } from './components/report-vizualization-anomaly-detection/report-vizualization-anomaly-detection.component';
import { ReportVizualizationInterrogationSummaryComponent } from './components/report-vizualization-interrogation-summary/report-vizualization-interrogation-summary.component';
import { ReportVizualizationLocationPredictionComponent } from './components/report-vizualization-location-prediction/report-vizualization-location-prediction.component';
import { ReportVizualizationNegotiationSummaryComponent } from './components/report-vizualization-negotiation-summary/report-vizualization-negotiation-summary.component';
import { BulletTrajectoryAnalysisComponent } from './components/bullet-trajectory-analysis/bullet-trajectory-analysis.component';
import { BloodStainOriginPointAnalysisComponent } from './components/blood-stain-origin-point-analysis/blood-stain-origin-point-analysis.component';
import { CarDeformationsAnalysisComponent } from './components/car-deformations-analysis/car-deformations-analysis.component';
import { AssessmentResultsComponent } from './components/assessment-results/assessment-results.component';
import { ObjectCentroidsComponent } from './components/object-centroids/object-centroids.component';
import { ReportsCarAccidentComponent } from './components/reports-car-accident/reports-car-accident.component';
import { ReportsInterrogationComponent } from './components/reports-interrogation/reports-interrogation.component';
import { ReportsCsiComponent } from './components/reports-csi/reports-csi.component';
import { ReportsTerroristAttackComponent } from './components/reports-terrorist-attack/reports-terrorist-attack.component';
import { HomePilotComponent } from './components/home-pilot/home-pilot.component';
import { ReportCarDeformationComponent } from './components/report-car-deformation/report-car-deformation.component';
import { InterrogationReportsSidenavComponent } from './shared-components/interrogation-reports-sidenav/interrogation-reports-sidenav.component';
import { CsiReportsSidenavComponent } from './shared-components/csi-reports-sidenav/csi-reports-sidenav.component';
import { TerrAttackReportsSidenavComponent } from './shared-components/terr-attack-reports-sidenav/terr-attack-reports-sidenav.component';
import { ReportInterrogationSummaryComponent } from './components/report-interrogation-summary/report-interrogation-summary.component';
import { ReportNegotiationSummaryComponent } from './components/report-negotiation-summary/report-negotiation-summary.component';
import { ReportBulltetTrajectoryComponent } from './components/report-bulltet-trajectory/report-bulltet-trajectory.component';
import { ReportBloodstainAnalysisComponent } from './components/report-bloodstain-analysis/report-bloodstain-analysis.component';
import { ReportCsiFinalResultsComponent } from './components/report-csi-final-results/report-csi-final-results.component';
import { ReportLocationPredictionComponent } from './components/report-location-prediction/report-location-prediction.component';
import { ReportAnomalyDetectionComponent } from './components/report-anomaly-detection/report-anomaly-detection.component';
import { ReportAiResultsComponent } from './components/report-ai-results/report-ai-results.component';
import { ReportGenerationCsiFinalResultsComponent } from './components/report-generation-csi-final-results/report-generation-csi-final-results.component';
import { ReportGenerationCarDeformationComponent } from './components/report-generation-car-deformation/report-generation-car-deformation.component';
import { ReportVizualizationCarDeformationComponent } from './components/report-vizualization-car-deformation/report-vizualization-car-deformation.component';
import { ReportVizualizationBloodstainAnalysisComponent } from './components/report-vizualization-bloodstain-analysis/report-vizualization-bloodstain-analysis.component';
import { ReportVizualizationBulletTrajectoryComponent } from './components/report-vizualization-bullet-trajectory/report-vizualization-bullet-trajectory.component';
import { ReportGenerationBloodstainAnalysisComponent } from './components/report-generation-bloodstain-analysis/report-generation-bloodstain-analysis.component';
import { ReportGenerationBulletTrajectoryComponent } from './components/report-generation-bullet-trajectory/report-generation-bullet-trajectory.component';
import { ScenarioGenerationCarAccidentComponent } from './components/scenario-generation-car-accident/scenario-generation-car-accident.component';
import { ScenarioGenerationCsiComponent } from './components/scenario-generation-csi/scenario-generation-csi.component';
import { ScenarioGenerationTerroristAttackComponent } from './components/scenario-generation-terrorist-attack/scenario-generation-terrorist-attack.component';
import { ScenarioGenerationCarAccidentPilotComponent } from './components/scenario-generation-car-accident-pilot/scenario-generation-car-accident-pilot.component';
import { ScenarioGenerationCsiPilotComponent } from './components/scenario-generation-csi-pilot/scenario-generation-csi-pilot.component';
import { ScenarioGenerationTerrAttackPilotComponent } from './components/scenario-generation-terr-attack-pilot/scenario-generation-terr-attack-pilot.component';
import { CarAccidentReportsSidenavComponent } from './shared-components/car-accident-reports-sidenav/car-accident-reports-sidenav.component';
import { LmsQuizResultsComponent } from './components/lms-quiz-results/lms-quiz-results.component';
import { ReportVizualizationLmsQuizResultsComponent } from './components/report-vizualization-lms-quiz-results/report-vizualization-lms-quiz-results.component';
import { ReportLmsQuizResultsComponent } from './components/report-lms-quiz-results/report-lms-quiz-results.component';
import { VrScenariosComponent } from './components/vr-scenarios/vr-scenarios.component';
import { ReportCsiAndLmsResultsComponent } from './components/report-csi-and-lms-results/report-csi-and-lms-results.component';
import { ReportVizualizationCsiAndLmsResultsComponent } from './components/report-vizualization-csi-and-lms-results/report-vizualization-csi-and-lms-results.component';
import { ReportCsiAndLmsResultsPilotComponent } from './components/report-csi-and-lms-results-pilot/report-csi-and-lms-results-pilot.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    LoginComponent,
    LogoutComponent,
    FooterComponent,
    HeaderComponent,
    LoadingSpinnerComponent,
    LocationNavbarComponent,
    NavbarComponent,
    LawgameAppComponent,
    StructuredDataComponent,
    UnstructuredDataComponent,
    SidenavStructuredDataComponent,
    SidenavUnstructuredDataComponent,
    GameStatisticsComponent,
    ScenariosComponent,
    NullWithDefaultPipe,
    ModelsComponent,
    ConfigurationComponent,
    DeleteElementComponent,
    EditElementComponent,
    EditScenarioComponent,
    DeleteScenarioComponent,
    AddScenarioComponent,
    AddDirectoryComponent,
    FolderExplorerNavbarComponent,
    DeleteDialogComponent,
    RecordingsComponent,
    SnapshotsComponent,
    TexturesComponent,
    UserProfileComponent,
    UserInfoCardComponent,
    ChangePasswordComponent,
    ApartmentScenarioComponent,
    CsiStructuredSidenavComponent,
    AinResponseComponent,
    NpcResponseComponent,
    GameEventsComponent,
    ImageryAcquisitionComponent,
    QualityReportComponent,
    InterrogationStructuredSidenavComponent,
    TerrAttackStructuredSidenavComponent,
    CarAccidentStructuredSidenavComponent,
    TwoCarsCrashScenarioComponent,
    VictimCrossingScenarioComponent,
    VictimOnCurbScenarioComponent,
    CityAttackScenarioComponent,
    EmotionalAssessmentComponent,
    AddAinResponseComponent,
    AddGameEventComponent,
    AddNpcResponseComponent,
    AddEmotionalAssessmentComponent,
    AddGameScenarioComponent,
    ForgotPasswordDialogComponent,
    AvatarMovesComponent,
    CameraSurveillanceComponent,
    GameActionsComponent,
    NpcMovesComponent,
    RealTimeRecordQuestsComponent,
    HumanStanceComponent,
    ReportVisualizationComponent,
    InterrogationSummaryComponent,
    NegotiationSummaryComponent,
    OmitFieldPipe,
    LocationPredictionComponent,
    AnomalyDetectionComponent,
    AiResultsInsertionComponent,
    ReportGenerationInterrogationSummaryComponent,
    ReportGenerationNegotiationSummaryComponent,
    ReportGenerationLocationPredictionComponent,
    ReportGenerationAnomalyDetectionComponent,
    ReportGenerationAiResultsComponent,
    ReportVizualizationAiResultsComponent,
    ReportVizualizationAnomalyDetectionComponent,
    ReportVizualizationInterrogationSummaryComponent,
    ReportVizualizationLocationPredictionComponent,
    ReportVizualizationNegotiationSummaryComponent,
    BulletTrajectoryAnalysisComponent,
    BloodStainOriginPointAnalysisComponent,
    CarDeformationsAnalysisComponent,
    AssessmentResultsComponent,
    ObjectCentroidsComponent,
    ReportsCarAccidentComponent,
    ReportsInterrogationComponent,
    ReportsCsiComponent,
    ReportsTerroristAttackComponent,
    HomePilotComponent,
    ReportCarDeformationComponent,
    InterrogationReportsSidenavComponent,
    CsiReportsSidenavComponent,
    TerrAttackReportsSidenavComponent,
    ReportInterrogationSummaryComponent,
    ReportNegotiationSummaryComponent,
    ReportBulltetTrajectoryComponent,
    ReportBloodstainAnalysisComponent,
    ReportCsiFinalResultsComponent,
    ReportLocationPredictionComponent,
    ReportAnomalyDetectionComponent,
    ReportAiResultsComponent,
    ReportGenerationCsiFinalResultsComponent,
    ReportGenerationCarDeformationComponent,
    ReportVizualizationCarDeformationComponent,
    ReportVizualizationBloodstainAnalysisComponent,
    ReportVizualizationBulletTrajectoryComponent,
    ReportGenerationBloodstainAnalysisComponent,
    ReportGenerationBulletTrajectoryComponent,
    ScenarioGenerationCarAccidentComponent,
    ScenarioGenerationCsiComponent,
    ScenarioGenerationTerroristAttackComponent,
    ScenarioGenerationCarAccidentPilotComponent,
    ScenarioGenerationCsiPilotComponent,
    ScenarioGenerationTerrAttackPilotComponent,
    CarAccidentReportsSidenavComponent,
    LmsQuizResultsComponent,
    ReportVizualizationLmsQuizResultsComponent,
    ReportLmsQuizResultsComponent,
    VrScenariosComponent,
    ReportCsiAndLmsResultsComponent,
    ReportVizualizationCsiAndLmsResultsComponent,
    ReportCsiAndLmsResultsPilotComponent,
  ],
  imports: [
      MatSnackBarModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      AppMaterialModule,
      MatProgressSpinnerModule,
      MatPaginatorModule,
      MatTableModule,
      HttpClientModule,
      MatChipsModule,
      MatExpansionModule,
      FormsModule,
      BrowserModule,
      MatTooltipModule,
      MatGridListModule,
      MatCardModule,
      MatMenuModule,
      MatListModule,
      MatIconModule,
      MatButtonModule,
      LayoutModule,
      MatCardModule,
      MatMenuModule,
      MatIconModule,
      MatButtonModule,
      LayoutModule,
      MatToolbarModule,
      TranslateModule.forRoot({
        defaultLanguage: 'EN',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    
      
    
  ],
  providers: [
    CdkColumnDef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    FileDataService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
